import { createBrowserHistory } from "history";
import { generateSSO } from "src/store/actions";
import store from "src/store/createStore";
// import * as history from "history";
import * as actionTypes from "../store/actions/actionTypes";
// import sliceKeys from "src/store/sliceKeys";
import { unAuthenticated, logout } from "src/store/actions/auth";
import sliceKeys from "src/store/sliceKeys";

const history = createBrowserHistory();

const createActionCreator = (type) => {
  return (payload) => {
    const action = { type };
    if (typeof payload !== "undefined") {
      action.payload = payload;
    }
    return action;
  };
};

const dispatchStoreAction = (type, payload, dispatch) => {
  const action = createActionCreator(type);
  dispatch(action(payload));
};

const serviceRequest = async (
  options = {
    skipLoader: false,
    skipErrorHandling: false,
  },
  dispatch,
  serviceMethod,
  payload,
  actionType = "NO_ACTION",
  getSuccessPayload = (res) => {
    return res?.data;
  },
  getErrorPayload = (res) => {
    return res;
  }
) => {
  try {
    // if (!options && !options.skipLoader) {
    if (options && !options.skipLoader) {
      dispatch({
        type: actionTypes.START_LOADING,
      });
    }
    // success: ['data', 'status', 'statusText', 'headers', 'config', 'request']
    // failed: ['config', 'request', 'response', 'isAxiosError', 'toJSON']
    const res = await serviceMethod(payload);
    if (
      typeof dispatch === "function" &&
      // (!options || !options.skipErrorHandling)
      (!options || !options.skipErrorHandling)
    ) {
      if (
        res &&
        res?.response &&
        res?.response?.data &&
        res?.response?.data?.status === "fail"
      ) {
        const globalMessages = [];
        if (res?.response?.data?.message === "Invalid token") {
          // await dispatch(unAuthenticated());
          // await dispatchStoreAction(
            //   actionTypes.API_MESSAGE,
            //   {
              //     actionType,
              //     message: "Không được phép truy cập",
              //     type: sliceKeys.message_danger,
              //   },
              //   dispatch
              // );
              // window.location.href = `/auth/login`;
              // history.push("/auth/login")
              // history.replace("/auth/login")
              // setTimeout(() => {
                //   window.location.href = generateSSO();
                // }, 5000);
          await dispatch(logout())
          window.location.href = generateSSO();
          return;
        }
        if (res?.response?.data?.message === "Not authorization") {
          window.location.href = generateSSO();
          return;
        }
        res?.response?.data?.violations?.length > 0 &&
          res?.response?.data?.violations?.forEach((messageItem) => {
            if (messageItem?.code === 401) {
              window.location.href = generateSSO();
              return;
            }
            globalMessages.push({ ...messageItem, actionType });
          });
        dispatchStoreAction(
          actionTypes.API_MESSAGE,
          {
            type: "ERROR",
            globalMessages,
          },
          dispatch
        );
      } else {
        dispatchStoreAction(
          actionTypes.CLEAR_API_MESSAGE,
          { actionType },
          dispatch
        );
      }
    }
    const serviceRequestResponse = await Promise.resolve(
      getSuccessPayload(res)
    );
    if (typeof dispatch === "function" && actionType) {
      dispatchStoreAction(actionType, serviceRequestResponse, dispatch);
    }
    return serviceRequestResponse;
  } catch (error) {
    const serviceRequestError = await Promise.resolve(getErrorPayload(error));
    if (typeof dispatch === "function" && actionType) {
      dispatchStoreAction(actionType, serviceRequestError, dispatch);
    }
    throw serviceRequestError;
  } finally {
    if (!options || !options.skipLoader) {
      dispatch({
        type: actionTypes.STOP_LOADING,
      });
    }
  }
};

export default { serviceRequest, dispatchStoreAction };
