import * as actionTypes from "../actions/actionTypes";

const initState = () => {
  return {
    list: [],
    detail: {},
  };
};
const initialState = initState();

const setListDevices = (state, payload) => {
  if (!payload || !payload.responseData || payload.status !== "success")
    return state;
  const list = payload.responseData.rows;
  const setListDevices = {
    ...state,
    list,
  };
  return setListDevices;
};
const setDetail = (state, payload) => {
  if (!payload || !payload.responseData || payload.status !== "success")
    return state;
  const detail = payload.responseData;
  const setDetail = {
    ...state,
    detail,
  };
  return setDetail;
};
const unsetDetail = (state, payload) => {
  const unsetDetail = {
    ...state,
    detail: initState().detail,
  };
  return unsetDetail;
};

const reducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case actionTypes.SET_ASSETS_DEVICES:
      return setListDevices(state, payload);
    case actionTypes.GET_ASSET_GROUP_DETAIL:
      return setDetail(state, payload);
    case actionTypes.UNSET_ASSET_DETAIL:
      return unsetDetail(state, payload);
    default:
      return state;
  }
};

export default reducer;
