import baseService from "src/helper/baseService";
import axios from "../../helper/axios";
import * as actionTypes from "../actions/actionTypes";
import { setUploadProgress, retryUploadProgress } from "./index";

const getAssetRequestHistoryByAssetIdMethod = async ({ payload, config }) => {
  return axios.get(
    `/asset-request-history/getAllRequestHistoryByAssetId/${
      payload.assetId
    }?filters=${encodeURIComponent(payload?.filters || "")}`
  );
};
const getAllAssetRequestTypeMethod = ({ payload, config }) => {
  return axios.get("/asset-request-type/getAllAssetRequestTypeForHistory");
};
// Asset
const getAllAssetByGroupIDMethod = ({ payload, config }) => {
  return axios.get(
    `/asset/getAllAssetByGroupID/${payload.id}?filters=${encodeURIComponent(
      payload?.filters || ""
    )}${payload?.currentPage ? `&currentPage=${payload?.currentPage}` : ""}${
      payload?.pageSize ? `&pageSize=${payload?.pageSize}` : ""
    }${payload?.sortField ? `&sortField=${payload?.sortField}` : ""}${
      payload?.sortOrder ? `&sortOrder=${payload?.sortOrder}` : ""
    }`
  );
};
const getAllAssetByGroupIDWithCheckCodeExistMethod = ({ payload, config }) => {
  return axios.get(
    `/asset/getAllAssetByGroupID/${payload.id}?filters=${encodeURIComponent(
      payload?.filters || ""
    )}`
  );
};

const getAllAssetNotAllocatedByGroupIDMethod = ({ payload, config }) => {
  return axios.get(
    `asset/getAllAssetNotAllocatedByGroupID/${
      payload.id
    }?filters=${encodeURIComponent(payload?.filters || "")}${
      payload?.currentPage ? `&currentPage=${payload?.currentPage}` : ""
    }${payload?.pageSize ? `&pageSize=${payload?.pageSize}` : ""}${
      payload?.sortField ? `&sortField=${payload?.sortField}` : ""
    }${payload?.sortOrder ? `&sortOrder=${payload?.sortOrder}` : ""}`
  );
};
const getAllAssetAllocatedByGroupIDMethod = ({ payload, config }) => {
  return axios.get(
    `asset/getAllAssetAllocatedByGroupID/${
      payload.id
    }?filters=${encodeURIComponent(payload?.filters || "")}${
      payload?.currentPage ? `&currentPage=${payload?.currentPage}` : ""
    }${payload?.pageSize ? `&pageSize=${payload?.pageSize}` : ""}${
      payload?.sortField ? `&sortField=${payload?.sortField}` : ""
    }${payload?.sortOrder ? `&sortOrder=${payload?.sortOrder}` : ""}`
  );
};
const getByIDMethod = ({ payload, config }) => {
  return axios.get(`/asset/getByID/${payload.id}`);
};
const postAssetMethod = ({ payload, config }) => {
  return axios.post(`/asset/postAsset`, { ...payload.data });
};
const putAssetMethod = ({ payload, config }) => {
  return axios.put(`/asset/putAsset/${payload.id}`, { ...payload.data });
};
const deleteAssetMethod = ({ payload, config }) => {
  return axios.delete(`/asset/deleteAsset/${payload.id}`);
};
const postListAssetMethod = ({ payload = {}, config }) => {
  return axios.post(`asset/postListAsset`, [...payload.data]);
};

// Asset Group
const getAllAssetGroupMethod = ({ payload = {}, config }) => {
  return axios.get(
    `/asset-group/getAllAssetGroup?filters=${encodeURIComponent(
      payload?.filters || ""
    )}
    ${payload?.currentPage ? `&currentPage=${payload?.currentPage}` : ""}
    ${payload?.pageSize ? `&pageSize=${payload?.pageSize}` : ""}
    ${payload?.sortField ? `&sortField=${payload?.sortField}` : ""}
    ${payload?.sortOrder ? `&sortOrder=${payload?.sortOrder}` : ""}`
  );
};
const getAssetGroupDetailMethod = ({ payload = {}, config }) => {
  return axios.get(`/asset-group/getAssetGroup/${payload.id}`);
};
const createTempAssetGroupMethod = ({ payload, config }) => {
  return axios.post(`/asset-group/postAssetGroup`, {
    asset_tree_id: payload.id,
  });
};
const importAssetGroupListMethod = ({ payload, config }) => {
  return axios.post(`/asset/importAsset/${payload.treeID}`, payload.data);
};
const putAssetGroupMethod = ({ payload, config }) => {
  return axios.put(`/asset-group/putAssetGroup/${payload.id}`, {
    ...payload.data,
  });
};
const putAssetGroupFinalMethod = ({ payload, config }) => {
  return axios.put(
    `/asset-group/putAssetGroupFinal/${payload.id}`,
    payload.data
  );
};
const deleteAssetGroupMethod = ({ payload, config }) => {
  return axios.delete(`/asset-group/deleteAssetGroup/${payload.id}`);
};
const uploadFileAssetGroupMethod = ({ payload = {}, config }) => {
  const formData = new FormData();
  formData.append("file", payload.file, payload.file.name);
  return axios.post(
    `asset-group/uploadFileAssetGroup/${payload.id}`,
    formData,
    {
      ...config,
      "Content-Type": "multipart/form-data",
      onUploadProgress: async (progressEvent) => {
        const { loaded, total } = progressEvent;
        const percentageProgress = Math.floor((loaded / total) * 100);
        await payload.dispatch(
          setUploadProgress({
            id: payload.index,
            progress: percentageProgress,
          })
        );
      },
    }
  );
};
const retryUploadFileAssetGroupMethod = async ({ payload = {}, config }) => {
  await payload.dispatch(retryUploadProgress({ id: payload.index }));

  return uploadFileAssetGroupMethod({ ...payload });
};
const deleteFileAssetGroupMethod = ({ payload = {}, config }) => {
  return axios.delete(`asset-group/deleteFileAssetGroup/${payload.id}`);
};
// User
const getAllUserMethod = ({ payload = {}, config }) => {
  return axios.get(
    `/user/getAllUser?filters=${
      (payload.filters && encodeURIComponent(payload.filters)) || ""
    }${(payload.sortField && `&sortField=${payload.sortField}`) || ""}${
      (payload.sortOrder && `&sortOrder=${payload.sortOrder}`) || ""
    }${(payload.currentPage && `&currentPage=${payload.currentPage}`) || ""}${
      (payload.pageSize && `&pageSize=${payload.pageSize}`) || ""
    }`
  );
};

//

const responseSuccess = (res) => {
  if (!res) return res;
  if (res.response) return { ...res.response.data };
  return res.data;
};

export const getAllAssetGroup = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getAllAssetGroupMethod,
      { payload, config },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};
export const getAssetGroupDetail = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getAssetGroupDetailMethod,
      { payload, config },
      actionTypes.GET_ASSET_GROUP_DETAIL,
      (res) => responseSuccess(res)
    );
  };
};
export const createTempAssetGroup = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      createTempAssetGroupMethod,
      { payload, config },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};
export const importAssetGroupList = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      importAssetGroupListMethod,
      { payload, config },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};
export const putAssetsGroup = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      putAssetGroupMethod,
      { payload, config },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};
export const putAssetGroupFinal = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      putAssetGroupFinalMethod,
      { payload, config },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};

export const deleteAssetGroup = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      deleteAssetGroupMethod,
      { payload, config },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};

export const getAllUser = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getAllUserMethod,
      { payload, config },
      actionTypes.GET_ALL_USERS,
      (res) => responseSuccess(res)
    );
  };
};
export const getAllAssetByGroupID = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getAllAssetByGroupIDMethod,
      { payload, config },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};
export const getAllAssetByGroupIDWithCheckCodeExist = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getAllAssetByGroupIDWithCheckCodeExistMethod,
      { payload, config },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};
export const getAllAssetNotAllocatedByGroupID = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getAllAssetNotAllocatedByGroupIDMethod,
      { payload, config },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};
export const getAllAssetAllocatedByGroupID = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getAllAssetAllocatedByGroupIDMethod,
      { payload, config },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};
export const getByID = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getByIDMethod,
      { payload, config },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};
export const postAsset = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      postAssetMethod,
      { payload, config },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};
export const putAsset = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      putAssetMethod,
      { payload, config },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};
export const deleteAsset = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      deleteAssetMethod,
      { payload, config },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};
export const uploadFileAssetGroup = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      uploadFileAssetGroupMethod,
      { config, payload: { ...payload, dispatch } },
      actionTypes.NO_ACTION,
      (res) => res.data
    );
  };
};
export const retryUploadFileAssetGroup = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      retryUploadFileAssetGroupMethod,
      { config, payload: { ...payload, dispatch } },
      actionTypes.NO_ACTION,
      (res) => res.data
    );
  };
};
export const deleteFileAssetGroup = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      deleteFileAssetGroupMethod,
      { config, payload },
      actionTypes.NO_ACTION,
      (res) => res.data
    );
  };
};
export const postListAsset = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      postListAssetMethod,
      { config, payload },
      actionTypes.NO_ACTION,
      (res) => res.data
    );
  };
};

export const getAssetRequestHistoryByAssetId = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getAssetRequestHistoryByAssetIdMethod,
      { config, payload },
      actionTypes.GET_ASSET_HISTORY,
      (res) => responseSuccess(res)
    );
  };
};

export const getAllAssetRequestType = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getAllAssetRequestTypeMethod,
      { config, payload },
      actionTypes.GET_ALL_REQUEST_TYPE,
      (res) => responseSuccess(res)
    );
  };
};

export const unsetAssetDetail = () => {
  return (dispatch) => {
    return baseService.dispatchStoreAction(
      actionTypes.UNSET_ASSET_DETAIL,
      {
        type: "UNSET_ASSET_DETAIL",
      },
      dispatch
    );
  };
};
