import baseService from "src/helper/baseService";
import * as actionTypes from "./actionTypes";

export const setDropdownCurrentPage = (payload = {}, config) => {
  return async (dispatch) => {
    return baseService.dispatchStoreAction(
      actionTypes.SET_DROPDOWN_CURRENT_PAGE,
      payload,
      dispatch,
    );
  };
};

export const setRowsPerDropdownPage = (payload = {}, config) => {
  return async (dispatch) => {
    return baseService.dispatchStoreAction(
      actionTypes.SET_ROWS_PER_DROPDOWN_PAGE,
      payload,
      dispatch,
    );
  };
};

export const setTotalDropdownPages = (payload = {}, config) => {
  return async (dispatch) => {
    return baseService.dispatchStoreAction(
      actionTypes.SET_TOTAL_DROPDOWN_PAGES,
      payload,
      dispatch,
    );
  };
};

export const resetDropdownPaginationState = (payload = {}, config) => {
  return async (dispatch) => {
    return baseService.dispatchStoreAction(
      actionTypes.RESET_DROPDOWN_PAGINATION_STATE,
      payload,
      dispatch,
    );
  };
};
