import baseService from "src/helper/baseService";
import axios from "../../helper/axios";
import * as actionTypes from "./actionTypes";

const createNewPasswordMethod = ({ payload, config }) => {
  return axios.post(`user/createNewPassword/${payload.token}`, payload.values, config);
};

const createNewPasswordSuccess = (res) => {
  return res.data;
};

export const createNewPassword = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      createNewPasswordMethod,
      { config, payload: {
        token: payload.code,
        values: payload.data
      }},
      null,
      (res) => createNewPasswordSuccess(res)
    );
  };
};