import React, { useCallback, useEffect, useState } from "react";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import { useDispatch } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import configuration from "src/configuration/index";
import { addItemScanMobile } from "src/store/actions";
import beepSound from "../../../assets/sounds/beep-07.wav";
import Modal from "../../../components/reporting/reporting-page/ReportInventoryDetailModal";
import axiosWrapper from "../../../helper/axios";
import AssetDetail from "./asset-detail/AssetDetail";
import AssetList from "./asset-list/AssetList";
import "./scanBarcode.scss";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ScanBarcode = (props) => {
  const QRid = props.match.params.id;
  let query = useQuery();

  const history = useHistory();
  const dispatch = useDispatch();
  const [stopStream, setStopStream] = useState(false);
  const [load, setLoad] = useState(true);
  const sound = new Audio(beepSound);
  const [data, setData] = React.useState([]);
  const [dub, setDub] = useState(false);
  const [err, setErr] = useState("");
  const [success, setSuccess] = useState("");
  const [showAssetDetail, setShowAssetDetail] = useState(false);
  const [listAsset, setListAsset] = useState([]);
  const [chosenAsset, setChosenAsset] = useState({});
  const [modal, setModal] = useState(false);
  const [barcodeData, setBarcodeData] = useState("");

  const closeHandler = () => setShowAssetDetail(false);

  const openHandler = (id) => {
    const targetAsset = listAsset.find((item) => item.id === id);
    setChosenAsset(targetAsset);
    setShowAssetDetail(true);
  };

  const addData = (barcode) => {
    const dubData = data.findIndex((item) => item === barcode);
    if (dubData < 0) {
      sound.play();
      data.push(barcode);
      setData([...data]);
      setStopStream(true);
      getScanItem();
      setDub(false);
    } else {
      setDub(true);
    }
  };

  const getScanItem = async () => {
    const assetCode = { asset_code: data[data.length - 1] };
    const datas = {
      ...assetCode,
      groupID: query.get("groupId"),
      warehouseID: query.get("warehouseId"),
    };

    const res = await dispatch(
      addItemScanMobile({
        id: QRid,
        data: datas,
      })
    );
    if (res) {
      const { status, message } = res;
      if (status === "success") {
        setSuccess("Quét mã vạch thành công");
      } else {
        if (typeof message === "string") {
          setErr(message);
        } else {
          setErr("Quét mã vạch thất bại");
        }
      }
      setLoad(false);
      setStopStream(false);
      setTimeout(() => {
        setLoad(true);
      }, 200);
    }
  };

  const handleDone = () => {
    window.close();
  };

  // ASSET INFORMATION
  // const fetchAssetItem = useCallback(
  //   (code) => {
  //     let existsAsset = listAsset.some((item) => {
  //       return item.code === code;
  //     });
  //     if (existsAsset) {
  //       setErr("Mã vạch đã scan");
  //       setBarcodeData("");
  //       return;
  //     } else {
  //       if (barcodeData) {
  //         setErr("");
  //         axiosWrapper
  //           .get(`${configuration.apiEndpoint}/asset/getByCode/${code}`)
  //           .then((res) => {
  //             res.data?.responseData &&
  //               setListAsset((prev) => {
  //                 return [res.data.responseData, ...prev];
  //               });
  //           });
  //         !QRid && sound.play();
  //         setBarcodeData("");
  //       }
  //     }
  //   },
  //   [listAsset, sound, barcodeData, QRid]
  // );

  const fetchAssetItem = (code) => {
    let existsAsset = listAsset.find((item) => item.asset_barcode == code);
    if (existsAsset) {
      setErr("Mã vạch đã scan");
      setBarcodeData("");
      return;
    } else {
      if (barcodeData) {
        setErr("");
        axiosWrapper
          .get(`${configuration.apiEndpoint}/asset/getByCode/${code}`)
          .then((res) => {
            if (res) {
              if (res?.data?.responseData) {
                const newListAsset = [...listAsset];
                newListAsset.push(res?.data?.responseData);
                setListAsset(newListAsset);
              }
            }
          });
        !QRid && sound.play();
        setBarcodeData("");
      }
    }
  };

  useEffect(() => {
    fetchAssetItem(barcodeData);
  }, [barcodeData]);

  const openModal = () => setModal(true);

  useEffect(() => {
    setTimeout(() => {
      setErr("");
    }, 10000);
  }, [err]);

  useEffect(() => {
    setTimeout(() => {
      setSuccess("");
    }, 5000);
  }, [success]);

  return (
    <>
      <div className="camera-container">
        {load && (
          <BarcodeScannerComponent
            width={"100%"}
            height={"100%"}
            delay={100}
            onUpdate={(err, result) => {
              if (result) {
                if (QRid) {
                  addData(result.text);
                }
                setBarcodeData(result.text);
              }
            }}
          />
        )}
        {success !== "" && (
          <div className="success-msg">
            <div className="success-container">
              <p>{success}</p>
            </div>
          </div>
        )}
        <div className="test">
          {dub ? (
            <p className="dub">Mã vạch đã được scan</p>
          ) : err !== "" ? (
            <p className="dub">{err}</p>
          ) : null}
        </div>
        {listAsset.length > 0 && (
          <AssetList openHandler={openHandler} listAsset={listAsset} />
        )}
        {showAssetDetail && (
          <AssetDetail
            closeHandler={closeHandler}
            isInventory={QRid ? false : true}
            chosenAsset={chosenAsset}
            openModal={openModal}
          />
        )}
        <Modal
          id={chosenAsset?.id}
          show={modal}
          handleModal={(close) => setModal(close)}
          assetInventory={true}
        />
        <button
          className="btn btn-primary btn-done"
          onClick={handleDone}
          component={Link}
          to="/"
        >
          Xong
        </button>
      </div>
    </>
  );
};

export default ScanBarcode;
