import baseService from "src/helper/baseService";
import axios from "../../helper/axios";
import * as actionTypes from "../actions/actionTypes";

const getAssetCategoriesMethod = ({payload = {}, config = {}}) => {
	return axios.get("asset-category/getAllAssetCategory", payload, config);
};
const getAssetCategoriesDetailMethod = ({payload = {}, config = {}}) => {
	return axios.get(`asset-category/getAssetCategory/${payload.id}`);
};

const responseSuccess = (res) => {
	if (!res) return res
	if (res.response) return {...res.response.data}
	return res.data;
}
  
export const getAssetCategories = (payload, config) => {
	return async (dispatch) => {
		return baseService.serviceRequest(
			{},
			dispatch,
			getAssetCategoriesMethod,
			{ payload, config },
			actionTypes.SET_ASSETS_CATEGORIES,
			(res) => responseSuccess(res)
		);
	};
};
export const getAssetCategoriesDetail = (payload, config) => {
	return async (dispatch) => {
		return baseService.serviceRequest(
			{},
			dispatch,
			getAssetCategoriesDetailMethod,
			{ payload, config },
			actionTypes.NO_ACTION,
			(res) => responseSuccess(res)
		);
	};
};

export const setFormatCurrency = (value) => !value ? value : value.toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",").replace(/^0/g, "")
export const setFormatDateDMY = (value) => !value ? value : value.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",").replace(/^0/g, "")