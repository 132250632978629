import React, { useState, useEffect } from "react";
import styled from "styled-components";
import moment from "moment";
import { CModalTitle } from "@coreui/react";
import { Drawer } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { useDeviceSize } from "../../../helper/hooks";
import Mdal from "../../../sharedComponents/Modal/index";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Row,
  Col,
} from "../../../sharedComponents/Table/Table";
import WrappedDropDownList from "../../../sharedComponents/DropDownList/WrappedDropDownList";
import {
  getAssetInventoryItemByID,
  putAssetInventoryItem,
  getAllAssetStatus,
  getAllAssetInventoryCriteriaByAssetInventoryId,
  revertAssetInventoryItemCriteria,
  getAssetInInventory,
  getByID,
  getAssetInventoryTeam,
  setMessage,
} from "../../../store/actions";
import sliceKeys from "../../../store/sliceKeys";
import TabInfo from "./TabInfoInventoryReport";
import TabReview from "./TabReviewInventoryReport";
import Tabs from "../../../sharedComponents/Tabs";

import closeIcon from "../../../assets/icons/close.svg";

import "./reporting-page.scss";

export const modelProps = {
  listTabs: {
    info: {
      name: "Thông tin kỳ kiểm kê",
      path: null,
      icon: null,
      value: "info",
    },
    review: {
      name: "Bảng đánh giá",
      path: null,
      icon: null,
      value: "review",
    },
  },
};

const ID_MANAGER = "9d6b4cd4-dd5c-46f8-9087-c4bc7be9dd74"
const Button = styled.button`
  height: 20px;
  display: flex;
`;

const ReportInventoryDetailTabs = (props) => {
  const { id, show, handleModal, assetInventory = false } = props;
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [{ width, height }, setSize] = useDeviceSize();
  const [listTabs, setListTabs] = useState([]);
  const [currentTab, setCurrentTab] = useState({});
  const [item, setItem] = useState({});
  const [finalStatuses, setFinalStatuses] = useState([]);
  const [criteria, setCriteria] = useState([]);
  const [submitCriteria, setSubmitCriteria] = useState([]);
  const [edit, setEdit] = useState(false);
  const [assetInventoryId, setAssetInventoryId] = useState(false);
  const [periodLoaded, setPeriodLoaded] = useState(false);
  const [detailLoaded, setDetailLoaded] = useState(false);
  const [listAssetInventory, setListAssetInventory] = useState([]);

  const fetchTabContent = (id, item, setItem, criteria, finalStatuses, setSubmitCriteria, edit, ) => {
    switch (currentTab.value) {
      case "info":
        return <TabInfo id={id} item={item} setItem={setItem} edit={edit} />;
      case "review":
        return (
          <TabReview
            id={id}
            item={item}
            setItem={setItem}
            criteria={criteria}
            finalStatuses={finalStatuses}
            setSubmitCriteria={setSubmitCriteria}
            edit={edit}
          />
        );
      default:
        return null;
    }
  };
  const fetchDetail = async (id) => {
    const response = await dispatch(getAssetInventoryItemByID({ id }));
    if (!response || response.status !== "success" || !response.responseData) {
      return;
    }
    setItem((_) => ({
      code: response.responseData.asset.code,
      name: response.responseData.asset.asset_group.name,
      time_reported: response.responseData.inventory_at || null,
      reporter:
        (response.responseData.status_id === null && response.responseData.updated_by_user) || null, 
        // response.responseData.asset_inventory_user_items[0]?.asset_inventory_user
        //   ?.user,
      seri: response.responseData.asset.seri,
      in_charge: response.responseData.asset.assistant_user_user,
      // departmentWarehouse:
      //   response.responseData.asset_inventory_user_items?.asset_inventory_user
      //     ?.department_id ||
      //   response.responseData.asset_inventory_user_items?.asset_inventory_user
      //     ?.asset_warehouse ||
      //   "",
      departmentWarehouse: response.responseData.asset_inventory_user_items[0]?.asset_inventory_user?.inventory_department?.id !== null ?
        response.responseData.asset_inventory_user_items[0]?.asset_inventory_user?.inventory_department?.name
        : response.responseData.asset_inventory_user_items[0]?.asset_inventory_user?.asset_warehouse?.id !== null ?
          response.responseData.asset_inventory_user_items[0]?.asset_inventory_user?.asset_warehouse?.name : '',
      departmentWarehouseId: response.responseData.asset_inventory_user_items[0]?.asset_inventory_user?.inventory_department?.id ||
        response.responseData.asset_inventory_user_items[0]?.asset_inventory_user?.asset_warehouse?.id ||
        '',
      location: response.responseData.asset.location,
      // depreciation: response.responseData.asset.depreciationPercentage,
      depreciation: response.responseData.asset.depreciation_per_year,
      residual_depreciation: response.responseData.asset.residualCostPercentage,
      residualCost: response.responseData.asset.depreciation,
      cost: response.responseData.asset.residualCost,
      description: response.responseData.note,
      report_conclusion: response.responseData.final_status,
      // report_conclusion: response.responseData.status.id
      // ? response.responseData.status : response.responseData.final_status,
      asset_inventory_item_criteria:
        response.responseData.asset_inventory_item_criteria,
      asset_inventory_id: response.responseData.asset_inventory_id,
      unique_key: response.responseData.unique_key,
      start_date: response.responseData.asset_inventory.start_date,
      end_date: response.responseData.asset_inventory.end_date,
      asset_inventory_users:
        response.responseData.asset_inventory.asset_inventory_users,
      updated_by_user: response.responseData.updated_by_user,
      updated_at: response.responseData.updated_at,
      status: response.responseData.status,
      asset_id: response.responseData.asset.id,
    }));
  };
  const fetchAssetInfoByAssetId = async (id) => {
    const response = await dispatch(getByID({ id }));
    if (!response || response.status !== "success" || !response.responseData)
      return;
    setItem((prev) => ({
      ...prev,
      departmentWarehouse:
        response.responseData.status_id === null &&
        response.responseData.asset_warehouse_id !== null
          ? response.responseData.asset_warehouse_id
          : response.responseData.status_id !== null &&
            response.responseData.assistant_department_id !== null
          ? response.responseData.assistant_department_id
          : null,
      // not includes in responseData.asset
      code: response.responseData.code, 
      name: response.responseData.asset_group.name, 
      depreciation: response.responseData.depreciation_per_year,
      // residual_depreciation: response.responseData.asset.depreciation_per_year 
      // ? 100 - +response.responseData.asset.depreciation_per_year : null,
      residual_depreciation: response.responseData.residualCostPercentage,
      residualCost: response.responseData.depreciation,
      cost: response.responseData.residualCost,
      
    }));
  };
  const fetchReporters = async (id, item) => {
    const response = await dispatch(
      getAssetInventoryTeam({
        assetInventoryId: item.asset_inventory_id,
        departmentOrWarehouseId: id,
      })
    );
    if (!response || response.status !== "success") {
      return setItem((prev) => ({ ...prev, asset_inventory_users: [] }));
    }
    setItem((prev) => ({ ...prev, asset_inventory_users: response.responseData.map(item => ({ref_user: {
      id: item.id,
      fullname: item.name,
      position: item.position,
    }})) }));
  }
  const fetchStatuses = async () => {
    const response = await dispatch(
      getAllAssetStatus({ code: `INVENTORY_ITEM_FINAL` })
    );
    if (!response || response.status !== "success") {
      return setFinalStatuses([]);
    }
    setFinalStatuses(response.responseData);
  };
  const fetchCriteria = async (asset_inventory_id, id) => {
    // set fetching talbe
    const response = await dispatch(
      getAllAssetInventoryCriteriaByAssetInventoryId({ id: asset_inventory_id })
    );
    if (!response || response.status !== "success" || !response.responseData?.rows.length) {
      setCriteria([]);
      setItem((prev) => ({
        ...prev,
        asset_inventory_item_criteria: [],
      }));
      return 
    }
    setCriteria([...response.responseData.rows]);
    //Don't uncomment this code
    // setItem((prev) => ({
    //   ...prev,
    //   asset_inventory_item_criteria: [
    //     ...response.responseData.rows.map((criteria, index) => ({
    //       asset_inventory_criteria_attachments: [],
    //       asset_inventory_criteria_id: criteria.id,
    //       asset_inventory_criteria_option_id: null,
    //       asset_inventory_item_id: id,
    //       description: null,
    //       id: (prev.asset_inventory_item_criteria?.length 
    //         && prev.asset_inventory_item_criteria[index]?.id) || null,
    //     })),
    //   ],
    // }));
  };
  const handleSubmit = async (item, listSubmitCriteria, temp = false) => {
    let payload = {
      id: assetInventory? item.asset_inventory_info_id : id,
      inventory_at: item.time_reported || moment().format("YYYY-MM-DD HH:mm:ss"),
      final_status_id: item.report_conclusion?.id,
      status_id: temp ? "274aae13-4675-4e97-838b-d26847270a75" : null,
      note: item.description || null,
      unique_key: item.unique_key,
      asset_inventory_item_critterias: listSubmitCriteria,
    };
    const response = await dispatch(putAssetInventoryItem(payload));
    if (!response || response.status !== "success" || !response.responseData) {
      if (props.assetInventory) {
        return await dispatch(
          setMessage({
            message: temp
              ? `Lưu tạm kiểm kê thất bại`
              : `Duyệt kiểm kê thất bại`,
            type: sliceKeys.message_danger,
          })
        );
      }
      return await dispatch(
        setMessage({
          message: temp
            ? `Lưu tạm biên bản thất bại`
            : `Duyệt biên bản thất bại`,
          type: sliceKeys.message_danger,
        })
      );
    }
    if (props.assetInventory) {
      await dispatch(
        setMessage({
          message: temp
            ? `Lưu tạm kiểm kê thành công`
            : `Duyệt kiểm kê thành công`,
          type: temp ? sliceKeys.message_info : sliceKeys.message_success,
        })
      );
      handleModal(false);
      return
    }
    else {
      await dispatch(
        setMessage({
          message: temp
          ? `Lưu tạm biên bản thành công`
          : `Duyệt biên bản thành công`,
          type: temp ? sliceKeys.message_info : sliceKeys.message_success,
        })
      );
      props.fetchAllData();
      handleModal(false);
    }
  };
  const revertCriteria = async (unique_key) => {
    const response = await dispatch(
      revertAssetInventoryItemCriteria({ unique_key })
    );
    if (!response || response.status !== "success" || !response.responseData) {
      return;
    }
    setItem({})
  };
  // Scan mobile not for desktop
  const fetchListAssetInventory = async (id) => {
    const response = await dispatch(getAssetInInventory({ id }));
    if (!response || response.status !== "success" || !response.responseData) {
      return;
    }
    const sortedListInventoryPeriods = response.responseData.map((item) => ({
      id: item.id,
      asset_inventory_id: item.asset_inventory?.id,
      name: item.asset_inventory?.name,
      start_date: item.asset_inventory.start_date,
      end_date: item.asset_inventory.end_date,
    }))
    .sort((a, b) => {
      if (moment(a.start_date, "YYYY-MM-DD HH:mm:ss").isSame(b.start_date)) return 0
      return moment(a.start_date, "YYYY-MM-DD HH:mm:ss").isAfter(b.start_date)? 1: -1;
    })
    setListAssetInventory(sortedListInventoryPeriods);
    if (!sortedListInventoryPeriods.length) return
    setAssetInventoryId(sortedListInventoryPeriods[0]?.asset_inventory_id || false);
    setItem((prev) => ({
      ...prev,
      start_date:
        sortedListInventoryPeriods[0]?.start_date || moment().format("YYYY-MM-DD"),
      end_date: sortedListInventoryPeriods[0]?.end_date || moment().format("YYYY-MM-DD"),
      asset_inventory_info_id: sortedListInventoryPeriods[0]?.id
    }));
  };
  const fetchTest = async ({
    currentPage = 1,
    pageSize = 5,
    search = "",
    more = false,
    setMore,
    setTotalPages,
    setSelected,
    fetchSelected = false,
  }) => {
    if (!item.asset_id) return
    const response = await dispatch(getAssetInInventory({ id: item.asset_id }));
    if (!response || response.status !== "success" || !response.responseData) {
      setTotalPages && setTotalPages(0);
      setListAssetInventory([]);
      return;
    }
    setTotalPages && setTotalPages(response.responseData.totalPages);
    if (more) {
      setListAssetInventory((prev) =>
        prev.concat(
          response.responseData.map((item) => ({
            id: item.id,
            asset_inventory_id: item.asset_inventory?.id,
            name: item.asset_inventory?.name,
            start_date: item.asset_inventory?.start_date,
            end_date: item.asset_inventory?.end_date,
          }))
        )
      );
      return setMore && setMore(false);
    }
    setListAssetInventory(
      response.responseData.map((item) => ({
        id: item.id,
        asset_inventory_id: item.asset_inventory?.id,
        name: item.asset_inventory?.name,
      }))
    );
  };
  const fetchModelTabs = async () => {
    const modelListTabs = modelProps.listTabs;
    setListTabs(modelListTabs);
    setCurrentTab(modelListTabs[Object.keys(modelListTabs)[0]]);
  }

  useEffect(() => {
    fetchModelTabs()
    return () => {
    }
  }, []);
  useEffect(() => {
    if (show) fetchModelTabs()
    return () => {
      if (show) {
        setItem({});
        setFinalStatuses([]);
        setCriteria([]);    
        setPeriodLoaded(false);
        setListTabs([]);
        setCurrentTab({});
        setSubmitCriteria([]);
        setEdit(false);
        setAssetInventoryId(false);
        setPeriodLoaded(false);
        setDetailLoaded(false);
        setListAssetInventory([]);
        if (edit && item.unique_key) {
          revertCriteria(item.unique_key);
        }
      }
    };
  }, [show,]);
  useEffect(() => {
    if (show && id) {
      // fetch API for
      if(!assetInventory) {
        fetchDetail(id);
        if (!detailLoaded) {
          setDetailLoaded(true)
        }
      } else {
        // fetch API for
        fetchListAssetInventory(id);
        fetchAssetInfoByAssetId(id);
        if (!detailLoaded) {
          setDetailLoaded(true)
        }
      }
      fetchStatuses();
    }
  }, [show, id]);
  // useEffect(() => {
  //   if (show && props.assetInventory && item.asset_id) {
  //     // fetch API for
  //     // fetchListAssetInventory(item.asset_id);
  //     // fetchAssetInfoByAssetId(item.asset_id);
  //     fetchListAssetInventory(id);
  //     fetchAssetInfoByAssetId(id);
  //     if (!detailLoaded) {
  //       setDetailLoaded(true)
  //     }
  //   }
  // }, [show, item.asset_id]);
  useEffect(() => {
    if (show && item.asset_inventory_id) {
      fetchCriteria(item.asset_inventory_id, id);
      // if (assetInventory && !periodLoaded)
      // if (assetInventory)
      setAssetInventoryId(item.asset_inventory_id);
      setCurrentTab(listTabs[Object.keys(listTabs)[0]]);
    } else if (show && !item.asset_inventory_id) {
      setCriteria([]);
      setItem((prev) => ({
        ...prev,
        asset_inventory_item_criteria: [],
      }));
    }
  }, [item.asset_inventory_id]);
  useEffect(() => {
    // if (show && assetInventory && periodLoaded) {
    if (show && assetInventory && assetInventoryId) {
      setItem((prev) => ({ ...prev, asset_inventory_id: assetInventoryId }));
    }
    return () => {
      if (assetInventory && !assetInventoryId && !periodLoaded) {
        setPeriodLoaded(true);
      }
    };
  }, [assetInventoryId]);
  useEffect(() => {
	if (show && assetInventory && item.departmentWarehouseId && item.asset_inventory_id) {
		fetchReporters(item.departmentWarehouseId, item);
	}
  }, [item.departmentWarehouseId , item.asset_inventory_id]);
  useEffect(() => {
    if (!show) return
    const unsatisfiedStatus = item.status?.id === null;
    const satisfiedUser = item.asset_inventory_users?.find(
      (item) => item?.ref_user?.id === user.id
    );
    if (assetInventory) {
      setEdit(!unsatisfiedStatus && satisfiedUser ? true : false);
    }
    else {
      const isManager = satisfiedUser?.asset_inventory_position?.id === ID_MANAGER // id Trưởng phòng - Manager
      const hasPermission = isManager ? true : satisfiedUser?.inventory_department_id?.includes(item?.departmentWarehouseId) || satisfiedUser?.asset_warehouse_id?.includes(item?.departmentWarehouseId)
      setEdit(!unsatisfiedStatus && hasPermission ? true : false);
    }
  }, [user, finalStatuses, item.asset_inventory_users, item.status]);
  useEffect(() => {
    if (show && detailLoaded && assetInventory && item.asset_inventory_info_id) {
      const updateDataByAssetInventoryInfo = async () => {
        const response = await dispatch(getAssetInventoryItemByID({ id: item.asset_inventory_info_id }));
        if (!response || response.status !== "success" || !response.responseData) {
          return;
        }
        //ko đụng tới - ko liên quan
        setItem((prev) => ({...prev, 
          code: response.responseData.asset.code,
          name: response.responseData.asset.asset_group.name,
          time_reported: response.responseData.inventory_at || null,
          reporter:
            (response.responseData.status_id === null && response.responseData.updated_by_user) || null, 
            // &&
            
            // response.responseData.asset_inventory_user_items?.asset_inventory_user
            //   ?.user,
          seri: response.responseData.asset.seri,
          in_charge: response.responseData.asset.assistant_user_user,
          // departmentWarehouse:
          //   response.responseData.asset_inventory_user_items?.asset_inventory_user
          //     ?.department_id ||
          //   response.responseData.asset_inventory_user_items?.asset_inventory_user
          //     ?.asset_warehouse ||
          //   "",
          departmentWarehouse: response.responseData.asset_inventory_user_items[0]?.asset_inventory_user?.inventory_department?.id !== null ?
            response.responseData.asset_inventory_user_items[0]?.asset_inventory_user?.inventory_department?.name
            : response.responseData.asset_inventory_user_items[0]?.asset_inventory_user?.asset_warehouse?.id !== null ?
              response.responseData.asset_inventory_user_items[0]?.asset_inventory_user?.asset_warehouse?.name : '',
          departmentWarehouseId: response.responseData.asset_inventory_user_items[0]?.asset_inventory_user?.inventory_department?.id ||
            response.responseData.asset_inventory_user_items[0]?.asset_inventory_user?.asset_warehouse?.id ||
            '',
          location: response.responseData.asset.location,
          depreciation: response.responseData.asset.depreciation_per_year,
          residual_depreciation: response.responseData.asset.residualCostPercentage,
          residualCost: response.responseData.asset.depreciation,
          cost: response.responseData.asset.residualCost,
          description: response.responseData.note,
          report_conclusion: response.responseData.final_status,
          // report_conclusion: response.responseData.status.id
          // ? response.responseData.status : response.responseData.final_status,
          asset_inventory_item_criteria:
            response.responseData.asset_inventory_item_criteria,
          asset_inventory_id: response.responseData.asset_inventory_id,
          unique_key: response.responseData.unique_key,
          start_date: response.responseData.asset_inventory.start_date,
          end_date: response.responseData.asset_inventory.end_date,
          // asset_inventory_users:
          //   response.responseData.asset_inventory.asset_inventory_users,
          updated_by_user: response.responseData.updated_by_user,
          updated_at: response.responseData.updated_at,
          status: response.responseData.status,
          asset_id: response.responseData.asset.id,
        }));
      }
      updateDataByAssetInventoryInfo()
    }
  }, [item.asset_inventory_info_id])
  useEffect(() => {
    if (edit) {
      setItem((prev) => ({
        ...prev,
        time_reported:
          prev.time_reported || null,
      }));
    }
  }, [edit]);
  const getBody = () => (
    <div>
      {assetInventory && (
        <>
          <div className="reporting-page__modal reporting-page__label__text">
            Đợt kiểm kê
          </div>
          <WrappedDropDownList
            placeholder={"Chọn đợt kiểm kê"}
            value={assetInventoryId}
            actionMethod={{
              selected: (value) => {
                setAssetInventoryId(value?.asset_inventory_id || false);
                setItem((prev) => ({
                  ...prev,
                  start_date:
                    value?.start_date || moment().format("YYYY-MM-DD"),
                  end_date: value?.end_date || moment().format("YYYY-MM-DD"),
                  asset_inventory_info_id: value?.id
                }));
              },
              fetchAPI: getAssetInInventory,
              fetchOptions: fetchTest,
            }}
            text={{
              key: "asset_inventory_id",
              label: "name",
            }}
            listItems={listAssetInventory.map((item) => ({
              ...item,
              name: item.name || "--",
            }))}
            isHandlingOutside
          />
        </>
      )}
      <Tabs
        list={listTabs}
        current={currentTab}
        flexGrow
        actionMethod={(item) => setCurrentTab(item)}
      />
      {fetchTabContent(id, item, setItem, criteria, finalStatuses, setSubmitCriteria, edit, )}
    </div>
  );
  const getFooter = () => (
    <>
      <button
        className="button button--secondary"
        onClick={() => {
          handleModal(false);
        }}
      >
        Hủy
      </button>
      {currentTab?.value === "info" ? (
        <>
          <button
            className="button button--primary"
            onClick={() => {
              setCurrentTab(listTabs[Object.keys(listTabs)[1]]);
            }}
          >
            Tiếp
          </button>
        </>
      ) : edit && currentTab?.value === "review" ? (
        <>
          <button
            className="button button--success"
            onClick={() => {
              // handleModal(false);
              handleSubmit(item, submitCriteria);
            }}
          >
            Đã kiểm kê
          </button>
          <button
            className="button button--primary"
            onClick={() => {
              // handleModal(false);
              handleSubmit(item, submitCriteria, true);
            }}
          >
            Lưu tạm
          </button>
        </>
      ) : null}
    </>
  );

  return (
    <>
      {show && (((props.assetInventory && width > 768)|| (!props.assetInventory && width >= 480))) && (
        <Mdal
          className="reporting-page__modal"
          size="xl"
          show={show && (((props.assetInventory && width > 768)|| (!props.assetInventory && width >= 480)))}
          handleModal={async (value) => {
            handleModal(value);
          }}
          components={{
            header: () => (
              <>
                <CModalTitle>{`[${item.code || "--"}] ${item.name || "--"}`}</CModalTitle>
                <Button
                  className="button--border-less button--none-outlined"
                  onClick={() => handleModal(false)}
                >
                  <img src={closeIcon} alt="x" />
                </Button>
              </>
            ),
            body: getBody,
            footer: getFooter,
          }}
        />
      )}
      {show && ((props.assetInventory && width <= 768) || (width < 480)) &&  (
        <Drawer
          className="reporting-page__modal"
          title={`[${item.code || "--"}] ${item.name || "--"}`}
          height={"70vh"}
          visible={show && ((props.assetInventory && width <= 768) || (width < 480))}
          key={"bottom"}
          placement={"bottom"}
          onClose={async (e) => {
            handleModal(false);
          }}
          headerStyle={{
            minHeight: "3.5rem",
            height: "auto",
          }}
          footerStyle={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
          closable={false}
          extra={
            <Button
              className="button--border-less button--none-outlined"
              onClick={() => handleModal(false)}
            >
              <img src={closeIcon} alt="x" />
            </Button>
          }
          // maskClosable={false}
          footer={getFooter()}
        >
          {getBody()}
        </Drawer>
      )}
    </>
  );
};

export default ReportInventoryDetailTabs;
