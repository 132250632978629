import baseService from "src/helper/baseService";
import axios from "../../helper/axios";
import * as actionTypes from "../actions/actionTypes";

import { setMessage } from "./index";
import store from "../createStore";
import sliceKeys from "../sliceKeys";
import cookies from "../../helper/cookies";
import configuration from "src/configuration";
import querystring from "query-string";
const crypto = require("asymmetric-crypto");

export const generateSSO = () => {
  const keys = crypto.keyPair();
  const test = querystring.parse(window.location.search);
  localStorage.setItem("publicKey", JSON.stringify(keys.publicKey));
  localStorage.setItem("secretKey", JSON.stringify(keys.secretKey));
  const retrievedKeys = keys.publicKey;
  return `${configuration.ssoDomain}?returnUrl=${encodeURIComponent(
    window.location.href.replace("?token=" + test.token, "")
  )}&channel=${configuration.channel}&publicKey=${btoa(retrievedKeys)}`;
};

const loginMethod = ({ payload, config }) => {
  return axios.post("user/login", payload, config);
};

const validateMethod = ({ payload, config }) => {
  return axios.get("user/getMeData", {
    ...config,
    headers: { Authorization: `Bearer ${payload}` },
  });
};

const getNotificationTokenMethod = ({ payload, config }) => {
  return axios.get("notification/getToken/" + config, {
    ...config,
    headers: { Authorization: `Bearer ${payload}` },
  });
};

const loginSuccess = (res) => {
  if (res.response) return { ...res.response.data };
  return res.data;
};

export const isAuthenticated = () => {
  const test = querystring.parse(window.location.search);
  if (!test.token && localStorage.getItem(sliceKeys.auth)) {
    const res = store.dispatch(validate(localStorage.getItem(sliceKeys.auth)));
    res.then((value) => {
      if (
        localStorage.getItem(sliceKeys.auth) &&
        store.getState().auth &&
        store.getState().auth.user
      ) {
        return true;
      } else {
        return false;
      }
    });
    return true;
  } else {
    const keyPair = JSON.parse(localStorage.getItem("secretKey"));
    if (test.token === "" || test.token?.trim() === "") return false;
    try {
      let decodeToken = JSON.parse(atob(test.token));
      const Token = crypto.decrypt(
        decodeToken.data,
        decodeToken.nonce,
        decodeToken.SSOServerPublicKey,
        keyPair
      );
      const validateToken = store.dispatch(validate(Token));
      validateToken.then((value) => {
        if (!value?.data?.responseData) {
          window.location.href = generateSSO();
          return false;
        } else {
          if (Token) {
            localStorage.setItem(sliceKeys.auth, Token);
            store.dispatch(setToken({ token: Token }));
          }
          let currentUrl = window.location.href;
          currentUrl = currentUrl.replace("?token=" + test.token, "");
          window.history.replaceState({}, "", currentUrl);
          return true;
        }
      });
      return true;
    } catch (err) {
      store.dispatch({ type: actionTypes.RESET_TOKEN });
      return false;
    }
  }
};

export const login = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      loginMethod,
      { config, payload },
      actionTypes.SET_TOKEN,
      (res) => loginSuccess(res)
    );
  };
};

export const validate = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      validateMethod,
      { config, payload },
      actionTypes.SET_USER,
      (res) => res
    );
  };
};

export const setToken = (payload, config) => {
  return async (dispatch) => {
    return baseService.dispatchStoreAction(
      actionTypes.SET_TOKEN,
      { token: payload.token },
      dispatch
    );
  };
};

export const logout = (payload = {}, config) => {
  return async (dispatch) => {
    return baseService.dispatchStoreAction(
      actionTypes.RESET_TOKEN,
      payload,
      dispatch
    );
  };
};

export const getNotificationToken = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getNotificationTokenMethod,
      { config, payload },
      actionTypes.GET_WEB_TOKEN,
      (res) => res
    );
  };
};

export const unAuthenticated = (payload = {}, config) => {
  return async (dispatch) => {
    dispatch(
      setMessage({
        actionType: actionTypes.UNAUTHENTICATED,
        message: "Quyền truy cập hết hạn",
        type: sliceKeys.message_danger,
      })
    );
    dispatch(
      setMessage({
        actionType: actionTypes.UNAUTHENTICATED,
        // message: "Vui lòng đăng nhập lại để hoàn tác",
        message: "Tự động chuyển trang đăng nhập sau 5 giấy",
        type: sliceKeys.message_info,
      })
    );
    return dispatch(logout());
  };
};
