import baseService from "src/helper/baseService";
import axios from "../../helper/axios";
import * as actionTypes from "../actions/actionTypes";
  
export const clearFiles = (payload = {}, config) => {
	return async (dispatch) => {
	  return baseService.dispatchStoreAction(
		actionTypes.CLEAR_UPLOAD_FILE,
		{},
		dispatch,
	  );
	};
};
export const setUploadFile = (payload = {}, config) => {
	return async (dispatch) => {
	  return baseService.dispatchStoreAction(
		actionTypes.SET_UPLOAD_FILE,
		{
			files: payload.files,
			id: payload.id,
		},
		dispatch,
	  );
	};
};
export const unsetUploadFile = (payload = {}, config) => {
	return async (dispatch) => {
	  return baseService.dispatchStoreAction(
		actionTypes.UNSET_UPLOAD_FILE,
		{
			index: payload.index
		},
		dispatch,
	  );
	};
};
export const unsetFiles = (payload = {}, config) => {
	return async (dispatch) => {
	  return baseService.dispatchStoreAction(
		actionTypes.UNSET_UPLOAD_FILES,
		{
			id: payload.id
		},
		dispatch,
	  );
	};
};
export const setUploadProgress = (payload = {}, config) => {
	return async (dispatch) => {
		return baseService.dispatchStoreAction(
			actionTypes.SET_UPLOAD_PROGRESS,
			{
				id: payload.id,
				progress: payload.progress,
			},
			dispatch,
		);
	};
};
export const setSuccessProgress = (payload = {}, config) => {
	return async (dispatch) => {
	  return baseService.dispatchStoreAction(
		actionTypes.SUCCESS_UPLOAD_FILE,
		{
			id: payload.id,
		},
		dispatch,
	  );
	};
};
export const setFailedProgress = (payload = {}, config) => {
	return async (dispatch) => {
	  return baseService.dispatchStoreAction(
		actionTypes.FAILURE_UPLOAD_FILE,
		{
			id: payload.id,
		},
		dispatch,
	  );
	};
};
export const retryUploadProgress = (payload = {}, config) => {
	return async (dispatch) => {
	  return baseService.dispatchStoreAction(
		actionTypes.RETRY_UPLOAD_FILE,
		{
			id: payload.id,
		},
		dispatch,
	  );
	};
};
