/* eslint-disable jsx-a11y/alt-text */
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import closeIcon from "../../assets/icons/close.svg";
import searchIcon from "../../assets/icons/search.svg";
import { useOutsideAlerter } from "../../helper/hooks";
import {
  resetDropdownPaginationState,
  setDropdownCurrentPage,
} from "../../store/actions";
import "./DropDownList.scss";
const DropDownList = forwardRef(function DropDownList(
  {
    options,
    placeholder,
    value,
    onChange,
    paging,
    label,
    test,
    id,
    onSearch,
    onFocusInput,
    onLoadMore,
    optional,
    isSearchVisible = true,
    selectedClass,
    noSearch = false,
    className = "",
    onCloseSearch,
  },
  ref
) {
  const [open, setOpen] = useState(false);
  const [searchValue, SetsearchValue] = useState("");
  const [isShowValue, setIsShowValue] = useState(true);
  const [selectedValue, setSelectedValue] = useState(value);

  const resetInputValue = () => {
    onSearch("");
    SetsearchValue("");
    onFocusInput();
  };
  const dropDownRef = useRef();
  useOutsideAlerter(dropDownRef, () => {
    setOpen(false);
  });
  const dispatch = useDispatch();

  const onSearchChange = (value) => {
    if (!value) {
      setIsShowValue(false);
      setSelectedValue(null);
    } else {
      setIsShowValue(true);
    }
  };

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  return (
    <div className={`dropdown ${className}`} ref={dropDownRef}>
      <div
        className="control"
        onClick={() => {
          setOpen((prev) => !prev);
          dispatch(resetDropdownPaginationState());
        }}
      >
        <div className={`selected-value ${selectedClass}`} tabIndex="0">
          {/* {isShowValue && (
            <p>
              {selectedValue && test
                ? selectedValue[label]
                  ? selectedValue[label][test]
                  : ""
                : selectedValue
                ? selectedValue[label]
                : placeholder}
            </p>
          )} */}
          {/* {!isShowValue && <p>{placeholder}</p>} */}
          <p>
            {selectedValue && test
              ? selectedValue[label]
                ? selectedValue[label][test]
                : ""
              : selectedValue
              ? selectedValue[label]
              : placeholder}
          </p>
        </div>
        <div className={`arrow ${open ? "arrow-up" : null}`}></div>
      </div>
      <div
        className={`search ${open ? "open" : null} ${
          noSearch === true ? "noSearch" : null
        }`}
        style={{ display: !isSearchVisible && "none" }}
      >
        <img src={searchIcon} className="search-icon"></img>
        <img
          src={closeIcon}
          className="close-icon"
          onClick={() => resetInputValue()}
        ></img>
        <input
          ref={ref}
          type="text"
          placeholder="Tìm kiếm"
          value={searchValue}
          onClick={() => onFocusInput()}
          onChange={(e) => {
            onSearch(e.target.value);
            SetsearchValue(e.target.value);
            onSearchChange(e.target.value);
          }}
        ></input>
      </div>
      <div className={`options ${open ? "open" : null}`}>
        {options?.length
          ? options?.map((option, index) => {
              return (
                <div
                  key={option[id]}
                  className={`option ${
                    selectedValue === option ? "selected" : null
                  }`}
                  onClick={() => {
                    onChange(option);
                    setOpen(false);
                  }}
                >
                  {test ? (
                    option[label] ? (
                      option[label][test]
                    ) : (
                      `--`
                    )
                  ) : optional ? (
                    <div className="optional">
                      <p>{option[label] ?? `--`}</p>
                      <p>{option[optional] ?? `--`}</p>
                    </div>
                  ) : (
                    option[label] ?? `--`
                  )}
                </div>
              );
            })
          : null}
        {paging && paging.totalPages > paging.currentDropdownPage && (
          <div
            className="option more"
            onClick={() => {
              dispatch(setDropdownCurrentPage(paging.currentDropdownPage + 1));
              onLoadMore();
            }}
          >
            Xem Thêm
          </div>
        )}
      </div>
    </div>
  );
});

export default DropDownList;
