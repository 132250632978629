import baseService from "src/helper/baseService";
import axios from "../../helper/axios";
import * as actionTypes from "./actionTypes";

const createScanMobileMethod = ({ payload, config }) => {
  return axios.post(`scan-mobile/createScanMobile`, payload, config);
};

const addItemScanMobileMethod = ({ payload, config }) => {
  return axios.post(`scan-mobile/addItemScan/${payload.id}`, payload.data);
};

const getItemScanMobileMethod = ({ payload, config }) => {
  return axios.get(`scan-mobile/getScanbyID/${payload}`);
};

const deleteItemScanMobileMethod = ({ payload, config }) => {
  return axios.delete(`scan-mobile/deleteItemScan/${payload}`);
};

const responseSuccess = (res) => {
  return res.data;
};

export const createScanMobile = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      createScanMobileMethod,
      { config, payload },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};

export const addItemScanMobile = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      addItemScanMobileMethod,
      { config, payload },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};

export const getItemScanMobile = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getItemScanMobileMethod,
      { config, payload },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};

export const deleteItemScanMobile = (payload, config) => {
    return async (dispatch) => {
      return baseService.serviceRequest(
        {},
        dispatch,
        deleteItemScanMobileMethod,
        { config, payload },
        actionTypes.NO_ACTION,
        (res) => responseSuccess(res)
      );
    };
  };