import baseService from "src/helper/baseService";
import axios from "../../helper/axios";
import * as actionTypes from "./actionTypes";

const getAllAssetCategoryMethod = ({ payload, config }) => {
  return axios.get(
    `asset-category/getAllAssetCategory?currentPage=${
      payload.currentPage
    }&pageSize=${
      payload.rowsPerPage
    }&sortField=created_at&sortOrder=asc&filters=${encodeURIComponent(
      payload.searchQuery
    )}`
  );
};
const getAssetCategoryMethod = ({ payload, config }) => {
  return axios.get(`asset-category/getAssetCategory/${payload}`);
};

const postAssetCategoryMethod = ({ payload, config }) => {
  return axios.post(`asset-category/postAssetCategory`, payload, config);
};

const putAssetCategoryMethod = ({ payload, config }) => {
  return axios.put(
    `asset-category/putAssetCategory/${payload.id}`,
    payload.values,
    config
  );
};

const deleteAssetCategoryMethod = ({ payload, config }) => {
  return axios.delete(`asset-category/deleteAssetCategory/${payload}`);
};

const responseSuccess = (res) => {
  return res.data;
};

export const getAllAssetCategory = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getAllAssetCategoryMethod,
      { config, payload },
      actionTypes.GET_ALL_ASSET_CATEGORY,
      (res) => responseSuccess(res)
    );
  };
};

export const getAssetCategory = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getAssetCategoryMethod,
      { config, payload },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};

export const postAssetCategory = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      postAssetCategoryMethod,
      { config, payload },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};

export const putAssetCategory = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      putAssetCategoryMethod,
      {
        config,
        payload: {
          id: payload.cateId,
          values: payload.data,
        },
      },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};

export const deleteAssetCategory = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      deleteAssetCategoryMethod,
      { config, payload },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};
