const isProdEnv = process.env.REACT_APP_ENV === "prod";
export default {
  isProdEnv,
  apiEndpoint: "https://gateway.dev.meu-solutions.com/assetmanagement/api/v1.0",
  ssoDomain: "http://27.74.255.96:8090/auth/loginqlts",
  hrDomain: "http://27.74.255.96:8090/portal/hr",
  hrModule: "http://27.74.255.96:8090/dashboard",
  qldaModule: "https://project.tlr.meu-solutions.com/",
  workflowModule: "http://27.74.255.96:8090/portal/workflow/workitem",
  channel: "qlts",
  siteURL: "http://asset.erp.meu-solutions.com/",
  workflowDomain: "http://27.74.255.96:8090/portal/workflow",
};
