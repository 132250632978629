import React, { Component, } from "react";
import { connect } from "react-redux";
import { setMessage, 
  clearFiles, 
  setUploadFile, 
  unsetUploadFile, 
  unsetFiles, 
} from "../../store/actions";
import Wrapped from "./Uploader";
import { withCookies } from "react-cookie";

const mapDispatchToProps = (dispatch) => {
  return {
    setMessage: (payload, config) => dispatch(setMessage(payload, config)),
    setUploadFile: (payload, config) => dispatch(setUploadFile(payload, config)),
    unsetUploadFile: (payload, config) => dispatch(unsetUploadFile(payload, config)),
    unsetFiles: (payload, config) => dispatch(unsetFiles(payload, config)),
    clearFiles: (payload, config) => dispatch(clearFiles(payload, config)),
  };
};

const mapStateToProps = (state) => {
  return {
    fileProgress: state.uploadFiles.fileProgress,
  };
};

class Enhanced extends Component {
	render() {
		return <Wrapped {...this.props}></Wrapped>;
	}
}

export default withCookies(connect(mapStateToProps, mapDispatchToProps)(Enhanced));
