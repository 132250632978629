import baseService from "src/helper/baseService";
import * as actionTypes from "./actionTypes";
import moment from "moment";
import sliceKeys from "../sliceKeys";
import { v4 as uuidv4 } from 'uuid';

export const setMessage = (payload = {}, config) => {
  return async (dispatch) => {
    return baseService.dispatchStoreAction(
      actionTypes.API_MESSAGE,
      {globalMessages: [{
        actionType: payload.actionType || actionTypes.NO_ACTION, 
        message: payload.message || "Tin nhắn thông báo",
        type: payload.type || sliceKeys.message_info,
        timeStamp: moment().valueOf(),
        // id: moment().valueOf(),
        id: uuidv4(),
      }]},
      dispatch,
    );
  };
};

export const deleteMessage = (payload = {}, config) => {
  return async (dispatch) => {
    return baseService.dispatchStoreAction(
      actionTypes.CLEAR_ONE_API_MESSAGE,
      {id: payload.id},
      dispatch,
    );
  };
};
