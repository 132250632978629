import React, { forwardRef, } from "react";
import "antd/dist/antd.css";
import { DatePicker } from 'antd';
// import "react-datepicker/dist/react-datepicker.css";
import "./input.scss"

const InputDate = React.memo(props => {
	const { 
		value,
		placeholder = "",
		format = "DD/MM/YYYY",
		showTime = false, 
		disabled = false, 
		disabledDate = false, 
		disabledDateTime = false, 
		onClick, 
		onFocus, 
		onBlur, 
		onChange, 
		className = "", 
		type = "date", 
	} = props;

	return (<DatePicker 
		{...props}
		getPopupContainer={(triggerNode) => triggerNode.parentNode}
		
		value={value} 
		format={format} 
		placeholder={placeholder}
		showTime={showTime}
		disabled={disabled}
		disabledDate={disabledDate}
		disabledTime={disabledDateTime}
		
		onClick={onClick}
		onFocus={onFocus}
		onBlur={onBlur}
		onChange={onChange}
		className={`input--antd-date ${className || ""}`} 
		/>)
})

const Input = forwardRef((props, ref) => {
	const { 
		name, 
		disabled = false, 
		type = "text", 
		value = "", 
		placeholder, 
		onBlur, 
		onClick, 
		onChange, 
		className = "", 
	} = props
	return (<input {...props} type={type} className={`input ${className}`} ref={ref} name={name} disabled={disabled} value={value} placeholder={placeholder} onClick={onClick} onChange={onChange} onBlur={onBlur}/>)
})

export default React.memo(Input)
export {InputDate}