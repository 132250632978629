import baseService from "src/helper/baseService";
import axios from "../../helper/axios";
import * as actionTypes from "./actionTypes";

const getAllAnotherRequestMethod = ({ payload, config }) => {
  return axios.get(
    `asset-request-history/getAllAnotherRequestByAssetID?currentPage=${
      payload?.currentPage
    }&pageSize=${
      payload?.rowsPerPage
    }&sortField=created_at&sortOrder=desc&requestType=${
      payload?.requestType
    }&approveStatus=${payload?.requestStatus}&searchKey=${encodeURIComponent(
      payload?.searchKey
    )}`
  );
};

const getAnotherRequestByIdMethod = ({ payload, config }) => {
  return axios.get(`asset-request/getAssetRequestByID/${payload?.id}`);
};

const getAllRequestTypeMethod = ({ payload, config }) => {
  return axios.get(`asset-request-type/getAllAssetRequestTypeForHistory`);
};

const getAllRequestStatusMethod = ({ payload, config }) => {
  return axios.get(`approve-status/getAllApproveStatus`);
};

const getAllReturnRequestMethod = ({ payload, config }) => {
  return axios.get(
    `asset-request-history/getAllBackRequest?currentPage=${
      payload?.currentPage
    }&pageSize=${
      payload?.rowsPerPage
    }&sortField=created_at&sortOrder=desc&searchKey=${encodeURIComponent(
      payload?.searchKey
    )}&filters=${
      payload?.requestStatus ? `asset_status_id==${payload?.requestStatus}` : ""
    }`
  );
};

const getAllMyReturnAssetRequestMethod = ({ payload, config }) => {
  return axios.get(
    `asset-request/getAllMyBackAssetRequest?currentPage=${
      payload?.currentPage
    }&pageSize=${
      payload?.rowsPerPage
    }&sortField=created_at&sortOrder=desc&searchKey=${encodeURIComponent(
      payload?.searchKey
    )}&filters=${
      payload?.requestStatus ? `asset_status_id==${payload?.requestStatus}` : ""
    }`
  );
};

const getAllReturnRequestStatusMethod = ({ payload, config }) => {
  return axios.get(`asset-status/getAllAssetStatus/PROCESSING`);
};

const getAllAssetRequestTypeMethod = () => {
  return axios.get(`asset-request-type/getAllAssetRequestType`);
};

const getAllRequestTypeConfigMethod = ({ payload, config }) => {
  return axios.get(
    `asset-request-type-config/getAllRequestTypeConfig?currentPage=${payload?.currentPage}&pageSize=${payload?.rowsPerPage}&filters=${payload?.options},type==${payload?.mode},(name|description)@=${payload?.searchQuery}`
  );
};

const putAssetPropertyTransferRequestMethod = ({ payload, config }) => {
  return axios.put(
    `asset-request/putAssetPropertyTransferRequest/${payload.id}`,
    payload.data
  );
};
const postAssetBackRequestMethod = ({ payload, config }) => {
  return axios.post(`asset-request/postAssetBackRequest`, payload.data, config);
};

const deleteAnotherAssetRequestMethod = ({ payload, config }) => {
  return axios.delete(`asset-request/deleteAnotherAssetRequest/${payload?.id}`);
};

const putAssetBackRequestMethod = ({ payload, config }) => {
  return axios.put(
    `asset-request/putAssetBackRequest/${payload?.id}`,
    payload?.data
  );
};

const refuseAssetBackRequestMethod = ({ payload, config }) => {
  return axios.put(
    `asset-request/refuseAssetBackRequest/${payload?.id}`,
    payload?.description
  );
};

const signAssetBackRequestMethod = ({ payload, config }) => {
  return axios.put(
    `asset-request/signMyAssetBackRequest/${payload?.id}`,
    payload?.warehouseId
  );
};

const exportPdfRequestBackMethod = ({ payload, config }) => {
  return axios.download(`asset-request/exportPdfBackRequest/${payload}`);
};

const responseSuccess = (res) => {
  return res.data;
};

export const getAllAssetRequestType = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getAllAssetRequestTypeMethod,

      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};

export const getAllRequestTypeConfig = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getAllRequestTypeConfigMethod,
      { config, payload },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};

export const getAllAnotherRequest = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getAllAnotherRequestMethod,
      { config, payload },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};

export const getAnotherRequestById = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getAnotherRequestByIdMethod,
      { config, payload },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};

export const getAllRequestType = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getAllRequestTypeMethod,
      { config, payload },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};

export const getAllRequestStatus = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getAllRequestStatusMethod,
      { config, payload },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};

export const getAllReturnRequest = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getAllReturnRequestMethod,
      { config, payload },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};

// api lấy danh sách yêu cầu trả tài sản mà người duyệt chính là user đang login
export const getAllMyReturnAssetRequest = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getAllMyReturnAssetRequestMethod,
      { config, payload },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};

export const getAllReturnRequestStatus = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getAllReturnRequestStatusMethod,
      { config, payload },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};

export const putAssetPropertyTransferRequest = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      putAssetPropertyTransferRequestMethod,
      { config, payload },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};

export const postAssetBackRequest = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      postAssetBackRequestMethod,
      { config, payload },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};

export const deleteAnotherAssetRequest = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      deleteAnotherAssetRequestMethod,
      { config, payload },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};

export const putAssetBackRequest = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      putAssetBackRequestMethod,
      { config, payload },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};

export const refuseAssetBackRequest = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      refuseAssetBackRequestMethod,
      { config, payload },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};

export const signAssetBackRequest = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      signAssetBackRequestMethod,
      { config, payload },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};

export const exportPdfBackRequest = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      exportPdfRequestBackMethod,
      { payload, config },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};
