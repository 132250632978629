import * as actionTypes from '../actions/actionTypes'

const initialState = {
  currentDropdownPage: 1,
  rowsDropdownPerPage: 5,
  totalDropdownPages: 1,
}

const reducer = (state = initialState, action)=>{
    switch (action.type) {
        case actionTypes.SET_DROPDOWN_CURRENT_PAGE:
          return {...state, currentDropdownPage: action.payload}
        case actionTypes.SET_ROWS_PER_DROPDOWN_PAGE:
          return {...state, rowsDropdownPerPage: action.payload}
        case actionTypes.SET_TOTAL_DROPDOWN_PAGES:
          return {...state, totalDropdownPage: action.payload}
        case actionTypes.RESET_DROPDOWN_PAGINATION_STATE:
          return initialState
        default:
          return state
      }
}
export default reducer;