import React, { Component, } from "react";
import { connect } from "react-redux";
import { 
	setTabs,
	setTabItem,
} from "../../store/actions";
import Wrapped from "./Mdal";
import {withCookies,} from "react-cookie"

const mapDispatchToProps = (dispatch) => {
  return {
  };
};

const mapStateToProps = (state) => {
  return {
  };
};

class Enhanced extends Component {
	render() {
		return <Wrapped {...this.props}></Wrapped>;
	}
}

export default withCookies(connect(mapStateToProps, mapDispatchToProps)(Enhanced));
