import { CSpinner } from '@coreui/react'
import { useSelector } from 'react-redux'
import sliceKeys from 'src/store/sliceKeys'

import logoImg from "../../assets/icons/logo.svg"
import './loader.scss'

const Loader = ({ withLogo }) => {
    const showLoader = useSelector(state => state.loader.showLoader)

    return (
        <>
            {showLoader && 
                <div className="loader-container">
                    {withLogo && 
                        <div className="logo-image">
                            <img src={logoImg} alt="meu logo" />
                        </div>
                    }
                    <CSpinner />
                </div>
            }
        </>       
    )
}

export default Loader
