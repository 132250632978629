import baseService from "src/helper/baseService";
import axios from "../../helper/axios";
import * as actionTypes from "./actionTypes";
import sliceKeys from "../sliceKeys";

const getAllAssetStatusMethod = ({ payload, config }) => {
  return axios.get(`asset-status/getAllAssetStatus/${payload.code}`);
};

const responseSuccess = (res) => {
  if (!res) return res;
  if (res.response) return { ...res.response.data };
  return res.data;
};

export const getAllAssetStatus = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getAllAssetStatusMethod,
      { payload, config },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};