import React, {useState, useReducer, useEffect, useRef} from 'react';
import {CModalTitle} from '@coreui/react';
import styled from 'styled-components';
import moment from 'moment';
import {useDispatch, useSelector} from 'react-redux';

import {
  Table,
  Col,
  Row,
  Thead,
  Tbody,
  Tfoot,
} from '../../../sharedComponents/Table/Table';
import {useUploadFile} from '../../../helper/hooks';
import {
  getAssetInventoryItemByID,
  putAssetInventoryItem,
  putAssetInventoryItemCriteria,
  uploadFileAssetInventoryItemCriteria,
  deleteFileAssetInventoryItemCriteria,
  getAllAssetStatus,
  getAllAssetInventoryCriteriaByAssetInventoryId,
  revertAssetInventoryItemCriteria,
  getAssetInInventory,
  getByID,
  getAssetInventoryTeam,
  setMessage,
} from '../../../store/actions';
import sliceKeys from '../../../store/sliceKeys';
import Input, {InputDate} from '../../../sharedComponents/Input/Input';
import Mdal from '../../../sharedComponents/Modal/Mdal';
import Uploader from '../../../sharedComponents/Uploader/index';
import DeleteFileDialog from '../../assets-page/asset-form/DeleteFileDialog';
import WrappedDropDownList from "../../../sharedComponents/DropDownList/WrappedDropDownList";

import editIcon from '../../../assets/icons/edit.svg';
import saveIcon from '../../../assets/icons/save.svg';
import closeIcon from '../../../assets/icons/close.svg';
import './reporting-page.scss';

const ID_MANAGER = "9d6b4cd4-dd5c-46f8-9087-c4bc7be9dd74"
// WARNING
// format code focus this should be grid-area: grid_reporting${(props) => props.index};
// There is NO ANY space between "grid_reporting" and "${(props) => props.index};"
const GridCell = styled.div`
  grid-area: grid_reporting${(props) => props.index};
`;
const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-template-rows: repeat(2, minmax(0, 1fr));
  grid-gap: 0 1.5rem;
  padding: 0;
  margin: 0.5rem 0;
  grid-template-areas:
    'grid_reporting1 grid_reporting2 grid_reporting3 grid_reporting4'
    'grid_reporting5 grid_reporting5 grid_reporting6 grid_reporting6';
`;
const GridCellBottom = styled.div`
  grid-area: grid_reporting_bottom${(props) => props.index};
`;
const GridContainerBottom = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-template-rows: repeat(1, minmax(0, 1fr));
  grid-gap: 0 1.5rem;
  padding: 0;
  grid-template-areas: 'grid_reporting_bottom1 grid_reporting_bottom2 grid_reporting_bottom3 grid_reporting_bottom4';
`;

const initialTouched = {
  code: false,
  name: false,
  time_reported: false,
  reporter: {
    fullname: false,
  },
  seri: false,
  in_charge: {
    fullname: false,
  },
  department: false,
  location: false,
  depreciation: false,
  residual_depreciation: false,
  residualCost: false,
  cost: false,
  description: false,
  report_conclusion: false,
};

const ReportInventoryDetail = (props) => {
  const {id, show = false, handleModal = false, fetchAllData, disabledEdit = false, assetInventory = false, } = props;
  const dispatch = useDispatch();
  const {user} = useSelector((state) => state.auth);
  const [item, setItem] = useState({});
  const [errors, setErrors] = useState({});
  const [finalStatuses, setFinalStatuses] = useState([]);
  const [criteria, setCriteria] = useState([]);
  const [submitCriteria, setSubmitCriteria] = useState([]);
  const [edit, setEdit] = useState(false);
  const [assetInventoryId, setAssetInventoryId] = useState(false);
  const [periodLoaded, setPeriodLoaded] = useState(false);
  const [detailLoaded, setDetailLoaded] = useState(false);
  const [listAssetInventory, setListAssetInventory] = useState([]);

  const fetchDetail = async (id) => {
    const response = await dispatch(getAssetInventoryItemByID({id}));
    if (!response || response.status !== 'success' || !response.responseData) {
      return;
    }
    setItem((_) => ({
      code: response.responseData.asset.code,
      name: response.responseData.asset.asset_group.name,
      time_reported: response.responseData.inventory_at || null,
      reporter:
      (response.responseData.status_id === null && response.responseData.updated_by_user) || null, 
        // response.responseData.asset_inventory_user_items[0]?.asset_inventory_user
          // ?.user,
      seri: response.responseData.asset.seri,
      in_charge: response.responseData.asset.assistant_user_user,
      department: response.responseData.asset_inventory_user_items[0]?.asset_inventory_user?.inventory_department?.id !== null ?
        response.responseData.asset_inventory_user_items[0]?.asset_inventory_user?.inventory_department?.name
        : response.responseData.asset_inventory_user_items[0]?.asset_inventory_user?.asset_warehouse?.id !== null ?
          response.responseData.asset_inventory_user_items[0]?.asset_inventory_user?.asset_warehouse?.name : '',
      departmentId: response.responseData.asset_inventory_user_items[0]?.asset_inventory_user?.inventory_department?.id ||
        response.responseData.asset_inventory_user_items[0]?.asset_inventory_user?.asset_warehouse?.id ||
        '',
      location: response.responseData.asset.location,
      // depreciation: response.responseData.asset.depreciationPercentage,
      depreciation: response.responseData.asset.depreciation_per_year,
      residual_depreciation: response.responseData.asset.residualCostPercentage,
      residualCost: response.responseData.asset.depreciation,
      cost: response.responseData.asset.residualCost,
      description: response.responseData.note,
      report_conclusion: response.responseData.final_status,
      // report_conclusion: response.responseData.status.id 
      // ? response.responseData.status : response.responseData.final_status, 
      asset_inventory_item_criteria:
        response.responseData.asset_inventory_item_criteria,
      asset_inventory_id: response.responseData.asset_inventory_id,
      unique_key: response.responseData.unique_key,
      start_date: response.responseData.asset_inventory.start_date,
      end_date: response.responseData.asset_inventory.end_date,
      asset_inventory_users:
        response.responseData.asset_inventory.asset_inventory_users,
      updated_by_user: response.responseData.updated_by_user,
      updated_at: response.responseData.updated_at,
      status: response.responseData.status,
    }));
  };
  const fetchAssetInfoByAssetId = async (id) => {
    const response = await dispatch(getByID({ id }));
    if (!response || response.status !== "success" || !response.responseData)
      return;
    setItem((prev) => ({
      ...prev,
      department:
        response.responseData.status_id === null &&
        response.responseData.asset_warehouse_id !== null
          ? response.responseData.asset_warehouse_id
          : response.responseData.status_id !== null &&
            response.responseData.assistant_department_id !== null
          ? response.responseData.assistant_department_id
          : null,
      // not includes in responseData.asset
      code: response.responseData.code, 
      name: response.responseData.asset_group.name, 
      depreciation: response.responseData.depreciation_per_year,
      // residual_depreciation: response.responseData.asset.depreciation_per_year 
      // ? 100 - +response.responseData.asset.depreciation_per_year : null,
      residual_depreciation: response.responseData.residualCostPercentage,
      residualCost: response.responseData.depreciation,
      cost: response.responseData.residualCost,
      
    }));
  };
  const fetchReporters = async (id, item) => {
    const response = await dispatch(
      getAssetInventoryTeam({
        assetInventoryId: item.asset_inventory_id,
        departmentOrWarehouseId: id,
      })
    );
    if (!response || response.status !== "success") {
      return setItem((prev) => ({ ...prev, asset_inventory_users: [] }));
    }
    setItem((prev) => ({ ...prev, asset_inventory_users: response.responseData.map(item => ({ref_user: {
      id: item.id,
      fullname: item.name,
      position: item.position,
    }})) }));
  }
  const fetchStatuses = async () => {
    const response = await dispatch(
      getAllAssetStatus({code: `INVENTORY_ITEM_FINAL`})
    );
    if (!response || response.status !== 'success') {
      return setFinalStatuses([]);
    }
    setFinalStatuses(response.responseData);
  };
  const fetchCriteria = async (id) => {
    const response = await dispatch(
      getAllAssetInventoryCriteriaByAssetInventoryId({id})
    );
    if (!response || response.status !== 'success') {
      return setCriteria([]);
    }
    setCriteria(response.responseData.rows);
  };
  const handleSubmit = async (item, listSubmitCriteria, temp = false) => {
    if (!temp && !item.report_conclusion.id) {
      return await dispatch(
        setMessage({
          message: `Vui lòng chọn kết quả cuối cùng`,
          type: sliceKeys.message_danger,
        })
      );
    }
    let payload = {
      id,
      inventory_at: item.time_reported || moment().format("YYYY-MM-DD HH:mm:ss"),
      final_status_id: item.report_conclusion.id,
      status_id: temp? "274aae13-4675-4e97-838b-d26847270a75" : null,
      note: item.description || null,
      unique_key: item.unique_key,
      asset_inventory_item_critterias: listSubmitCriteria,
    };
    const response = await dispatch(putAssetInventoryItem(payload));
    if (!response || response.status !== 'success' || !response.responseData) {
      // return await dispatch(
      //   setMessage({
      //     message: temp
      //       ? `Lưu tạm biên bản thất bại`
      //       : `Duyệt biên bản thất bại`,
      //     type: sliceKeys.message_danger,
      //   })
      // );
      if (assetInventory) {
        return await dispatch(
          setMessage({
            message: temp
              ? `Lưu tạm kiểm kê thất bại`
              : `Duyệt kiểm kê thất bại`,
            type: sliceKeys.message_danger,
          })
        );
      }
      return await dispatch(
        setMessage({
          message: temp
            ? `Lưu tạm biên bản thất bại`
            : `Duyệt biên bản thất bại`,
          type: sliceKeys.message_danger,
        })
      );
    }
    // await dispatch(
    //   setMessage({
    //     message: temp
    //       ? `Lưu tạm biên bản thành công`
    //       : `Duyệt biên bản thành công`,
    //     type: temp ? sliceKeys.message_info : sliceKeys.message_success,
    //   })
    // );
    // fetchAllData();
    // handleModal(false);
    
    if (assetInventory) {
      await dispatch(
        setMessage({
          message: temp
            ? `Lưu tạm kiểm kê thành công`
            : `Duyệt kiểm kê thành công`,
          type: temp ? sliceKeys.message_info : sliceKeys.message_success,
        })
      );
      handleModal(false);
      return
    }
    else {
      await dispatch(
        setMessage({
          message: temp
          ? `Lưu tạm biên bản thành công`
          : `Duyệt biên bản thành công`,
          type: temp ? sliceKeys.message_info : sliceKeys.message_success,
        })
      );
      props.fetchAllData();
      handleModal(false);
    }
  };
  const revertCriteria = async (unique_key) => {
    const response = await dispatch(
      revertAssetInventoryItemCriteria({unique_key})
    );
    if (!response || response.status !== 'success' || !response.responseData) {
      return;
    }
    setItem({})
  };
  const getBodyModal = () => {
    return (<>
      {assetInventory && (
          <>
            <div className="reporting-page__modal reporting-page__label__text">
              Đợt kiểm kê
            </div>
            <WrappedDropDownList
              placeholder={"Chọn đợt kiểm kê"}
              value={assetInventoryId}
              actionMethod={{
                selected: (value) => {
                  setAssetInventoryId(value?.asset_inventory_id || false);
                  setItem((prev) => ({
                    ...prev,
                    start_date:
                      value?.start_date || moment().format("YYYY-MM-DD"),
                    end_date: value?.end_date || moment().format("YYYY-MM-DD"),
                    asset_inventory_info_id: value?.id
                  }));
                },
                fetchAPI: getAssetInInventory,
                fetchOptions: fetchTest,
              }}
              text={{
                key: "asset_inventory_id",
                label: "name",
              }}
              listItems={listAssetInventory.map((item) => ({
                ...item,
                name: item.name || "--",
              }))}
              isHandlingOutside
            />
          </>
        )}
      <div className='flex flex__column'>
        <GridContainer>
          <GridCell index={1}>
            {/* <div className="reporting-page__modal reporting-page__label__text  reporting-page__label__text--disabled">Thời gian kiểm kê</div> */}
            <div className='reporting-page__modal reporting-page__label__text'>
              Thời gian kiểm kê
            </div>
            <InputDate
              disabled
              format={'HH:mm DD/MM/YYYY'}
              // placeholder={edit? "--": 'Chọn thời gian kiểm kê'}
              placeholder={"--"}
              showTime={{
                defaultValue:
                  (item.time_reported &&
                    moment(item.time_reported, 'YYYY-MM-DD HH:mm:ss')) ||
                  '',
              }}
              value={
                (item.time_reported &&
                  moment(item.time_reported, 'YYYY-MM-DD HH:mm:ss')) ||
                ''
              }
              onChange={(value, valueString) => {
                setItem((prev) => ({
                  ...prev,
                  time_reported:
                    (value && moment(value).format('YYYY-MM-DD HH:mm:ss')) ||
                    '',
                }));
              }}
              className={` ${
                errors.time_reported ? 'input--antd-date--outline-red' : ''
              } reporting-page__text`}
            />
          </GridCell>
          <GridCell index={2}>
            {/* <div className="reporting-page__modal reporting-page__label__text reporting-page__label__text--disabled">Người kiểm kê</div> */}
            <div className='reporting-page__modal reporting-page__label__text'>
              Người kiểm kê
            </div>
            <Input
              disabled
              value={(item.status?.id === null && item.report_conclusion?.id && item.reporter?.fullname) || '--'}
              // onChange={(e) =>
              //   setItem((prev) => ({
              //     ...prev,
              //     reporter: {...prev.reporter, fullname: e.target.value},
              //   }))
              // }
              placeholder={'Nhập tên Người kiểm kê'}
              // className={`${errors.reporter ? "input--outline-red" : ""} reporting-page__text reporting-page__label__text--disabled`}
              className={`${
                errors.reporter ? 'input--outline-red' : ''
              } reporting-page__text`}
            />
          </GridCell>
          <GridCell index={3}>
            {/* <div className="reporting-page__modal reporting-page__label__text reporting-page__label__text--disabled">Số seri</div> */}
            <div className='reporting-page__modal reporting-page__label__text'>
              Số seri
            </div>
            <Input
              disabled
              value={item.seri || '--'}
              onChange={(e) =>
                setItem((prev) => ({...prev, seri: e.target.value}))
              }
              placeholder={'Nhập số seri'}
              // className={`${errors.seri ? "input--outline-red" : ""} reporting-page__text reporting-page__label__text--disabled`}
              className={`${
                errors.seri ? 'input--outline-red' : ''
              } reporting-page__text`}
            />
          </GridCell>
          <GridCell index={4}>
            {/* <div className="reporting-page__modal reporting-page__label__text reporting-page__label__text--disabled">Người phụ trách</div> */}
            <div className='reporting-page__modal reporting-page__label__text'>
              Người phụ trách
            </div>
            <Input
              disabled
              value={item.in_charge?.fullname || '--'}
              onChange={(e) =>
                setItem((prev) => ({
                  ...prev,
                  in_charge: {...prev.in_charge, fullname: e.target.value},
                }))
              }
              placeholder={'Nhập tên người phụ trách'}
              // className={`${errors.in_charge ? "input--outline-red" : ""} reporting-page__text reporting-page__label__text--disabled`}
              className={`${
                errors.in_charge ? 'input--outline-red' : ''
              } reporting-page__text`}
            />
          </GridCell>
          <GridCell index={5}>
            {/* <div className="reporting-page__modal reporting-page__label__text reporting-page__label__text--disabled">Phòng ban</div> */}
            <div className='reporting-page__modal reporting-page__label__text'>
              Phòng ban / kho
            </div>
            <Input
              disabled
              value={item.department || '--'}
              onChange={(e) =>
                setItem((prev) => ({...prev, department: e.target.value}))
              }
              placeholder={'Nhập tên phòng ban'}
              // className={`${errors.department ? "input--outline-red" : ""} reporting-page__text reporting-page__label__text--disabled`}
              className={`${
                errors.department ? 'input--outline-red' : ''
              } reporting-page__text`}
            />
          </GridCell>
          <GridCell index={6}>
            {/* <div className="reporting-page__modal reporting-page__label__text reporting-page__label__text--disabled">Vị trí đặt tài sản</div> */}
            <div className='reporting-page__modal reporting-page__label__text'>
              Vị trí đặt tài sản
            </div>
            <Input
              disabled
              value={item.location || ''}
              onChange={(e) =>
                setItem((prev) => ({...prev, location: e.target.value}))
              }
              placeholder={'--'}
              // className={`${errors.location ? "input--outline-red" : ""} reporting-page__text reporting-page__label__text--disabled`}
              className={`${
                errors.location ? 'input--outline-red' : ''
              } reporting-page__text`}
            />
          </GridCell>
        </GridContainer>
        {item.updated_by_user && item.updated_at && (
          <div className='flex flex-gap--05 align-center reporting-page__field'>
            <div className='reporting-page__field__text'>
              Chỉnh sửa gần nhất bởi
            </div>
            <div className='flex flex flex-gap--05 align-center'>
              <div className='flex flex-gap--05 justify-end align-center'>
                <img src={`${item.updated_by_user?.avatar}`} alt='user' />
                <div className='reporting-page__field__text reporting-page__field__text--emphasis'>
                  {item.updated_by_user?.fullname}
                </div>
              </div>
              <div className='reporting-page__field__text'>-</div>
              <div className='flex justify-end reporting-page__field__text'>{`${moment.utc(
                item.updated_at,
                'YYYY-MM-DD HH:mm:ss'
              ).local().format('DD/MM/YYYY')} • ${moment.utc(
                item.updated_at,
                'YYYY-MM-DD HH:mm:ss'
              ).local().format('HH:mm')}`}</div>
            </div>
          </div>
        )}
        <div style={{overflowX: 'auto'}}>
        <Table className='reporting-page__table'>
          {item.asset_inventory_item_criteria?.length ? (
            item.asset_inventory_item_criteria.map((section, index) => {
              return (
                <Section
                  key={index}
                  section={{
                    ...section,
                    asset_inventory_id: item.asset_inventory_id,
                  }}
                  listItems={criteria}
                  handleMethod={{
                    changeCriteria: (item) =>
                      setSubmitCriteria((prev) =>
                        !prev.find((other) => other.id === item.id)?.id
                          ? [...prev, item]
                          : prev.map((other) =>
                              other.id === item.id ? item : other
                            )
                      ),
                  }}
                  unique_key={item.unique_key}
                  edit={edit}
                />
              );
            })
          ) : (
            <Tfoot>
              <tr className='custom-table__rows custom-table__rows--blank'>
                <td colSpan={20}>
                  <div className='list-empty-message'>
                    Không có dữ liệu đánh giá
                  </div>
                </td>
              </tr>
            </Tfoot>
          )}
        </Table>
        </div>
        <GridContainerBottom>
          <GridCellBottom index={1}>
            {/* <div className="reporting-page__modal reporting-page__label__text reporting-page__label__text--disabled">% Khấu hao / năm (%)</div> */}
            <div className='reporting-page__modal reporting-page__label__text'>
              % Khấu hao / năm
            </div>
            <Input
              disabled
              value={
                (item.depreciation && +item.depreciation.toFixed(2)) || '--'
              }
              onChange={(e) =>
                setItem((prev) => ({...prev, depreciation: e.target.value}))
              }
              placeholder={'Nhập tỷ lệ khấu hao'}
              // className={`${errors.depreciation ? "input--outline-red" : ""} reporting-page__text reporting-page__label__text--disabled`}
              className={`${
                errors.depreciation ? 'input--outline-red' : ''
              } reporting-page__text`}
            />
          </GridCellBottom>
          <GridCellBottom index={2}>
            {/* <div className="reporting-page__modal reporting-page__label__text reporting-page__label__text--disabled">Tỷ lệ khấu hao còn lại (%)</div> */}
            <div className='reporting-page__modal reporting-page__label__text'>
              Tỷ lệ khấu hao còn lại (%)
            </div>
            <Input
              disabled
              value={
                (item.residual_depreciation &&
                  +item.residual_depreciation.toFixed(2)) ||
                '--'
              }
              onChange={(e) =>
                setItem((prev) => ({
                  ...prev,
                  residual_depreciation: e.target.value,
                }))
              }
              placeholder={'Nhập tỷ lệ khấu hao còn lại (%)'}
              // className={`${errors.residual_depreciation ? "input--outline-red" : ""} reporting-page__text reporting-page__label__text--disabled`}
              className={`${
                errors.residual_depreciation ? 'input--outline-red' : ''
              } reporting-page__text`}
            />
          </GridCellBottom>
          <GridCellBottom index={3}>
            {/* <div className="reporting-page__modal reporting-page__label__text reporting-page__label__text--disabled">Giá trị đã khấu hao (VNĐ) </div> */}
            <div className='reporting-page__modal reporting-page__label__text'>
              Giá trị đã khấu hao (VNĐ){' '}
            </div>
            <Input
              disabled
              value={
                (item.residualCost && +item.residualCost.toFixed(2)) || '--'
              }
              onChange={(e) =>
                setItem((prev) => ({...prev, residualCost: e.target.value}))
              }
              placeholder={'Nhập giá trị còn lại'}
              // className={`${errors.residualCost ? "input--outline-red" : ""} reporting-page__text reporting-page__label__text--disabled`}
              className={`${
                errors.residualCost ? 'input--outline-red' : ''
              } reporting-page__text`}
            />
          </GridCellBottom>
          <GridCellBottom index={4}>
            {/* <div className="reporting-page__modal reporting-page__label__text reporting-page__label__text--disabled">Giá trị còn lại (VNĐ)</div> */}
            <div className='reporting-page__modal reporting-page__label__text'>
              Giá trị còn lại (VNĐ)
            </div>
            <Input
              disabled
              value={(item.cost && +item.cost.toFixed(2)) || '--'}
              onChange={(e) =>
                setItem((prev) => ({...prev, cost: e.target.value}))
              }
              placeholder={'Nhập giá mới tương đương'}
              // className={`${errors.cost ? "input--outline-red" : ""} reporting-page__text reporting-page__label__text--disabled`}
              className={`${
                errors.cost ? 'input--outline-red' : ''
              } reporting-page__text`}
            />
          </GridCellBottom>
        </GridContainerBottom>
        <div>
          <div className='reporting-page__modal reporting-page__label__text'>
            Đánh giá tổng quan
          </div>

          <textarea
            name='description'
            disabled={!edit}
            value={item.description || ''}
            onChange={(e) =>
              setItem((prev) => ({...prev, description: e.target.value}))
            }
            placeholder='Nhập mô tả'
          />
        </div>
        <div>
          <div className='reporting-page__modal reporting-page__label__text'>
            Kết quả cuối cùng
          </div>
          <div className='flex flex__row'>
            {finalStatuses.map((status, index) => (
              <button
                disabled={!edit}
                key={index}
                onClick={() => {
                  
                  if (!edit) return;
                  if (
                    item.report_conclusion?.id !== status.id
                  ) {
                    setItem((prev) => ({
                      ...prev,
                      report_conclusion: status,
                    }));
                  } 
                  else if (
                    item.report_conclusion?.id === status.id
                  ) {
                    setItem((prev) => ({
                      ...prev,
                      report_conclusion: {
                        code: "INVENTORY_ITEM_CHECKED", 
                        color: "#00a560", 
                        description: "Đang kiểm kê", 
                        group_code: "INVENTORY_ITEM", 
                        // id: "2db0a08e-fa06-4b71-b37a-047e9e8ed70b", 
                        id: "274aae13-4675-4e97-838b-d26847270a75", 
                        name: "Đang kiểm kê", 
                      },
                    }));
                  }
                  // edit &&
                  // item.report_conclusion?.id !== status.id &&
                  // setItem((prev) => ({...prev, report_conclusion: status}))
                }}
                className={'button button--border-less flex-grow'}
              >
                <input
                  disabled={!edit}
                  type='radio'
                  name={'reporting_conclusion'}
                  value={status.id}
                  checked={item.report_conclusion?.id === status.id}
                  onChange={() => {}}
                />
                {status.name}
              </button>
            ))}
          </div>
        </div>
      </div>
    </>);
  };
  // Scan mobile not for desktop
  const fetchListAssetInventory = async (id) => {
    const response = await dispatch(getAssetInInventory({ id }));
    if (!response || response.status !== "success" || !response.responseData) {
      return;
    }
    const sortedListInventoryPeriods = response.responseData.map((item) => ({
      id: item.id,
      asset_inventory_id: item.asset_inventory?.id,
      name: item.asset_inventory?.name,
      start_date: item.asset_inventory.start_date,
      end_date: item.asset_inventory.end_date,
    }))
    .sort((a, b) => {
      if (moment(a.start_date, "YYYY-MM-DD HH:mm:ss").isSame(b.start_date)) return 0
      return moment(a.start_date, "YYYY-MM-DD HH:mm:ss").isAfter(b.start_date)? 1: -1;
    })
    setListAssetInventory(sortedListInventoryPeriods);
    if (!sortedListInventoryPeriods.length) return
    setAssetInventoryId(sortedListInventoryPeriods[0]?.asset_inventory_id || false);
    setItem((prev) => ({
      ...prev,
      start_date:
        sortedListInventoryPeriods[0]?.start_date || moment().format("YYYY-MM-DD"),
      end_date: sortedListInventoryPeriods[0]?.end_date || moment().format("YYYY-MM-DD"),
      asset_inventory_info_id: sortedListInventoryPeriods[0]?.id
    }));
  };
  const fetchTest = async ({
    currentPage = 1,
    pageSize = 5,
    search = "",
    more = false,
    setMore,
    setTotalPages,
    setSelected,
    fetchSelected = false,
  }) => {
    if (!item.asset_id) return
    const response = await dispatch(getAssetInInventory({ id: item.asset_id }));
    if (!response || response.status !== "success" || !response.responseData) {
      setTotalPages && setTotalPages(0);
      setListAssetInventory([]);
      return;
    }
    setTotalPages && setTotalPages(response.responseData.totalPages);
    if (more) {
      setListAssetInventory((prev) =>
        prev.concat(
          response.responseData.map((item) => ({
            id: item.id,
            asset_inventory_id: item.asset_inventory?.id,
            name: item.asset_inventory?.name,
            start_date: item.asset_inventory?.start_date,
            end_date: item.asset_inventory?.end_date,
          }))
        )
      );
      return setMore && setMore(false);
    }
    setListAssetInventory(
      response.responseData.map((item) => ({
        id: item.id,
        asset_inventory_id: item.asset_inventory?.id,
        name: item.asset_inventory?.name,
      }))
    );
  };

  useEffect(() => {
    return () => {
      if (show) {
        setItem({});
        setErrors({});
        setFinalStatuses([]);
        setPeriodLoaded(false);
        setSubmitCriteria([]);
        setEdit(false);
        setAssetInventoryId(false);
        setPeriodLoaded(false);
        setDetailLoaded(false);
        setListAssetInventory([]);
        setListAssetInventory([]);
        if (edit && item.unique_key) {
          revertCriteria(item.unique_key);
        }
      }
    };
  }, [show,]);
  useEffect(() => {
    if (show && id) {
      if(!assetInventory) {
        fetchDetail(id);
        if (!detailLoaded) {
          setDetailLoaded(true)
        }
      } else {
        fetchListAssetInventory(id);
        fetchAssetInfoByAssetId(id);
        if (!detailLoaded) {
          setDetailLoaded(true)
        }
      }
      fetchStatuses();
    }
  }, [show, id]);
  useEffect(() => {    
    if (show && item.asset_inventory_id) {
      fetchCriteria(item.asset_inventory_id, id);
      setAssetInventoryId(item.asset_inventory_id);
    } else if (show && !item.asset_inventory_id) {
      setCriteria([]);
      setItem((prev) => ({
        ...prev,
        asset_inventory_item_criteria: [],
      }));
    }
  }, [item.asset_inventory_id]);
  useEffect(() => {
    if (show && assetInventory && assetInventoryId) {
      setItem((prev) => ({ ...prev, asset_inventory_id: assetInventoryId }));
    }
    return () => {
      if (assetInventory && !assetInventoryId && !periodLoaded) {
        setPeriodLoaded(true);
      }
    };
  }, [assetInventoryId]);
  useEffect(() => {
	if (show && assetInventory && item.departmentId && item.asset_inventory_id) {
		fetchReporters(item.departmentId, item);
	}
  }, [item.departmentId , item.asset_inventory_id]);
  useEffect(() => {
    if (!show) return
    const unsatisfiedStatus = item.status?.id === null
    const satisfiedUser = item.asset_inventory_users?.find(
      (item) => item?.ref_user?.id === user.id
    );
    const isManager = satisfiedUser?.asset_inventory_position?.id === ID_MANAGER // id Trưởng phòng - Manager
    const hasPermission = isManager ? true : satisfiedUser?.inventory_department_id?.includes(item?.departmentId) || satisfiedUser?.asset_warehouse_id?.includes(item?.departmentId)
    setEdit(!disabledEdit && !unsatisfiedStatus && hasPermission ? true : false);
  }, [user, finalStatuses, item.asset_inventory_users, item.status])
  useEffect(() => {
    if (show && detailLoaded && assetInventory && item.asset_inventory_info_id) {
      const updateDataByAssetInventoryInfo = async () => {
        const response = await dispatch(getAssetInventoryItemByID({ id: item.asset_inventory_info_id }));
        if (!response || response.status !== "success" || !response.responseData) {
          return;
        }
        setItem((prev) => ({...prev, 
          code: response.responseData.asset.code,
          name: response.responseData.asset.asset_group.name,
          time_reported: response.responseData.inventory_at || null,
          reporter: (response.responseData.status_id === null && response.responseData.updated_by_user) || null, 
          seri: response.responseData.asset.seri,
          in_charge: response.responseData.asset.assistant_user_user,
          // department:
          //   response.responseData.asset_inventory_user_items?.asset_inventory_user
          //     ?.department_id ||
          //   response.responseData.asset_inventory_user_items?.asset_inventory_user
          //     ?.asset_warehouse ||
          //   "",
          // departmentId:
          //   response.responseData.asset_inventory_user_items?.asset_inventory_user
          //     ?.department_id ||
          //   response.responseData.asset_inventory_user_items?.asset_inventory_user
          //     ?.asset_warehouse ||
          //   "",
            
          department: response.responseData.asset_inventory_user_items[0]?.asset_inventory_user?.inventory_department?.id !== null ?
            response.responseData.asset_inventory_user_items[0]?.asset_inventory_user?.inventory_department?.name
            : response.responseData.asset_inventory_user_items[0]?.asset_inventory_user?.asset_warehouse?.id !== null ?
              response.responseData.asset_inventory_user_items[0]?.asset_inventory_user?.asset_warehouse?.name : '',
          departmentId: response.responseData.asset_inventory_user_items[0]?.asset_inventory_user?.inventory_department?.id ||
            response.responseData.asset_inventory_user_items[0]?.asset_inventory_user?.asset_warehouse?.id ||
            '',
          location: response.responseData.asset.location,
          depreciation: response.responseData.asset.depreciation_per_year,
          residual_depreciation: response.responseData.asset.residualCostPercentage,
          residualCost: response.responseData.asset.depreciation,
          cost: response.responseData.asset.residualCost,
          description: response.responseData.note,
          report_conclusion: response.responseData.final_status,
          // report_conclusion: response.responseData.status.id
          // ? response.responseData.status : response.responseData.final_status,
          asset_inventory_item_criteria:
            response.responseData.asset_inventory_item_criteria,
          asset_inventory_id: response.responseData.asset_inventory_id,
          unique_key: response.responseData.unique_key,
          start_date: response.responseData.asset_inventory.start_date,
          end_date: response.responseData.asset_inventory.end_date,
          // asset_inventory_users:
          //   response.responseData.asset_inventory.asset_inventory_users,
          updated_by_user: response.responseData.updated_by_user,
          updated_at: response.responseData.updated_at,
          status: response.responseData.status,
          asset_id: response.responseData.asset.id,
        }));
      }
      updateDataByAssetInventoryInfo()
    }
  }, [item.asset_inventory_info_id])
  useEffect(() => {
    if (show && edit && item.status?.id != null) {
      setItem(prev => ({...prev,
        time_reported: moment().format("YYYY-MM-DD HH:mm:ss"),
      }))
    }
  }, [show, edit])

  return (
    <>
      {show && (
        <Mdal
          className='reporting-page__modal'
          size='xl'
          show={show}
          handleModal={async (value) => {
            handleModal(value);
          }}
          components={{
            header: () => (
              <CModalTitle>{`[${item.code || '--'}]${
                item.name || '--'
              }`}</CModalTitle>
            ),
            body: getBodyModal,
            footer: () => (
              <>
                {edit ? (
                  <>
                    <button
                      className='button button--secondary'
                      onClick={() => {
                        handleModal(false);
                      }}
                    >
                      Hủy
                    </button>
                    <button
                      className='button button--success'
                      onClick={() => {
                        handleSubmit(item, submitCriteria);
                      }}
                    >
                      Đã kiểm kê
                    </button>
                    <button
                      className='button button--primary'
                      onClick={() => {
                        handleSubmit(item, submitCriteria, true);
                      }}
                    >
                      Lưu tạm
                    </button>
                  </>
                ) : 
                  <button
                    className='button button--primary'
                    onClick={() => {
                      handleModal(false);
                    }}
                  >
                    Đóng
                  </button>
                }
              </>
            ),
          }}
        />
      )}
    </>
  );
};

const Section = (props) => {
  const {section = {}, listItems = []} = props;
  const dispatch = useDispatch();
  const ref = useRef(true);
  const [propsLoaded, setPropsLoaded] = useState(false);
  const [itemLoaded, setItemLoaded] = useState(false);
  const [item, setItem] = useState({});
  const [confirm, setConfirm] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [criteria, setCriteria] = useState([]);
  const initState = {
    listFiles: [],
  };

  const addState = (state, payload) => {
    if (!payload) return state;
    const addState = {
      ...state,
      listFiles: [...state.listFiles, ...payload],
    };
    return addState;
  };
  const removeState = (state, payload) => {
    if (!payload) return state;
    const removeState = {
      ...state,
      listFiles: [...state.listFiles.filter((file) => file.id !== payload.id)],
    };
    return removeState;
  };
  const reducer = (state = initState, action) => {
    switch (action.type) {
      case 'reset':
        return initState;
      case 'add':
        return addState(state, action.payload);
      case 'remove':
        return removeState(state, action.payload);
      default:
        return state;
    }
  };
  const [state, dispatchState] = useReducer(reducer, initState);

  const handleStateUploadFile = (parameters) => {
    const {empty = false, add = false, filter = false} = parameters;

    if (empty !== false) {
      dispatchState({type: 'reset'});
    }
    if (add !== false) {
      if (!add.length) return;
      dispatchState({
        type: 'add',
        payload: add.map((file) => ({
          ...file,
          name: file.image.substring(file.image.lastIndexOf('/') + 1),
        })),
      });
    }
    if (filter !== false) {
      dispatchState({type: 'remove', payload: {id: filter}});
    }
  };

  useEffect(() => {
    ref.current = true;
    return () => {
      ref.current = false;
    };
  }, []);
  useEffect(() => {
    setCriteria(listItems);
  }, [listItems]);
  useEffect(() => {
    if (ref.current && section && propsLoaded && itemLoaded) {
      typeof props.handleMethod.changeCriteria === 'function' &&
        props.handleMethod.changeCriteria({
          id: item.id,
          description: item.description,
          asset_inventory_criteria_option_id:
            item.asset_inventory_criteria_option_id,
        });
    } else if (ref.current && section && propsLoaded && !itemLoaded)
      setItemLoaded(true);
  }, [item]);
  useEffect(() => {
    if (ref.current && section && !propsLoaded) {
      setPropsLoaded(true);
      setItem(section);
      dispatchState({type: 'reset'});
      dispatchState({
        type: 'add',
        payload: section?.asset_inventory_criteria_attachments
          ? section?.asset_inventory_criteria_attachments.map((file) => ({
              ...file,
              name: file.image.substring(file.image.lastIndexOf('/') + 1),
            }))
          : [],
      });
    }
  }, [section]);
  const fileMethods = useUploadFile({
    id: section.id,
    add: (payload, config) => {
      return uploadFileAssetInventoryItemCriteria(
        {...payload, unique_key: props.unique_key},
        config
      );
    },
    delete: deleteFileAssetInventoryItemCriteria,
    handleState: handleStateUploadFile,
    confirmDelete: () => {
      return setConfirmModal(true);
    },
    confirmed: confirm,
    setConfirmed: setConfirm,
  });
  return (
    <>
      <Thead>
        <Row th>
          <Col th></Col>
          {criteria?.length
            ? 
              [...criteria
                .find(item => item.id === section.asset_inventory_criteria_id)?.asset_inventory_criteria_options
                || [], 
                ...Array.from(Array(criteria.find(item => item.id === section.asset_inventory_criteria_id)?.asset_inventory_criteria_options.length 
                ? 3-criteria.find(item => item.id === section.asset_inventory_criteria_id)?.asset_inventory_criteria_options.length : 3).keys()), ]
              .map((status, index) => (
                <Col
                  key={index}
                  th
                  childClass='table__thead__text table__thead__text--text-center'
                >
                  {status.name || '--'}
                </Col>
              ))
            : null}
          <Col th>Mô tả chi tiết</Col>
        </Row>
      </Thead>
      <Tbody>
        <Row plain borderless>
          <Col colSpan={1}>
            {
              criteria.find(
                (item) => item.id === section.asset_inventory_criteria_id
              )?.name
            }
          </Col>
          {criteria?.length
            ? 
            [...criteria
              .find(item => item.id === section.asset_inventory_criteria_id)?.asset_inventory_criteria_options
              || [], 
              ...Array.from(Array(criteria.find(item => item.id === section.asset_inventory_criteria_id)?.asset_inventory_criteria_options.length 
              ? 3-criteria.find(item => item.id === section.asset_inventory_criteria_id)?.asset_inventory_criteria_options.length : 3).keys()), ]
              .map((criteria, index) => {
                return(
                <td className='table__cols  ' key={index}>
                  {
                    // criteria.id &&
                    <div className='flex flex-center'>
                      <button
                        // disabled={!criteria.id || !props.edit}
                        disabled={!criteria.id || (!props.edit && item.asset_inventory_criteria_option_id !== criteria.id)}
                        key={index}
                        onClick={() => {
                          if (!props.edit) return;
                          if (
                            !item.asset_inventory_criteria_option_id ||
                            item.asset_inventory_criteria_option_id !==
                              criteria.id
                          ) {
                            setItem((prev) => ({
                              ...prev,
                              asset_inventory_criteria_option_id: criteria.id,
                            }));
                          } else if (
                            item.asset_inventory_criteria_option_id ===
                            criteria.id
                          ) {
                            setItem((prev) => ({
                              ...prev,
                              asset_inventory_criteria_option_id: null,
                            }));
                          }
                        }}
                        className={'button button--border-less flex-grow'}
                      >
                        <input
                          // disabled={!criteria.id || !props.edit}
                          disabled={!criteria.id || (!props.edit && item.asset_inventory_criteria_option_id !== criteria.id)}
                          readOnly={!criteria.id || !props.edit}
                          type='radio'
                          name={section.id}
                          value={criteria.id || index}
                          checked={
                            item.asset_inventory_criteria_option_id ===
                              criteria.id || false
                          }
                          onChange={() => {}}
                        />
                      </button>
                    </div>
                  }
                </td>
              )})
            : null}
          <Col
            className={'table__cols--input'}
            childClass={'table__rows__input'}
          >
            <div className='flex'>
              <div className='flex flex__column flex-grow flex-gap--1'>
                {props.edit ? (
                  <>
                    {/* {true ? <> */}
                    <Input
                      value={item.description || ""}
                      onChange={(e) =>
                        setItem((prev) => ({
                          ...prev,
                          description: e.target.value,
                        }))
                      }
                      placeholder={'Nhập mô tả'}
                      className={'reporting-page__table__text'}
                    />
                  </>
                ) : (
                  <>
                    <div className={'reporting-page__table__text'}>
                      {item.description}
                    </div>
                  </>
                )}
                {state.listFiles.length || props.edit
                ?  
                <>
                  <Uploader
                    id={section.id}
                    multipleFiles
                    listFiles={state.listFiles}
                    hasPreview
                    borderless
                    unScrollable={!props.edit}
                    unShowActions={!props.edit}
                    unShowFileName
                    actionMethod={fileMethods}
                    hasStacks={!props.edit && 5}
                    placeholder={{
                      input: () => '',
                      empty: () => '',
                      icon: () => false,
                    }}
                    center={'left'}
                    closeOnBackdrop
                  />
                  {confirmModal && (
                    <DeleteFileDialog
                      open={confirmModal}
                      handleClose={setConfirmModal}
                      setConfirm={setConfirm}
                    />
                  )}
                </>
                : null}
              </div>
            </div>
          </Col>
        </Row>
      </Tbody>
    </>
  );
};

export default ReportInventoryDetail;
