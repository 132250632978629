import { CModal, CModalBody } from "@coreui/react";
import { Badge, Tooltip } from "antd";
import { size, toArray } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import {
	buildStyles, CircularProgressbar as CircularProgressBar
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import chevronLeftIcon from "../../assets/icons/chevron-left.svg";
import chevronRightIcon from "../../assets/icons/chevron-right.svg";
import closeIcon from "../../assets/icons/close.svg";
import excelIcon from "../../assets/icons/excel.svg";
import imageUploaderIcon from "../../assets/icons/image-uploader.svg";
import pdfIcon from "../../assets/icons/pdf.svg";
import wordIcon from "../../assets/icons/word.svg";
import blankFileIcon from "../../assets/images/blank-file.png";
import config from "../../configuration/index";
import { useOutsideAlerter } from "../../helper/hooks";
import { STATUS_UPLOAD } from "../../store/reducers/uploadFiles";
import model from "./model";
import "./Uploader.scss";

const { PreviewImageBackground } = model;

const getExtension = (fileName) =>
  ".".concat(fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2));

const PreviewImage = (props) => {
  const {
    id,
    image,
    show,
    unShow,
    listItems = [],
    closeOnBackdrop = false,
  } = props;

  const imgRef = useRef();
  const ref = useRef();
  const [loadedProps, setLoadedProps] = useState(false);
  const [list, setList] = useState([]);
  const [selected, setSelected] = useState({});
  const [modal, setModal] = useState(false);

  const closeModal = () => {
    unShow(false);
    setModal(false);
  };
  const handleSelect = (direction) => {
    if (!direction) return;
    switch (direction) {
      case "-":
        return (
          list.indexOf(selected) > 0 &&
          setSelected(list[list.indexOf(selected) - 1])
        );
      case "+":
        return (
          list.indexOf(selected) < list.length - 1 &&
          setSelected(list[list.indexOf(selected) + 1])
        );
      default:
        return;
    }
  };

  useOutsideAlerter(imgRef, () => {
    // 	// if (!listItems.length) {
    // 	// unShow(false)
    // 	// closeModal()
    // 	// }
  });
  useEffect(() => {
    ref.current = true;
    return () => {
      ref.current = false;
    };
  }, []);
  useEffect(() => {
    if (ref.current && !loadedProps && listItems.length) {
      setLoadedProps(true);
      setList(listItems);
      setSelected(listItems[0]);
    }
  }, [listItems]);
  useEffect(() => {
    if (id) {
      setSelected(listItems.find((item) => item.id === id));
    }
  }, [id]);
  useEffect(() => {
    ref.current && setModal(show);
  }, [show]);

  return (
    <>
      <CModal
        size="xl"
        closeOnBackdrop={closeOnBackdrop}
        className={`modal modal--transparent modal--preview-image`}
        centered={true}
        // show={modal}
        show={show}
        onClose={() => closeModal()}
      >
        <CModalBody style={{ overflowY: "auto" }}>
          <div className="preview-image__background--overlay">
            <div
              className="preview-image__button preview-image__button-close"
              onClick={() => closeModal()}
            >
              <img src={closeIcon} alt="close icon" />
            </div>
            {list.length ? (
              <>
                <div
                  className="preview-image__button__wrapper preview-image__button-left"
                  onClick={() => handleSelect("-")}
                >
                  <div className="preview-image__button">
                    <img src={chevronLeftIcon} alt="chevron icon" />
                  </div>
                </div>
                <div
                  className="preview-image__button__wrapper preview-image__button-right"
                  onClick={() => handleSelect("+")}
                >
                  <div className="preview-image__button">
                    <img src={chevronRightIcon} alt="chevron icon" />
                  </div>
                </div>
                <PreviewImageBackground
                  backgroundImage={selected.image}
                  className={`preview-image__background ${
                    listItems.length
                      ? "preview-image__background__list-items"
                      : ""
                  }`}
                />
                <div
                  className={`preview-image ${
                    listItems.length ? "preview-image__list-items" : ""
                  }`}
                >
                  <img src={selected.image} alt="preview" ref={imgRef} />
                </div>
                <div className={`preview-image__carousel`}>
                  {list
                    .filter((file) => file.id)
                    .map((file) => (
                      <div
                        key={file.id}
                        onClick={() => {
                          setSelected(file);
                        }}
                      >
                        <PreviewItem
                          key={file.id}
                          id={file.id}
                          file_name={file.name}
                          file_image={`${file.image}`}
                          hasPreview={false}
                          showInfo
                          closeOnBackdrop={closeOnBackdrop}
                        />
                      </div>
                    ))}
                </div>
              </>
            ) : (
              <>
                <PreviewImageBackground
                  backgroundImage={image}
                  className={`preview-image__background ${
                    listItems.length
                      ? "preview-image__background__list-items"
                      : ""
                  }`}
                />
                <div
                  className={`preview-image ${
                    listItems.length ? "preview-image__list-items" : ""
                  }`}
                >
                  <img src={image} alt="preview" ref={imgRef} />
                </div>
              </>
            )}
          </div>
        </CModalBody>
      </CModal>
    </>
  );
};

const BadgeButton = (props) => {
  const { onClick } = props;

  return (
    <button {...props} onClick={onClick} className="uploader__button__close">
      <img src={closeIcon} alt="close" />
    </button>
  );
};

const PreviewItem = (props) => {
  const {
    id,
    file_image,
    file = false,
    onLocal = false,
    file_name,
    file_progress,
    retryUpload = false,
    showInfo = false,
    stacked = false,
    listItems = [],
    hasPreview = true,
    tooltip = false,
    closeOnBackdrop = false,
  } = props;
  const [image, setImage] = useState("");
  const [preview, setPreview] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);

  const getImage = () => (onLocal && file ? image : file_image);
  const previewImage = () => {
    setPreview(true);
  };

  const setPreviewIcon = (extension, options = null) => {
    switch (extension) {
      case ".doc":
      case ".docx":
        return <img src={wordIcon} alt="preview" />;
      case ".pdf":
        return <img src={pdfIcon} alt="preview" />;
      case ".xls":
      case ".xlsx":
        return <img src={excelIcon} alt="preview" />;
      case ".apng":
      case ".avif":
      case ".gif":
      case ".jpg":
      case ".jpeg":
      case ".jfif":
      case ".pjp":
      case ".pjpeg":
      case ".bmp":
      case ".png":
      case ".svg":
      case ".webp":
      case ".ico":
      case ".cur":
      case ".tif":
      case ".tiff":
        return <img src={getImage()} alt="preview" />;
      default:
        return <img src={blankFileIcon} alt="preview" />;
    }
  };
  const Image = (props) => {
    return (
      <>
        <div
          key={id}
          className="uploader__preview__child "
          onClick={() => hasPreview && !preview && previewImage()}
        >
          <div className="uploader__preview__child__image">
            {setPreviewIcon(getExtension(file_name).toLowerCase())}
            {hasPreview && preview && (
              <PreviewImage
                id={id}
                image={getImage()}
                show={preview}
                unShow={setPreview}
                listItems={listItems}
                closeOnBackdrop={closeOnBackdrop}
              />
            )}
            {file_progress < 100 && onLocal ? (
              <>
                <div
                  id={id}
                  className="uploader__preview__child__button uploader__preview__child__button--show"
                  onClick={() => {
                    typeof retryUpload === "function" && retryUpload();
                  }}
                >
                  <div style={{ width: 32, height: 32 }}>
                    <CircularProgressBar
                      key={id}
                      styles={buildStyles({ ...model.styleCircularLoader })}
                      value={file_progress}
                      text={`${file_progress}%`}
                    />
                  </div>
                  {/* { hasPreview &&
								(<div id={id} className="uploader__preview__child__button">
									<div className="uploader__preview__child__button__preview" onClick={() => previewImage()}><img src={previewIcon} alt="preview icon" /></div>
								</div>) */}
                </div>
              </>
            ) : (
              // preview Image interface
              // hasPreview &&
              // (<div id={id} className="uploader__preview__child__button">
              // 	<div className="uploader__preview__child__button__preview" onClick={() => previewImage()}><img src={previewIcon} alt="preview icon" /></div>
              // </div>)
              // null

              stacked && (
                <div
                  id={id}
                  className={`uploader__preview__child__button ${
                    stacked ? "uploader__preview__child__button--show" : ""
                  }`}
                >
                  <div
                    className="uploader__preview__child__button__text"
                    onClick={() => previewImage()}
                  >
                    {`+${stacked.length}`}
                  </div>
                </div>
              )
            )}
          </div>
          {showInfo ? null : (
            <div className="uploader__preview__child__info">
              {!onLocal || isUploaded ? (
                <div className="uploader__preview__child__text">
                  {file_name ? file_name : id}
                </div>
              ) : (
                <div className="uploader__preview__child__text uploader__preview__child__text--temporary">
                  {file_name ? file.name : id}
                </div>
              )}
            </div>
          )}
        </div>
      </>
    );
  };

  useEffect(() => {
    let timeOut;
    if (onLocal && getExtension(file_name)) {
      const reader = new FileReader();
      const url = reader.readAsDataURL(file);
      reader.onloadend = (e) => {
        setImage((prev) => reader.result);
      };
      if (file_progress === 100)
        timeOut = setTimeout(() => setIsUploaded(true), 1000);
    }
    return () => clearTimeout(timeOut);
  }, [file]);
  return (
    <>
      {tooltip ? (
        <Tooltip title={file_name ? file_name : id} placement="bottom">
          <Image />
        </Tooltip>
      ) : (
        <Image />
      )}
    </>
  );
};

const StackPreviewItem = (props) => {
  const {
    stacks = [],
    listItems = [],
    hasPreview = false,
    unShowActions = false,
    unShowFileName = false,
    closeOnBackdrop = false,
    removeFile,
  } = props;

  return (
    <div>
      {stacks.length ? (
        unShowActions ? (
          <PreviewItem
            key={stacks[0].id}
            id={stacks[0].id}
            file_name={stacks[0].name}
            file_image={`${config.apiEndpoint}${stacks[0].image}`}
            stacked={stacks}
            listItems={listItems.map((item) => ({
              ...item,
              image: `${config.apiEndpoint}${item.image}`,
            }))}
            showInfo={unShowFileName}
            closeOnBackdrop={closeOnBackdrop}
          />
        ) : (
          <Badge
            key={stacks[0].id}
            count={<BadgeButton onClick={() => removeFile(stacks[0].id)} />}
          >
            <PreviewItem
              key={stacks[0].id}
              id={stacks[0].id}
              file_name={stacks[0].name}
              file_image={`${config.apiEndpoint}${stacks[0].image}`}
              stacked={stacks}
              listItems={listItems.map((item) => ({
                ...item,
                image: `${config.apiEndpoint}${item.image}`,
              }))}
              showInfo={unShowFileName}
              closeOnBackdrop={closeOnBackdrop}
            />
          </Badge>
        )
      ) : null}
    </div>
  );
};

const Uploader = (props) => {
  const {
    id,
    disabled = false,
    multipleFiles = false,
    onLocal = false,
    listFiles = [],
    acceptFiles = [],
    withCaution = "",
    center = false,

    hasPreview = false,
    cancellation = false,
    retry = false,
    borderless = false,
    unScrollable = false,
    unShowActions = false,
    unShowFileName = false,
    hasStacks = false,
    closeOnBackdrop = false,

    fileProgress,
    setUploadFile,

    actionMethod = false,
    placeholder = {
      input: () =>
        `Kéo thả hoặc nhấp <span class="uploader__text--emphasized">vào đây</span> để tải ảnh lên</>`,
      empty: () => `Hiện chưa có tệp`,
      icon: () => true,
    },
  } = props;
  const uploadedFileAmount = size(fileProgress);
  const [files, setFiles] = useState([]);

  const validateFormat = (files) => {
    return files.filter((file) => {
      const ext = getExtension(file.name);
      return acceptFiles.length ? acceptFiles.includes(ext) : true;
    });
  };

  const handleFile = async (event) => {
    if (disabled) return;
    const newFiles = validateFormat([...event.target.files]);
    setFiles([...files]);
    await setUploadFile({ id, files: newFiles });
    event.target.files = null;
    event.target.value = null;
  };
  const handleFilesToUpload = async (filesToUpload) => {
    if (typeof actionMethod.add === "function")
      await actionMethod.add([...filesToUpload]);
  };
  const cancelUpload = (source) => {
    typeof source.cancel === "function" && source.cancel("Cancel by User");
  };
  const removeFile = async (index, isTemp = false) => {
    if (disabled) return;
    if (isTemp) {
      await props.unsetUploadFile({ index });
      const notLocal = files.filter((file) => file.id);
      const local = files.filter((file) => !file.id);
      const filtered = notLocal.concat(
        local.slice(0, index - 1).concat(local.slice(index + 1))
      );
      if (typeof actionMethod.add === "function")
        await actionMethod.add([...filtered]);
      setFiles((prev) => filtered);
    } else {
      if (typeof actionMethod.delete === "function") {
        let response = await actionMethod.delete(index);
        if (!response) return;
      }
      setFiles((prev) => files.filter((file) => file.id !== index));
    }
  };
  const retryUpload = async (file) => {
    retry &&
      file.status === STATUS_UPLOAD.failed &&
      typeof actionMethod.retry === "function" &&
      (await actionMethod.retry(file));
  };
  const handleActions = async (file) => {
    switch (file.status) {
      case STATUS_UPLOAD.uploading:
        if (cancellation) {
          return cancelUpload(file.cancelSource);
        } else return;
      // case STATUS_UPLOAD.failed: return typeof actionMethod.retry === 'function'
      // 	&& await actionMethod.retry(file)
      default:
        return removeFile(file.id, true);
    }
  };
  const hasFiles = () =>
    files.length ||
    (size(fileProgress) &&
      toArray(fileProgress).filter((file) =>
        onLocal ? true : file.belongTo === id
      ).length);

  useEffect(() => {
    const resetFileProgress = async () => await props.unsetFiles({ id });
    resetFileProgress();
    return () => {
      resetFileProgress({ id });
    };
  }, []);
  useEffect(() => {
    setFiles(listFiles);
  }, [listFiles]);
  useEffect(() => {
    const filesToUpload = toArray(fileProgress)
      .filter((file) => file.belongTo === id)
      .filter((file) => file.progress === 0);
    handleFilesToUpload(filesToUpload);
  }, [uploadedFileAmount]);

  return (
    <div className="uploader__box">
      <button
        disabled={disabled}
        className={`${hasFiles() ? "uploader__preview " : ""} ${
          borderless ? "uploader__button--border-less" : ""
        } uploader__button `}
      >
        <div
          className={`${
            hasFiles() ? "uploader__preview__container--has-children" : ""
          } ${
            unScrollable ? "uploader__preview__container--un-scroll" : ""
          } uploader__preview__container ${
            center === "left"
              ? `uploader__preview__container--flex-start`
              : center === "right"
              ? `uploader__preview__container--flex-end`
              : ""
          }`}
        >
          {hasFiles() ? (
            <>
              {hasStacks ? (
                <>
                  {[...files]
                    .slice(0, hasStacks - 1)
                    .filter((file) => file.id)
                    .map((file, index) => {
                      return (
                        <>
                          {unShowActions ? (
                            <PreviewItem
                              key={file.id}
                              id={file.id}
                              file_name={file.name}
                              file_image={`${config.apiEndpoint}${file.image}`}
                              showInfo={unShowFileName}
                              listItems={files.map((item) => ({
                                ...item,
                                image: `${config.apiEndpoint}${item.image}`,
                              }))}
                              closeOnBackdrop={closeOnBackdrop}
                            />
                          ) : (
                            <Badge
                              key={file.id}
                              count={
                                <BadgeButton
                                  onClick={() => removeFile(file.id)}
                                />
                              }
                            >
                              <PreviewItem
                                key={file.id}
                                id={file.id}
                                file_name={file.name}
                                file_image={`${config.apiEndpoint}${file.image}`}
                                showInfo={unShowFileName}
                                listItems={files.map((item) => ({
                                  ...item,
                                  image: `${config.apiEndpoint}${item.image}`,
                                }))}
                                closeOnBackdrop={closeOnBackdrop}
                              />
                            </Badge>
                          )}
                        </>
                      );
                    })}
                  <StackPreviewItem
                    key={-1}
                    stacks={files.slice(hasStacks - 1)}
                    listItems={files}
                    hasPreview={hasPreview}
                    unShowActions={unShowActions}
                    unShowFileName={unShowFileName}
                    removeFile={removeFile}
                    closeOnBackdrop={closeOnBackdrop}
                  />
                </>
              ) : (
                <>
                  {files
                    .filter((file) => file.id)
                    .map((file, index) => {
                      return (
                        <>
                          {unShowActions ? (
                            <PreviewItem
                              key={file.id}
                              id={file.id}
                              file_name={file.name}
                              file_image={`${config.apiEndpoint}${file.image}`}
                              showInfo={unShowFileName}
                              closeOnBackdrop={closeOnBackdrop}
                              listItems={files
                                .filter((file) => file.id)
                                .map((item) => ({
                                  ...item,
                                  image: `${config.apiEndpoint}${item.image}`,
                                }))}
                            />
                          ) : (
                            <Badge
                              key={file.id}
                              count={
                                <BadgeButton
                                  onClick={() => removeFile(file.id)}
                                />
                              }
                            >
                              <PreviewItem
                                key={file.id}
                                id={file.id}
                                file_name={file.name}
                                file_image={`${config.apiEndpoint}${file.image}`}
                                showInfo={unShowFileName}
                                closeOnBackdrop={closeOnBackdrop}
                                listItems={files
                                  .filter((file) => file.id)
                                  .map((item) => ({
                                    ...item,
                                    image: `${config.apiEndpoint}${item.image}`,
                                  }))}
                              />
                            </Badge>
                          )}
                        </>
                      );
                    })}
                  {size(fileProgress)
                    ? toArray(fileProgress)
                        .filter((file) =>
                          onLocal ? true : file.belongTo === id
                        )
                        .map((file) => {
                          return (
                            <Badge
                              key={file.id}
                              count={
                                <BadgeButton
                                  onClick={() => handleActions(file)}
                                />
                              }
                            >
                              <PreviewItem
                                key={file.id}
                                id={file.id}
                                file_image={file.image}
                                file_name={file.file.name}
                                file_progress={file.progress}
                                file_status={file.status}
                                file={file.file}
                                onLocal
                                retryUpload={() => retryUpload(file)}
                                showInfo={unShowFileName}
                                closeOnBackdrop={closeOnBackdrop}
                              />
                            </Badge>
                          );
                        })
                    : null}
                  {unShowActions ? null : (
                    <div className="uploader__container">
                      <input
                        type="file"
                        disabled={disabled}
                        onChange={(event) => {
                          handleFile(event);
                        }}
                        onClick={() => {}}
                        className="uploader"
                        multiple={multipleFiles}
                        accept={
                          acceptFiles.length ? acceptFiles.join(", ") : "*"
                        }
                      />
                    </div>
                  )}
                </>
              )}
            </>
          ) : (
            <>
              {typeof placeholder?.icon === "function" && placeholder?.icon() && (
                <div className="uploader__text uploader__icon">
                  <img
                    alt="uploader icon"
                    src={imageUploaderIcon}
                    className="uploader__text"
                  />
                </div>
              )}
              {!disabled
                ? // ? (<div className="text-nowrap uploader__text">Kéo thả hoặc nhấp <span className="uploader__text--emphasized">vào đây</span> để tải ảnh lên</div>)
                  typeof placeholder?.input === "function" &&
                  placeholder?.input() && (
                    <div
                      className="uploader__text "
                      dangerouslySetInnerHTML={{
                        __html:
                          (typeof placeholder?.input === "function" &&
                            placeholder?.input()) ||
                          "",
                      }}
                    />
                  )
                : typeof placeholder?.empty === "function" &&
                  placeholder?.empty() && (
                    <div
                      className="uploader__text "
                      dangerouslySetInnerHTML={{
                        __html:
                          (typeof placeholder?.empty === "function" &&
                            placeholder?.empty()) ||
                          "",
                      }}
                    />
                  )}
              {
                typeof placeholder?.empty === "function" &&
                placeholder?.empty() ? (
                  <input
                    type="file"
                    disabled={disabled}
                    onChange={(event) => {
                      handleFile(event);
                    }}
                    onClick={() => {}}
                    className="uploader uploader__drop-zone"
                    multiple={multipleFiles}
                    accept={acceptFiles.length ? acceptFiles.join(", ") : "*"}
                  />
                ) : (
                  <div className="uploader__container">
                    <input
                      type="file"
                      disabled={disabled}
                      onChange={(event) => {
                        handleFile(event);
                      }}
                      onClick={() => {}}
                      className="uploader"
                      multiple={multipleFiles}
                      accept={acceptFiles.length ? acceptFiles.join(", ") : "*"}
                    />
                  </div>
                )
              }
            </>
          )}
        </div>
      </button>
      {withCaution && (
        <div className="uploader__text uploader__sub-text">{withCaution}</div>
      )}
    </div>
  );
};

export default Uploader;
export { PreviewImage };

