import * as actionTypes from "../actions/actionTypes";

const initialState = {
  paramCurrentNode: {},
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PARENT_ASSET_TREES_FIELDS:
      return {
        ...state,
        paramCurrentNode: action.payload?.responseData,
      };
    default:
      return state;
  }
};
export default reducer;
