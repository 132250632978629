import baseService from 'src/helper/baseService';
import axios from '../../helper/axios';
import * as actionTypes from './actionTypes';

const getInventoryHistory = ({ payload, config }) => {
  return axios.get(
    `/asset-import-export/getAllImportExport?currentPage=${
      payload.currentPage
    }&pageSize=${
      payload.rowsPerPage
    }&sortField=created_at&sortOrder=DESC&filters=${encodeURIComponent(
      payload.filters
    )}`
  );
};

const getAssetInventoryHistory = ({ payload, config }) => {
  return axios.get(
    `asset-inventory-history/getAllAssetInventoryHistoryByID/${payload.idAsset}`,
    {
      params: payload.filtersList,
    }
  );
};

export const getAllAssetInventoryHistory = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getAssetInventoryHistory,
      { config, payload },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};

const exportExcel = ({ payload, config }) => {
  return axios.download(
    `/asset-import-export/exportAllImportExport?sortField=created_at&sortOrder=DESC&filters=${encodeURIComponent(
      payload.filters
    )}`
  );
};

const responseSuccess = (res) => {
  return res.data;
};

export const getAllInventoryHistory = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getInventoryHistory,
      { config, payload },
      actionTypes.GET_INVENTORY_HISTORY,
      (res) => responseSuccess(res)
    );
  };
};

export const getExportExcel = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      exportExcel,
      { config, payload },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};
