import React, {useState} from 'react';
import scanImg from '../../../../assets/images/scan.svg';
import './AssetItem.scss';
import axiosWrapper from '../../../../helper/axios';
import {useEffect} from 'react';
import {useCallback} from 'react';
import NoImg from '../../../../assets/images/no-img.svg.png';
import configuration from "src/configuration/index";

const AssetItem = ({openHandler, item}) => {
  const [img, setImg] = useState('');

  const fetchImg = useCallback(
    () =>
      axiosWrapper
        .get(
          `${configuration.apiEndpoint}/asset-group/getAssetGroup/${item.asset_group.id}`
        )
        .then((res) => {
          const resImg =
            res.data.responseData.asset_group_images?.length > 0
              ? res.data.responseData.asset_group_images[0].image
              : "--";
          setImg(resImg);
        }),
    [item.asset_group.id]
  );

  useEffect(() => {
    fetchImg();
  }, [fetchImg]);

  const data = {
    name: item?.asset_group.name,
    code: item?.code,
  };

  return (
    <li className="asset-item" onClick={() => openHandler(item.id)}>
      <div className="asset-img">
        {img !== "--" ? (
          <img src={`${configuration.apiEndpoint}${img}`} alt="asset" />
        ) : (
          <img src={NoImg} alt="asset" />
        )}
      </div>
      <div className="asset-text">
        <h4 className="asset-name">{data.name}</h4>
        <span className="asset-id">{data.code}</span>
      </div>
      <div className="asset-scan-img">
        <img src={scanImg} alt="scan" />
      </div>
    </li>
  );
};

export default AssetItem;
