import baseService from "src/helper/baseService";
import axios from "../../helper/axios";
import * as actionTypes from "./actionTypes";

const getNotification = ({ payload, config }) => {
  return axios.get(
    `/notification/getMyNotification?currentPage=${
      payload.currentPage ?? 1
    }&pageSize=${
      payload.rowsPerPage ?? 10
    }&sortField=created_at&sortOrder=DESC&filters=${encodeURIComponent(
      payload.filters ?? ""
    )}`
  );
};

const getNotificationGroups = ({ payload, config }) => {
  return axios.get(`/notification/getUnreadNotification`);
};

const deleteNotificationAction = ({ payload, config }) => {
  return axios.delete(`/notification/delete/${payload.id}`);
};

const readNotificationAction = ({ payload, config }) => {
  return axios.put(`/notification/readNotification/${payload.id}`);
};

const responseSuccess = (res) => {
  return res?.data;
};

export const getAllNotification = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getNotification,
      { config, payload },
      actionTypes.GET_NOTIFICATION,
      (res) => responseSuccess(res)
    );
  };
};

export const getAllNotificationGroups = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getNotificationGroups,
      { config, payload },
      actionTypes.GET_NOTIFICATION_GROUP,
      (res) => responseSuccess(res)
    );
  };
};

export const deleteNotification = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      deleteNotificationAction,
      { config, payload },
      actionTypes.DELETE_NOTIFICATION,
      (res) => res
    );
  };
};

export const readNotification = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      readNotificationAction,
      { config, payload },
      actionTypes.NO_ACTION,
      (res) => res
    );
  };
};
