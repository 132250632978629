import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useDispatch, useSelector, } from "react-redux"
import {
  unsetUploadFile,
  setFailedProgress,
  retryUploadProgress,
} from "../store/actions"
import store from "../store/createStore"
import axios from "./axios"
export function useOutsideAlerter(ref, action) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        // alert("You clicked outside of me!");
        action()
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

export const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export function useDeviceSize() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  const { height, width } = windowDimensions;
  const dispatch = useDispatch()

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize, { passive: true });
    if (width !== undefined && height !== undefined) dispatch({ type: 'setSidebarShow', sidebarShow: false });
    return () => window.removeEventListener("resize", handleResize, { passive: true });
  }, [windowDimensions]);

  return [windowDimensions, setWindowDimensions]
}

export function useUploadFile(props) {
  const {
    id,
    add: addAPI,
    cancel: cancelAPI,
    delete: deleteAPI,
    handleState = false,

    confirmDelete = false,
    confirmed = false,
    setConfirmed = false,
  } = props;
  const dispatch = useDispatch()
  const [state, setState] = useState({
    source: axios.request.CancelToken.source()
  })
  const [deleteId, setDeleteId] = useState(false)

  const deleteFile = async (id) => {
    const response = await dispatch(deleteAPI({ id }))
    if (!response || response.status !== "success") return
    handleState({ filter: id })
  }

  useEffect(() => {
    if (confirmDelete !== false
      && typeof confirmDelete === "function"
      && confirmed && deleteId) {
      deleteFile(deleteId)
      setDeleteId(false)
    }
  }, [confirmed])
  useEffect(() => {
    if (deleteId === false) {

      typeof setConfirmed === "function"
        && setConfirmed(false);
    }
  }, [deleteId])

  const fileMethods = {
    reset: async () => {
      typeof handleState === 'function'
        && handleState({ empty: [] })
    },
    // update state in current cpn
    add: async (list) => {
      // setListFiles(list)
      // call api for each file uploading
      let response;
      const newListFiles = await Promise.allSettled([
        ...list.map(async (file, index) => {
          try {
            // Set API for 
            // Success upload file: remove local file - store fileProgress
            response = await dispatch(addAPI({ id, file: file.file, index: file.id, dispatch, }, {
              cancelToken: file.cancelSource.token,
              // cancelToken: state.source.token, 
            }))
            // Fail upload file: return uploaded file
            if (response?.status !== "success") {
              await dispatch(setFailedProgress({ id: file.id }))
              return false
            }
            return { ...response.responseData, index: file.id }

          } catch (error) {
            if (error) {
            }
          }
        }),
      ])

      // Filter from Promise Result Object 
      // To get item from response
      const returnFiles = newListFiles
        .filter(file => file.value)
        .map(file => ({ ...file.value, }))
      // reversed array to remove each 
      // avoiding remove the index - id of file of fileProgress store has been changed
      await returnFiles
        .reverse()
        .map(async ({ index }) => {
          await (dispatch(unsetUploadFile({ index })))
        })
      // Handle state from cpn 
      typeof handleState === 'function'
        && handleState({
          add: returnFiles
            .map(({ index, ...file }) => file)
        })
    },
    retry: async (file) => {
      try {
        await dispatch(retryUploadProgress({ id: file.id }))
        const reUploadFile = store.getState().uploadFiles.fileProgress[file.id]
        // Set API for 
        let response = await dispatch(addAPI({ id, file: reUploadFile.file, index: reUploadFile.id, }, {
          cancelToken: reUploadFile.cancelSource.token,
        }))
        // Success upload file: remove local file - store fileProgress
        // Fail upload file: return uploaded file
        if (response?.status !== "success") return Promise.reject("Upload File failed")
        await (dispatch(unsetUploadFile({ index: file.id })))
        return response.responseData

      } catch (error) {
        if (error) {
          await dispatch(setFailedProgress({ id: file.id }))
        }
      }
    },
    // get array of differ files of fetched API data of Item from state files
    // to call API delete files
    delete: async (id) => {
      setDeleteId(id)
      if (confirmDelete !== false
        && typeof confirmDelete === "function") {
        return await confirmDelete()
        // let confirmRes = 
        // // !confirmed
        // if (!confirmRes) return
      }
      // const response = await dispatch(deleteAPI({id}))
      // if (!response || response.status !== "success") return 
      // handleState({filter: id})
      deleteFile(id)
    }
  }

  return fileMethods
}

export function useForceUpdate() {
  const [, setTick] = useState(0);
  const update = useCallback(() => {
    setTick(tick => tick + 1);
  }, [])
  return update;
}
