import baseService from "src/helper/baseService";
import * as actionTypes from "./actionTypes";

//Overview page
export const setExportExcelAssetSpinner = (payload = {}, config) => {
  return async (dispatch) => {
    return baseService.dispatchStoreAction(
      actionTypes.SET_ASSET_EXPORT_EXCEL_SPINNER,
      {},
      dispatch
    );
  };
};

export const unSetExportExcelAssetSpinner = (payload = {}, config) => {
  return async (dispatch) => {
    return baseService.dispatchStoreAction(
      actionTypes.UNSET_ASSET_EXPORT_EXCEL_SPINNER,
      {},
      dispatch
    );
  };
};

export const setExportExcelAssetGroupSpinner = (payload = {}, config) => {
  return async (dispatch) => {
    return baseService.dispatchStoreAction(
      actionTypes.SET_ASSET_GROUP_EXPORT_EXCEL_SPINNER,
      {},
      dispatch
    );
  };
};

export const unSetExportExcelAssetGroupSpinner = (payload = {}, config) => {
  return async (dispatch) => {
    return baseService.dispatchStoreAction(
      actionTypes.UNSET_ASSET_ASSET_GROUP_EXPORT_EXCEL_SPINNER,
      {},
      dispatch
    );
  };
};

//Import-export page
export const setImportExportExcelSpinner = (payload = {}, config) => {
  return async (dispatch) => {
    return baseService.dispatchStoreAction(
      actionTypes.SET_IMPORT_EXPORT_EXCEL_SPINNER,
      {},
      dispatch
    );
  };
};

export const unSetImportExportExcelSpinner = (payload = {}, config) => {
  return async (dispatch) => {
    return baseService.dispatchStoreAction(
      actionTypes.UNSET_IMPORT_EXPORT_EXCEL_SPINNER,
      {},
      dispatch
    );
  };
};

//Import page
export const setImportViewPDFSpinner = (payload = {}, config) => {
  return async (dispatch) => {
    return baseService.dispatchStoreAction(
      actionTypes.SET_IMPORT_VIEW_PDF_SPINNER,
      {},
      dispatch
    );
  };
};

export const unSetImportViewPDFSpinner = (payload = {}, config) => {
  return async (dispatch) => {
    return baseService.dispatchStoreAction(
      actionTypes.UNSET_IMPORT_VIEW_PDF_SPINNER,
      {},
      dispatch
    );
  };
};
