import firebase from "firebase/app";
import React, { Component, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Route, Router, Switch } from "react-router-dom";
import { render } from "sass";
import ScanBarcode from "./components/pages/scanBarcode/ScanBarcode";
import history from "./helper/history";
import { messaging } from "./init-fcm";
import "./scss/style.scss";
import Loader from "./sharedComponents/Loader/Loader";
import {
  getNotificationToken,
  isAuthenticated,
  setToken,
} from "./store/actions";
import store from "./store/createStore";
import sliceKeys from "./store/sliceKeys";

// import $ from 'jquery';
// import 'bootstrap/js/modal';
// import 'bootstrap/js/dropdown';
// import 'bootstrap/js/tooltip';
// import 'bootstrap/js/dist/modal.js';
// import 'bootstrap/js/dist/dropdown.js';
// import 'bootstrap/js/dist/tooltip.js';
// import 'bootstrap/dist/css/bootstrap.css';
// window.$ = $;
// window.jQuery = $;
// window.Util = require('exports-loader?Util!bootstrap/js/src/util'); // eslint-disable-line
// window.Dropdown = require('exports-loader?Dropdown!bootstrap/js/src/dropdown'); // eslint-disable-line
// window.Modal = require('exports-loader?Modal!bootstrap/js/src/modal'); // eslint-disable-line
// require('bootstrap');

const loading = (
  <div>
    <Loader withLogo={true} />
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./layouts/TheLayout"));
const ToastMessage = React.lazy(() => import("./layouts/toast-message/index"));

// Pages
const Login = React.lazy(() => import("./components/auth/login/index"));
const ForgotPassword = React.lazy(() =>
  import("./components/auth/forgot-password/index")
);
const CreateNewPassword = React.lazy(() =>
  import("./components/auth/createNewPassword/index")
);
const Register = React.lazy(() =>
  import("./components/pages/register/Register")
);
const Page404 = React.lazy(() => import("./components/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./components/pages/page500/Page500"));

async function initNotificationToken() {
  if (
    firebase.messaging.isSupported() &&
    localStorage.getItem(sliceKeys.auth)
  ) {
    messaging
      .requestPermission()
      .then(async function () {
        const token = await messaging.getToken();
        if (localStorage.getItem(sliceKeys.auth)) {
          await store.dispatch(
            getNotificationToken(localStorage.getItem(sliceKeys.auth), token)
          );
        }
      })
      .catch(function (err) {
      });
  }
  else {
  }
}

const App = () => {
  const [userPermission, setUserPermission] = useState([]);
  const [componentDidMount, setComponentDidMount] = useState(true);

  useEffect(() => {
    if (isAuthenticated()) {
      initNotificationToken();

      setTimeout(() => {
        const userData = store.getState().auth;
        if (userData && userData?.user) {
          if (userData?.user?.permision) {
            setUserPermission(userData?.user?.permision);
          }
        }
      }, 4000);
    }
    setComponentDidMount(false);
  }, []);

  useEffect(() => {
    if (!componentDidMount) {
      const url = window.location.pathname;
      if (url === "/assets" || url === "/assets/") {
        if (userPermission?.includes("AM_ADMIN")) {
          //Do not something
        } else {
          history.push("/assets/myassets");
        }
      }
    }
  }, [userPermission]);

  return (
    <>
      <Router history={history}>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route
              exact
              path="/404"
              name="Page 404"
              render={(props) => <Page404 {...props} />}
            />
            <Route
              exact
              path="/500"
              name="Page 500"
              render={(props) => <Page500 {...props} />}
            />
            <Route
              exact
              path="/ScanBarcode/:id"
              name="Scan Barcode"
              render={(props) => <ScanBarcode {...props} />}
            />
            <Route
              exact
              path="/ScanBarcode"
              name="Scan Barcode Inventory"
              render={(props) => <ScanBarcode {...props} />}
            />
            <Route component={TheLayout} path="/" />
          </Switch>
          <ToastMessage maxCount={4} filter />
        </React.Suspense>
      </Router>
    </>
  );
};

export default App;
