import React, {useState, useEffect, } from 'react'

import { useDeviceSize, } from "../../../helper/hooks"
import ReportInventoryDetail from "./ReportInventoryDetail"
import ReportInventoryDetailTabs from "./ReportInventoryDetailTabs"
import "./reporting-page.scss"

const ReportInventoryDetailModal = props => {
	const [windowDimensions, _] = useDeviceSize();
	const { height, width } = windowDimensions;
	const [state, setState] = useState(false)

	useEffect(() => {
		return () => {
			props.show && setState(true)
		}
	}, [props.show,])
		
	return (<div className="reporting-page">
		{
		width > 768
			? <ReportInventoryDetail {...props} show={width > 768 &&  props?.show} changed={state} setChanged={setState}  />
			: <ReportInventoryDetailTabs {...props} show={(!(width > 768) || props.assetInventory) && props?.show} changed={state} setChanged={setState} />
		}
	</div>)
}

export default ReportInventoryDetailModal