import baseService from "src/helper/baseService";
import axios from "../../helper/axios";
import * as actionTypes from "./actionTypes";

const getAllDepartmentMethod = ({ payload, config }) => {
  return axios.get(
    payload ?`user/getAllDepartment?currentPage=${payload.currentPage}&pageSize=${
      payload.rowsPerPage
    }&filters=${encodeURIComponent(payload.searchQuery)}`
  : `user/getAllDepartment`);
};

const getAllUserByDepartmentIDMethod = ({ payload, config }) => {
  return axios.get(
    `user/getAllUserByDepartmentID/${payload.id}?currentPage=${
      payload.currentPage
    }&pageSize=${payload.rowsPerPage}&filters=${encodeURIComponent(
      payload.searchQuery
    )}`
  );
};

const getAllUserMethod = ({ payload, config }) => {
  return axios.get(
    `user/getAllUser?currentPage=${
      payload.currentPage
    }&pageSize=${payload.rowsPerPage}&filters=${encodeURIComponent(
      payload.searchQuery
    )}`
  );
};

const getUserSignatureMethod = ({ payload, config }) => {
  return axios.get(`user/getMySignature`);
};

const getUserSignatureByIdMethod = ({ payload, config }) => {
  return axios.get(`user/getUserSignature/${payload}`);
};

const responseSuccess = (res) => {
  return res.data;
};

export const getAllDepartment = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getAllDepartmentMethod,
      { config, payload },
      actionTypes.GET_ALL_DEPARTMENT,
      (res) => responseSuccess(res)
    );
  };
};
export const getUserSignature = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getUserSignatureMethod,
      { config, payload },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};
export const getUserSignatureById = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getUserSignatureByIdMethod,
      { config, payload },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};
export const getAllUserByDepartmentID = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getAllUserByDepartmentIDMethod,
      { config, payload },
      actionTypes.GET_ALL_USER_BY_DEPARTMENTID,
      (res) => responseSuccess(res)
    );
  };
};

export const getAllUser = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getAllUserMethod,
      { config, payload },
      actionTypes.GET_ALL_USER,
      (res) => responseSuccess(res)
    );
  };
};
