import React, { useState, useEffect, } from 'react';
import {
  CModal,
  CModalHeader,
  CModalBody,
  CModalFooter,
} from '@coreui/react'
import "./Modal.scss"

const Mdal = props => {
	const {
		show = false, 
		handleModal, 
		components = {}, 
		closeButtonHeader = false, 
	} = props
    const [modal, setModal] = useState(false)

	const onClose = () => {
		handleModal(false)
		setModal(false)
	}

	useEffect(() => setModal(show && true), [show,])

	return (
		<>
			{Object.keys(components).length
			? (<CModal 
				closeOnBackdrop={false}
				centered={true} 
				show={modal} 
				onClose={() => onClose()}
				{...props}
				>
					{components.header() && <CModalHeader closeButton={closeButtonHeader}>{components.header()}</CModalHeader>}
					{components.body() && <CModalBody style={{maxHeight: "70vh", overflowY: "auto"}}>{components.body()}</CModalBody>}
					{components.footer() && <CModalFooter>{components.footer()}</CModalFooter>}
			</CModal>) : null}
		</>
	)
}

export default Mdal;