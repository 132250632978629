import baseService from "src/helper/baseService";
import axios from "../../helper/axios";
import sliceKeys from "../sliceKeys";
import * as actionTypes from "./actionTypes";
import { setMessage } from "./message";

const getAllAssetUnallocatedMethod = ({ payload, config }) => {
  return axios.get(
    `asset/getAllAsset${
      payload
        ? `?currentPage=${payload.currentPage}&pageSize=${
            payload.rowsPerPage
          }&filters=${encodeURIComponent(
            payload.searchQuery
          )}&isAllocated=false`
        : ""
    }`
  );
};

const getAllAssetAllocatedMethod = ({ payload, config }) => {
  return axios.get(
    `asset/getAllAsset${
      payload
        ? `?currentPage=${payload.currentPage}&pageSize=${
            payload.rowsPerPage
          }&filters=${encodeURIComponent(payload.searchQuery)}&isAllocated=true`
        : ""
    }`
  );
};

const getWarehouseAssetMethod = ({ payload, config }) => {
  return axios.get(
    `asset/getAllAsset${
      payload
        ? `?currentPage=${payload.currentPage}&pageSize=${
            payload.rowsPerPage
          }&filters=${encodeURIComponent(
            payload.searchQuery
          )}&isAllocated=false`
        : ""
    }`
  );
};

const postAssetRequestMethod = ({ payload, config }) => {
  return axios.post(`asset-request/postAssetRequest`, payload, config);
};

const postAnotherAssetRequestMethod = ({ payload, config }) => {
  return axios.post(
    `asset-request/postAnotherAssetRequest`,
    payload.data,
    config
  );
};

const putAssetRequestMethod = ({ payload, config }) => {
  return axios.put(`asset-request/putAssetRequest/${payload.id}`, payload.data);
};

const putAnotherAssetRequestMethod = ({ payload, config }) => {
  return axios.put(
    `asset-request/putAnotherAssetRequest/${payload.id}`,
    payload.data
  );
};

const exportPdfRequestMethod = ({ payload, config }) => {
  return axios.download(`asset-request/exportPdfRequest/${payload}`);
};

const signMyAssetRequestMethod = ({ payload, config }) => {
  return axios.put(
    `asset-request/signMyAssetRequest/${payload.id}`,
    payload.data
  );
};

const refuseAssetRequestMethod = ({ payload, config }) => {
  return axios.put(
    `asset-request/refuseAssetRequest/${payload.id}`,
    payload.description
  );
};

const printBarcodeMethod = ({ payload, config = { responseType: "blob" } }) => {
  return axios.post(`/asset-group/exportPdfAssetInventory`, payload, config);
};

const getAllAllowcationAssetRequestMethod = ({ payload, config }) => {
  return axios.get(
    `asset-request/getAllAllowcationAssetRequest?currentPage=${payload.currentPage}
    &pageSize=${payload.rowsPerPage}
    &sortField=created_at&sortOrder=DESC&searchKey=${payload.searchKey}`
  );
};

const getAllMyAssets = ({ payload, config }) => {
  return axios.get(
    `asset/getAllMyAssets?currentPage=${payload.currentPage}&pageSize=${
      payload.rowsPerPage
    }&sortField=created_at&sortOrder=DESC&filters=${encodeURIComponent(
      payload.searchQuery
    )}`
  );
};

const getAllMyAssetRequestsMethod = ({ payload, config }) => {
  return axios.get(
    `asset-request/getAllMyAssetRequests?currentPage=${
      payload.currentPage
    }&pageSize=${
      payload.rowsPerPage
    }&sortField=created_at&sortOrder=DESC&searchKey=${encodeURIComponent(
      payload.searchKey
    )}`
  );
};

const getAllAssetGroupByTreeIdMethod = ({ payload, config }) => {
  return axios.get(
    `asset-group/getAllAssetGroupByTreeID/${payload.nodeId}?filters=${payload.filters}`
  );
};

const deleteListAssetListMethod = ({ payload, config }) => {
  return axios.post(`asset/deleteListAsset`, { asset_id: payload }, config);
};

//===Change list asset payment status===//
const postChangeListAssetPaymentStatusMethod = ({ payload, config }) => {
  return axios.post(`asset/changeListAssetPaymentStatus`, payload.data, config);
};

const responseSuccess = (res) => {
  return res.data;
};

export const getAllAssetUnallocated = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getAllAssetUnallocatedMethod,
      { config, payload },
      actionTypes.GET_ALL_ASSET,
      (res) => responseSuccess(res)
    );
  };
};

export const getAllAssetAllocated = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getAllAssetAllocatedMethod,
      { config, payload },
      actionTypes.GET_ALL_ASSET,
      (res) => responseSuccess(res)
    );
  };
};

export const getAllAllowcationAssetRequest = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getAllAllowcationAssetRequestMethod,
      { config, payload },
      actionTypes.GET_ALL_ALLOCATION_ASSET,
      (res) => responseSuccess(res)
    );
  };
};

export const getAllMyAssetRequest = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getAllMyAssetRequestsMethod,
      { config, payload },
      actionTypes.GET_ALL_MY_ASSET_REQUEST,
      (res) => responseSuccess(res)
    );
  };
};

export const postAssetRequest = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      postAssetRequestMethod,
      { config, payload },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};

export const postAnotherAssetRequest = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      postAnotherAssetRequestMethod,
      { config, payload },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};
export const putAssetRequest = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      putAssetRequestMethod,
      { payload, config },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};

export const putAnotherAssetRequest = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      putAnotherAssetRequestMethod,
      { payload, config },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};

export const refuseAssetRequest = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      refuseAssetRequestMethod,
      { payload, config },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};
export const exportPdfRequest = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      exportPdfRequestMethod,
      { payload, config },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};

export const signMyAssetRequest = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      signMyAssetRequestMethod,
      { payload, config },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};

export const getAllMyAsset = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getAllMyAssets,
      { config, payload },
      actionTypes.GET_ALL_MY_ASSET,
      (res) => responseSuccess(res)
    );
  };
};

export const getWarehouseAsset = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getWarehouseAssetMethod,
      { config, payload },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};

export const printBarcode = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      printBarcodeMethod,
      { config, payload },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};

export const notAllowSaveMatchWarehouseManager = (payload = {}, config) => {
  return async (dispatch) => {
    dispatch(
      setMessage({
        actionType: actionTypes.NO_ACTION,
        message: "Không được phép chọn trùng chủ kho",
        type: sliceKeys.message_warning,
      })
    );
  };
};

export const changeListAssetPaymentStatus = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      postChangeListAssetPaymentStatusMethod,
      { config, payload },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};

export const deleteListAsset = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      deleteListAssetListMethod,
      { config, payload },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};

export const getAllAssetGroupByTreeIdNoAction = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getAllAssetGroupByTreeIdMethod,
      { config, payload },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};
