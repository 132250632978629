import Qs from "qs";
import configuration from "src/configuration/index";
import axios from "axios";
import * as actionTypes from "src/store/actions/actionTypes";
import sliceKeys from "src/store/sliceKeys";
import cookies from "./cookies";
import { setMessage } from "src/store/actions";
import store from "src/store/createStore";
class ServiceClass {
  constructor() {}

  paramsSerializer = (params) => {
    if (
      typeof URLSearchParams !== "undefined" &&
      params instanceof URLSearchParams
    ) {
      return params.toString();
    }
    return Qs.stringify(params, { arrayFormat: "repeat", skipNulls: true });
  };

  loadConfiguration() {
    const servicesConfig = {
      baseURL: configuration.apiEndpoint,
    };
    // this.request = axios.create({
    //   ...servicesConfig,
    //   paramsSerializer: this.paramsSerializer,
    // });
    this.request = {
      ...axios,
      ...axios.create({
      ...servicesConfig,
      paramsSerializer: this.paramsSerializer,
    })};
    if (localStorage.getItem(sliceKeys.auth)) {
      this.request.defaults.headers.Authorization = `Bearer ${localStorage.getItem(
        sliceKeys.auth
      )}`;
    }
  }

  get(url, config = {}) {
    this.loadConfiguration();
    return this.request
      .get(url, config)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  }

  download(url, config = {responseType: 'blob'}) {
    this.loadConfiguration();
    return this.request
      .get(url, config)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  }


  post(url, payload, config = {}) {
    this.loadConfiguration();
    return this.request
      .post(url, payload, config)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  }

  put(url, payload, config = {}) {
    this.loadConfiguration();
    return this.request
      .put(url, payload, config)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  }

  delete(url, config = {}) {
    this.loadConfiguration();
    return this.request
      .delete(url, config)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  }
}
const axiosWrapper = new ServiceClass();
export default axiosWrapper;
