import React, { useRef, useState } from 'react'
import { useDispatch } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import MuiButton from '@material-ui/core/Button';
import { IconButton, Typography } from '@material-ui/core';
import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import closeIcon from '../../../assets/icons/u_times.svg'

const styles = (theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: 0,
        padding: '8px 8px 8px 12px',
    },
    title: {
        
        fontSize: '14px',
        fontWeight: 600,
        color: '#373737'
    },
    closeButton: {
        width: '20px',
        height: '20px',
        color: theme.palette.grey[500],
        '&:focus': {
            outline: 'none',
        }
    }
});

const Dialog = withStyles((theme) => ({
    root: {
        borderRadius: '16px',
    },
    paper: {
        borderRadius: '8px',
    },
    paperWidthSm: {
        maxWidth: '550px',
    },
    paperWidthMd: {
        maxWidth: '720px',
    },
    paperWidthLg: {
        maxWidth: '1190px',
        height: '600px'
    }
}))(MuiDialog)
  
const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography className={classes.title}>{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
              <img src={closeIcon} alt="close icon" />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
});
  
const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
      '&::-webkit-scrollbar': {
        width: '10px'
      },
      '&::-webkit-scrollbar-track': {
        background: '#f1f1f1',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'rgba(211, 206, 206)',
        borderRadius: '10px',
      }
    },
}))(MuiDialogContent);
  
const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const Button = withStyles((theme) => ({
    "root": {
        borderRadius: "4px",
        boxShadow: "none",
        height: "40px",
        padding: "10px 12px",
        textTransform: "none",
        fontSize: "14px",
        fontWeight: 600,

        "&:focus": {
            outline: "none",
            boxShadow: "none",
        }
    },
    "contained": {
        color: "#fff",
        backgroundColor: "#2b67b3"
    },
    "text": {
        color: "#2b67b3"
    },
    "containedSecondary": {
        color: "#fff",
        backgroundColor: "red"
    }
}))(MuiButton)

const DeteleFileDialog = (props) => {
    const { 
        open, 
        handleClose, 
        setConfirm, 
        title = "Xác nhận xóa", 
        message = "Bạn có chắc muốn xóa cái này?", 
    } = props

    return (
        <div>
            <Dialog fullWidth={true} maxWidth="xs" open={open} onClose={() => handleClose(false)} aria-labelledby="form-dialog-title">
                <DialogTitle id="customized-dialog-title" onClose={() => handleClose(false)}>
                    {title}
                </DialogTitle>
                <DialogContent dividers>
                    {message}
                </DialogContent>
                <DialogActions>
                    <>
                        <Button onClick={() => handleClose(false)} color="primary">Từ chối</Button>
                        <Button onClick={(e) => {
                                    setConfirm(true)
                                    handleClose(false)
                                }}
                                variant="contained" 
                                color="secondary">
                                    Đồng ý
                        </Button>
                    </>
                </DialogActions>
            </Dialog> 
        </div>
    )
}

export default DeteleFileDialog
