import baseService from "src/helper/baseService";
import * as actionTypes from "./actionTypes";

export const setCurrentPage = (payload = {}, config) => {
  return async (dispatch) => {
    return baseService.dispatchStoreAction(
      actionTypes.SET_CURRENT_PAGE,
      payload,
      dispatch,
    );
  };
};

export const setRowsPerPage = (payload = {}, config) => {
  return async (dispatch) => {
    return baseService.dispatchStoreAction(
      actionTypes.SET_ROWS_PER_PAGE,
      payload,
      dispatch,
    );
  };
};

export const setTotalPages = (payload = {}, config) => {
  return async (dispatch) => {
    return baseService.dispatchStoreAction(
      actionTypes.SET_TOTAL_PAGES,
      payload,
      dispatch,
    );
  };
};

export const resetPaginationState = (payload = {}, config) => {
  return async (dispatch) => {
    return baseService.dispatchStoreAction(
      actionTypes.RESET_PAGINATION_STATE,
      payload,
      dispatch,
    );
  };
};
