/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import DropDownList from "src/sharedComponents/DropDownList/DropDownList";
import { useOutsideAlerter } from "../../helper/hooks";

function WrappedDropDownList(props) {
  const {
    placeholder = "",
    text = {},
    value = false,
    actionMethod = false,
    reset = false,
    listItems = false,
    isHandlingOutside = false,
    modifiedOutSide = false,
    depsProps = false, 
    selectedClass = "",
  } = props;
  const ref = useRef(true);
  const dispatch = useDispatch();
  const [onSearch, setOnSearch] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selected, setSelected] = useState(null);
  const [options, setOptions] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [more, setMore] = useState(false);
  const [mountedProps, setMountedProps] = useState(false);
  const [mountedCurrentPage, setMountedCurrentPage] = useState(false);

  const initialState = (trigger = false) => {
    setOnSearch(false);
    setSearchQuery("");
    setSelected(null);
    setOptions([]);
    setTotalPages(0);
    setCurrentPage(1);
    setPageSize(5);
    setMore(false);
    trigger && setMountedProps(false);
  };
  const fetchSelected = async (value) => {
    if (typeof actionMethod.fetchAPI !== "function") return;
    const response = await dispatch(
      actionMethod.fetchAPI({
        currentPage,
        pageSize: 1,
        filters: (actionMethod.filtersSelected&&`${actionMethod.filtersSelected}${value}`) || "",
      })
    );
    if (!response || response.status !== "success") return;
    setSelected(
      response.responseData.rows.length ? response.responseData.rows[0] : null
    );
  };
  const fetchSearch = async () => {
    if (typeof actionMethod.fetchAPI !== "function") return;
    const response = await dispatch(
      actionMethod.fetchAPI({
        currentPage,
        pageSize,
        filters: `${actionMethod.filters}${searchQuery}` || "",
      })
    );
    if (!response || response.status !== "success") {
      setTotalPages(0);
      setOptions([]);
      return;
    }
    setTotalPages(response.responseData.totalPages);
    if (more) {
      setOptions((prev) => prev.concat(response.responseData.rows));
      return setMore(false);
    }
    setOptions(response.responseData.rows);
  };

  useOutsideAlerter(ref, () => setOnSearch(false));
  useEffect(() => {
    setMountedProps(false);
    initialState();
    return () => {
      setMountedProps(false);
      ref.current = false;
    };
  }, []);
  useEffect(() => {
    if (listItems !== false) setOptions(listItems);
  }, [listItems]);
  useEffect(() => { 
    /*
    * Re calling fetch data by changing outside factors changes
    * Effect runs when call api from outside component 
    * or any outside factor changes
    */ 
    if (depsProps && ref.current) {
      initialState(true)
      if (isHandlingOutside && typeof actionMethod.fetchOptions === "function") {
        return actionMethod.fetchOptions({
          currentPage,
          pageSize,
          search: searchQuery,
          more,
          setMore,
          setTotalPages,
          totalPages,
        });
      } else if (!isHandlingOutside) {
        fetchSearch();
      }
    }
  }, [depsProps])
  useEffect(() => {
    /**
     * * Effect for first mounted component with attached value or not
     * * update the dropdown option list for props.value is set
     * * Not set value it would fire functions once 
     * * while the state "mountedProps" is false then set mounted
     */
    if (!mountedProps) {
      if (value) {
        if (options.length) {
          const found = options.find((item) => item[text.key] === value);
          if (found) {
            setSelected(found);
            setMountedProps(true);
            return;
          } 
          else if ( isHandlingOutside // modifiedOutSide 
            && typeof actionMethod.fetchOptions === "function") {
              actionMethod.fetchOptions({
              currentPage,
              pageSize,
              search: value,
              more,
              setMore,
              setTotalPages,
              totalPages,
              setSelected,
              fetchSelected: true,
            });
            setMountedProps(true);
          }
          return;
        } 
        else if (
          !actionMethod.fetchOptions ||
          typeof actionMethod.fetchOptions !== "function"
        ) {
          fetchSelected(value);
          setMountedProps(true);
          return;
        }
      } 
    }
  }, [value,options]);
  useEffect(() => {
    /**
     * * fetch next paging data
     */
    // if (modifiedOutSide && typeof actionMethod.fetchOptions === "function") {
    if (!mountedCurrentPage) {
      if (currentPage > 1) setMountedCurrentPage(true)
    } else {
      if (isHandlingOutside && typeof actionMethod.fetchOptions === "function") {
        return actionMethod.fetchOptions({
          currentPage,
          pageSize,
          search: searchQuery,
          more,
          setMore,
          setTotalPages,
          totalPages,
        });
      } else if (!isHandlingOutside) {
        fetchSearch();
      }
    }
  }, [currentPage]);
  useEffect(() => {
    reset && setSelected(null);
  }, [reset]);
  useEffect(() => {
    const resetPaginationState = async () => {
      setTotalPages(0);
      setCurrentPage(1);
      setPageSize(5);
    };
    resetPaginationState();
  }, [searchQuery]);
  useEffect(() => {
    /**
     * Search feature
     */
    if (ref.current && onSearch) {
      if (isHandlingOutside && typeof actionMethod.fetchOptions === "function") {
        return actionMethod.fetchOptions({
          currentPage,
          pageSize,
          search: searchQuery,
          more,
          setMore,
          setTotalPages,
          totalPages,
        });
      } else if (!isHandlingOutside) {
        fetchSearch();
      }
    }
  }, [searchQuery]);
  useEffect(() => {
    if (typeof actionMethod.selected === "function") {
      return actionMethod.selected(selected);
    }
  }, [selected]);

  return (
    <DropDownList
      ref={ref}
      value={selected}
      options={options}
      label={text.label || "name"}
      id={text.key || false}
      test={text.underLabel || false}
      optional={text.tag || false}
      placeholder={placeholder}
      selectedClass={selectedClass}
      onChange={(val) => {
        setSelected(val);
      }}
      onFocusInput={() => setOnSearch(true)}
      onSearch={setSearchQuery}
      onLoadMore={() => {
        setMore(true);
        setCurrentPage((prev) => prev + 1);
      }}
      paging={{
        totalPages,
        currentDropdownPage: currentPage,
      }}
    ></DropDownList>
  );
}

export default React.memo(WrappedDropDownList);
