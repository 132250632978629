import * as actionTypes from '../actions/actionTypes'

const initialState = {
    assetFollowDepartmentList: [],
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_STATISTIC_ASSET_FOLLOW_DEPARTMENT:
            return {
              ...state,
              assetFollowDepartmentList: action.payload?.responseData,
            };
        default:
            return state;
    }
}

export default reducer