import styled from "styled-components"

const model = {
	
	styleCircularLoader: {
		// Rotation of path and trail, in number of turns (0-1)
		rotation: 0,

		// Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
		strokeLinecap: 'round',

		// Text size
		textSize: '2rem',

		// How long animation takes to go from one percentage to another, in seconds
		pathTransitionDuration: 0.5,

		// Can specify path transition in more detail, or remove it entirely
		// pathTransition: 'none',

		// Colors
		pathColor: `#fff`,
		textColor: '#fff',
		trailColor: '#777',
		backgroundColor: '#fff',
	},

	PreviewImageBackground: styled.div`
		background: url(${props => props.backgroundImage})  no-repeat center center fixed;
		opacity: 0.3;
		background-size: cover;
		position: absolute;
		inset: 0;
	`
}

export default model