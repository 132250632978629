import baseService from "src/helper/baseService";
import axios from "../../helper/axios";
import * as actionTypes from "../actions/actionTypes";

const forgetPasswordMethod = ({ payload, config }) => {
  return axios.post("user/forgetPassword", payload, config);
};

const forgetPasswordSuccess = (res) => {
  return res.data;
};

export const forgetPassword = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      forgetPasswordMethod,
      { config, payload },
      null,
      (res) => forgetPasswordSuccess(res)
    );
  };
};