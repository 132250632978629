import React, { Component, } from "react";
import { connect } from "react-redux";
import { 
} from "../../store/actions";
import Wrapped from "./Tabs";
import { withCookies } from "react-cookie";

const mapDispatchToProps = (dispatch) => {
  return {};
};

const mapStateToProps = (state) => {
  return {};
};

class Enhanced extends Component {
	render() {
		return <Wrapped {...this.props}></Wrapped>;
	}
}

export default withCookies(connect(mapStateToProps, mapDispatchToProps)(Enhanced));
