import baseService from "src/helper/baseService";
import * as actionTypes from "./actionTypes";

export const setModalCurrentPage = (payload = {}, config) => {
  return async (dispatch) => {
    return baseService.dispatchStoreAction(
      actionTypes.SET_MODAL_CURRENT_PAGE,
      payload,
      dispatch,
    );
  };
};

export const setRowsPerModalPage = (payload = {}, config) => {
  return async (dispatch) => {
    return baseService.dispatchStoreAction(
      actionTypes.SET_ROWS_PER_MODAL_PAGE,
      payload,
      dispatch,
    );
  };
};

export const setTotalModalPages = (payload = {}, config) => {
  return async (dispatch) => {
    return baseService.dispatchStoreAction(
      actionTypes.SET_TOTAL_MODAL_PAGES,
      payload,
      dispatch,
    );
  };
};

export const resetModalPaginationState = (payload = {}, config) => {
  return async (dispatch) => {
    return baseService.dispatchStoreAction(
      actionTypes.RESET_MODAL_PAGINATION_STATE,
      payload,
      dispatch,
    );
  };
};
