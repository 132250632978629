import * as actionTypes from "../actions/actionTypes";

const initState = () => {
	return {
		categories: [],
	};
};
const initialState = initState();

const setCategories = (state, payload) => {
	if (!payload || !payload.responseData || payload.status !== "success") return state

	const categories = payload.responseData.rows
	const setCategories = {
		...state,
		categories
	};
	return setCategories;
};

const reducer = (state = initialState, action) => {
	const { payload } = action;
	switch (action.type) {
		case actionTypes.SET_ASSETS_CATEGORIES:
			return setCategories(state, payload);
		// case actionTypes.SET_ASSETS_CATEGORIES_DETAIL:
		// 	return setCategories(state, payload);
		default:
			return state;
	}
};

export default reducer;
