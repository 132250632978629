import React, {useState, useEffect,} from 'react';
import styled from "styled-components";
import moment from "moment";
import { CModalTitle } from "@coreui/react";
import { Popconfirm, Select } from "antd";

import Input, { InputDate } from "../../../sharedComponents/Input/Input";
import Mdal from "../../../sharedComponents/Modal/index";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Row,
  Col,
} from "../../../sharedComponents/Table/Table";

// WARNING
// format code focus this should be grid-area: grid_reporting${(props) => props.index};
// There is NO ANY space between "grid_reporting" and "${(props) => props.index};"
const GridCell = styled.div`
  grid-area: grid_report${(props) => props.index};
`;
const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: repeat(1,minmax(0, 1fr));
  grid-gap: 0 1.5rem;
  padding: 0;
  margin: 0.5rem 0;
  grid-template-areas: ${(props) =>
    props.approval === "approval"
      ? `"grid_report1 grid_report2"
	;`
      : props.approval === "new"
      ? `"grid_report1 grid_report1"
	;`
      : `"grid_report1 grid_report2"
	"grid_report3 grid_report4"
	;`};
`;

const TabInfoInventoryReport = props => {
	const { 
		item, 
    setItem, 
		approval = false, 
	} = props;
	// const [item, setItem] = useState({})
	const [errors, setErrors] = useState({})

  useEffect(() => {

  }, [item])

	return (<>
    <div>
      <GridContainer>
            <GridCell index={1}>
              <div className="reporting-page__modal reporting-page__label__text">
                Ngày bắt đầu
              </div>
              <InputDate
                disabled
                format={"DD/MM/YYYY"}
                placeholder={"Chọn ngày bắt đầu"}
                value={
                  (item.start_date &&
                    moment(item.start_date, "YYYY-MM-DD")) ||
                  ""
                }
                // onChange={(value, valueString) => {
                //   setItem((prev) => ({
                //     ...prev,
                //     start_date: moment(value).format("YYYY-MM-DD") || "",
                //   }));
                // }}
                className={` ${
                  errors.start_date ? "input--antd-date--outline-red" : ""
                } reporting-page__text`}
              />
            </GridCell>
            <GridCell index={2}>
              <div className="reporting-page__modal reporting-page__label__text">
                Ngày kết thúc
              </div>
              <InputDate
                disabled
                format={"DD/MM/YYYY"}
                placeholder={"Chọn ngày kết thúc"}
                value={
                  (item.end_date &&
                    moment(item.end_date, "YYYY-MM-DD")) ||
                  ""
                }
                // onChange={(value, valueString) => {
                //   setItem((prev) => ({
                //     ...prev,
                //     end_date: moment(value).format("YYYY-MM-DD") || "",
                //   }));
                // }}
                className={` ${
                  errors.end_date ? "input--antd-date--outline-red" : ""
                } reporting-page__text`}
              />
            </GridCell>
          </GridContainer>
    
          <div className="reporting-page__modal reporting-page__label__text">
            Ban kiểm kê
          </div>
          <div style={{overflowX: 'auto'}}>
          <Table>
            <Thead>
              <Row th>
                <Col th index>
                  #
                </Col>
                <Col th>Chức vụ</Col>
                <Col th>Người kiểm kê</Col>
                {/* <Col th></Col> */}
              </Row>
            </Thead>
            <Tbody>
              {item.asset_inventory_users?.length
              ? item.asset_inventory_users.map((reporter, index) => <Row key={index}>
                  <Col index>{index + 1}</Col>
                  <Col>{reporter.asset_inventory_position?.name || reporter.ref_user?.position || "--"}</Col>
                  <Col>{reporter.ref_user?.fullname || "--"}</Col>
                  {/* <Col></Col> */}
                </Row>)
              : <tr>
                <td colSpan={"10"}>
                  <div className="list-empty-message"> Chưa có dữ liệu</div>
                </td>
              </tr>
              } 
            </Tbody>
          </Table>
          </div>
    
    <div className="reporting-page__modal reporting-page__label__text">
                Người kiểm kê
              </div>
              <Input
                disabled
                value={(item.status?.id === null && item.report_conclusion?.id && item.reporter?.fullname) || ""}
                // onChange={(e) =>
                //   setItem((prev) => ({
                //     ...prev,
                //     reporter: {
                //       ...prev.reporter,
                //       fullname: e.target.value,
                //     },
                //   }))
                // }
                placeholder={"--"}
                className={`${
                  errors.reporter ? "input--outline-red" : ""
                } reporting-page__text`}
              />
      <div className="reporting-page__modal reporting-page__label__text">
        Kết quả kiểm kê
        </div>
        <Input
        disabled
        value={(item.status?.id === null && item.report_conclusion?.name) || "--"}
        placeholder={"Nhập đánh giá"}
        className={`${
          errors.report_conclusion ? "input--outline-red" : ""
        } reporting-page__text`}
        />
        {item.updated_by_user && item.updated_at && <div className="flex reporting-page__field--mobile">
        <div className="reporting-page__field__text">Chỉnh sửa gần nhất bởi</div>
        <div className="flex flex__column flex-grow flex-gap--05">
          <div className="flex flex-gap--05 justify-end align-center">
            <img src={`${item.updated_by_user?.avatar}`} alt="user"/>
            <div className="reporting-page__field__text reporting-page__field__text--emphasis">{item.updated_by_user?.fullname}</div>
          </div>
          <div className="flex justify-end reporting-page__field__text">{`${moment.utc(item.updated_at, "YYYY-MM-DD HH:mm:ss").local().format("DD/MM/YYYY")} • ${moment.utc(item.updated_at, "YYYY-MM-DD HH:mm:ss").local().format("HH:mm")}`}</div>
        </div>
        </div>}
    </div>
	</>)
}

export default TabInfoInventoryReport