import * as actionTypes from "../actions/actionTypes";

const initialState = {
  departmentList: [],
  totalPages: 1,
  count: 0,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ALL_DEPARTMENT:
      return {
        ...state,
        departmentList: action.payload?.responseData?.rows,
        totalPages: action.payload?.responseData?.totalPages,
        count: action.payload?.responseData?.count,
      };
    default:
      return state;
  }
};
export default reducer;
