import baseService from "src/helper/baseService";
import axios from "../../helper/axios";
import * as actionTypes from "./actionTypes";

const getStatusByType = ({ payload, config }) => {
  return axios.get(`/asset-status/getAllAssetStatus/${payload.type}`);
};

const responseSuccess = (res) => {
  return res.data;
};

export const getAllStatusByType = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getStatusByType,
      { config, payload },
      actionTypes.GET_STATUS_LIST,
      (res) => responseSuccess(res)
    );
  };
};
