import React from 'react';
import AssetItem from './AssetItem';
import './AssetList.scss';

const AssetList = ({ openHandler, listAsset }) => {
  return (
    <div className='asset-info'>
      <h3 className='asset-info-title'>Thông tin tài sản</h3>
      <ul className='asset-list'>
        {listAsset.map(item => (
          <AssetItem openHandler={openHandler} item={item} />
        ))}
      </ul>
    </div>
  );
};

export default AssetList;
