import baseService from "src/helper/baseService";
import axios from "../../helper/axios";
import * as actionTypes from "./actionTypes";

const getAllStatisticListMethod = ({ payload, config }) => {
  return axios.get(
    `statistics/getAllAsset?startDate=${payload.startDate}&endDate=${
      payload.endDate
    }&currentPage=${payload.currentPage}&pageSize=${
      payload.rowsPerPage
    }&sortField=created_at&sortOrder=desc&departmentID=${
      payload?.departmentId || ""
    }&filters=${payload?.filterString}`
  );
};

const exportExcelAssetMethod = ({ payload, config }) => {
  return axios.download(
    `statistics/exportExcelAsset?startDate=${payload.startDate}&endDate=${
      payload.endDate
    }
    &currentPage=${payload.currentPage}&pageSize=${
      payload.rowsPerPage
    }&sortField=created_at&sortOrder=desc&departmentID=${
      payload?.departmentId || ""
    }&filters=${payload.warehouseId},${payload.statusId},${
      payload.handlePersonId
    },${payload.searchQuery}`
  );
};

const exportExcelAssetFollowAssetGroupMethod = ({ payload, config }) => {
  return axios.download(
    `statistics/exportExcelAssetShortFormat?startDate=${
      payload.startDate
    }&endDate=${payload.endDate}
    &currentPage=${payload.currentPage}&pageSize=${
      payload.rowsPerPage
    }&sortField=created_at&sortOrder=desc&departmentID=${
      payload?.departmentId || ""
    }&filters=${payload.warehouseId},${payload.statusId},${
      payload.handlePersonId
    },${payload.searchQuery}`
  );
};

const responseSuccess = (res) => {
  return res.data;
};

export const getAllStatisticList = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getAllStatisticListMethod,
      { config, payload },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};

export const exportExcelAsset = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      exportExcelAssetMethod,
      { config, payload },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};

export const exportExcelAssetFollowAssetGroup = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      exportExcelAssetFollowAssetGroupMethod,
      { config, payload },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};
