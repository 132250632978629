import * as actionTypes from '../actions/actionTypes'

const initialState = {
    assetStatisticOfMyList: [],
    totalMy: 0
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_ALL_ASSET_STATISTIC_OF_MY:
            return {
              ...state,
              assetStatisticOfMyList: action.payload?.responseData?.static,
              totalMy: action.payload?.responseData?.total,
            };
        default:
            return state;
    }
}

export default reducer