import React from 'react';
import styled from "styled-components"
import "./table.scss";

const TH = styled.th`
	${props => props.width?`width: ${props.width}` : ""}
`

const Col = props => {
	const { 
		children, 
		className, 
		index = false, 
		childClass, 
		th = false, 
		responsive = {}, 
		colSpan = false, 
	} = props;

	return (
		<>
			{th
			? responsive.width
				? (<TH width={responsive.width} className={`table__cols ${index? "table__thead__text" : ""} ${className || ""}`}>
					{index
					?children
					:(<div><div className={`table__cols--flex ${!index && !childClass? "table__thead__text" : ""} ${childClass || ""}`}>{children}</div></div>)}
				</TH>) 
				: (<th className={`table__cols ${index? "table__thead__text" : ""} ${className || ""}`} colSpan={colSpan || "none"}>
					{index
					?children
					:(<div><div className={`table__cols--flex ${!index && !childClass? "table__thead__text" : ""} ${childClass || ""}`}>{children}</div></div>)}
				</th>)
			: (<td className={`table__cols ${index? "table__rows__text" : ""} ${className || ""}`} colSpan={colSpan || "none"}>
				{index
				?children
				:(<div><div className={`table__cols--flex ${!index && !childClass? "table__rows__text" : ""} ${childClass || ""}`}>{children}</div></div>)}
			</td>)	
			}
		</>
	)
}

const Row = props => {
	const { children, className, th = false, plain = false, borderless = false, } = props;

	return (<>
		{th
		? (<tr className={`${className || ""} ${plain? "table__rows--blank" : ""} ${borderless? "table__rows--border-less" : ""} `}>
			{children}
		</tr>)
		:(<tr className={`table__rows ${className || ""} ${plain? "table__rows--blank" : ""} ${borderless? "table__rows--border-less" : ""} `}>
			{children}
		</tr>)}
	</>)
}

const Thead = props => {
	const { children, className } = props;

	return (<thead className={`table__thead ${className || ""}`}>
		{children}
	</thead>)
}

const Tbody = props => {
	const { children, className } = props;

	return (<tbody className={`table__tbody ${className || ""}`}>
		{children}
	</tbody>)
}

const Tfoot = props => {
	const { children, className } = props;

	return (<tfoot className={`table__tfoot ${className || ""}`}>
		{children}
	</tfoot>)
}

const Table = props => {
	const { children, className } = props;

	return (<table className={`table ${className || ""}`}>
		{children}
	</table>)
}

export {Table, Thead, Tbody, Tfoot, Row, Col,}