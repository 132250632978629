import * as actionTypes from '../actions/actionTypes';

const initialState = {
  allTransferHistoryList: [],
  allRequestTypeList: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ASSET_HISTORY:
      return {
        ...state,
        allTransferHistoryList: action?.payload?.responseData,
      };
    case actionTypes.GET_ALL_REQUEST_TYPE:
      return {
        ...state,
        allRequestTypeList: action?.payload?.responseData,
      };
    default:
      return state;
  }
};
export default reducer;
