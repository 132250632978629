import baseService from "src/helper/baseService";
import axios from "../../helper/axios";
import * as actionTypes from "./actionTypes";

const getParentAssetTreesFieldsMethod = ({ payload, config }) => {
  return axios.get(`asset-tree/getParentAssetTreesFields/${payload}`);
};
const responseSuccess = (res) => {
  return res.data;
};
export const getParentAssetTreesFields = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getParentAssetTreesFieldsMethod,
      { config, payload },
      actionTypes.GET_PARENT_ASSET_TREES_FIELDS,
      (res) => responseSuccess(res)
    );
  };
};
