import * as actionTypes from "../actions/actionTypes";

const initialState = {
  isShowAssetExportSpinner: false,
  isShowAssetGroupExportSpinner: false,
  isShowImportExportSpinner: false,
  isShowImportViewPDFSpinner: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    //Overview page
    case actionTypes.SET_ASSET_EXPORT_EXCEL_SPINNER:
      return { ...initialState, isShowAssetExportSpinner: true };
    case actionTypes.UNSET_ASSET_EXPORT_EXCEL_SPINNER:
      return { ...initialState, isShowAssetExportSpinner: false };
    case actionTypes.SET_ASSET_GROUP_EXPORT_EXCEL_SPINNER:
      return { ...initialState, isShowAssetGroupExportSpinner: true };
    case actionTypes.UNSET_ASSET_ASSET_GROUP_EXPORT_EXCEL_SPINNER:
      return { ...initialState, isShowAssetGroupExportSpinner: false };
    //Import-export page
    case actionTypes.SET_IMPORT_EXPORT_EXCEL_SPINNER:
      return { ...initialState, isShowImportExportSpinner: true };
    case actionTypes.UNSET_IMPORT_EXPORT_EXCEL_SPINNER:
      return { ...initialState, isShowImportExportSpinner: false };
    //Import page
    case actionTypes.SET_IMPORT_VIEW_PDF_SPINNER:
      return { ...initialState, isShowImportViewPDFSpinner: true };
    case actionTypes.UNSET_IMPORT_VIEW_PDF_SPINNER:
      return { ...initialState, isShowImportViewPDFSpinner: false };
    default:
      return state;
  }
};
export default reducer;
