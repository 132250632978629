import baseService from "src/helper/baseService";
import axios from "../../helper/axios";
import * as actionTypes from "../actions/actionTypes";

const getAllTreeTypeMethod = ({ payload, config }) => {
  return axios.get(`asset-tree/getAllAssetTreeTypes`);
};

const getBreadCrumMethod = ({ payload, config }) => {
  return axios.get(`asset-group/getBreadcrumbs/${payload}`);
};

const getAllAssetGroupByTreeIdMethod = ({ payload, config }) => {
  return axios.get(
    `asset-group/getAllAssetGroupByTreeID/${payload}?currentPage=1&pageSize=5&sortField=created_at&sortOrder=desc`
  );
};

const getAssetGroupByIdMethod = ({ payload, config }) => {
  return axios.get(`asset-group/getAssetGroup/${payload}`);
};

const getAllAssetGroupByTreeIdWithSearchMethod = ({ payload, config }) => {
  return axios.get(
    `asset-group/getAllAssetGroupByTreeID/${
      payload.id
    }?filters=${encodeURIComponent(payload?.filters || "")}${
      payload?.currentPage ? `&currentPage=${payload?.currentPage}` : ""
    }${payload?.pageSize ? `&pageSize=${payload?.pageSize}` : ""}${
      payload?.sortField ? `&sortField=${payload?.sortField}` : ""
    }${payload?.sortOrder ? `&sortOrder=${payload?.sortOrder}` : ""}`
  );
};

const getAssetTreeMethod = ({ payload, config }) => {
  return axios.get(
    `asset-tree/getAllAssetTrees?search=${encodeURIComponent(payload || "")}`
  );
};

const getAssetTreeByIdMethod = ({ payload, config }) => {
  return axios.get(`asset-tree/getAssetTree/${payload}`);
};

const postAssetTreeMethod = ({ payload, config }) => {
  return axios.post(`asset-tree/postAssetTree`, payload, config);
};

const putAssetTreeMethod = ({ payload, config }) => {
  return axios.put(
    `asset-tree/putAssetTree/${payload.id}`,
    payload.data,
    config
  );
};

const deleteAssetTreeMethod = ({ payload, config }) => {
  return axios.delete(`asset-tree/deleteAssetTree/${payload}`);
};

const getAssetGroupByCodeMethod = ({ payload, config }) => {
  return axios.get(`asset-group/getByCode/${payload}`);
};

const getAssetByCodeMethod = ({ payload, config }) => {
  return axios.get(`asset/getByCode/${payload}`);
};

const responseSuccess = (res) => {
  return res.data;
};

export const getAllTreeType = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getAllTreeTypeMethod,
      { config, payload },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};

export const getBreadCrum = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getBreadCrumMethod,
      { config, payload },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};

export const getAllAssetGroupByTreeId = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getAllAssetGroupByTreeIdMethod,
      { config, payload },
      actionTypes.GET_ASSET_GROUPS_BY_TREE_ID,
      (res) => responseSuccess(res)
    );
  };
};

export const getAssetGroupById = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getAssetGroupByIdMethod,
      { config, payload },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};

export const getAllAssetGroupByTreeIdWithSearch = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getAllAssetGroupByTreeIdWithSearchMethod,
      { config, payload },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};

export const getAssetTree = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getAssetTreeMethod,
      { config, payload },
      actionTypes.GET_TREE_DATA,
      (res) => responseSuccess(res)
    );
  };
};

export const getAssetTreeNoAction = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getAssetTreeMethod,
      { config, payload },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};

export const getAssetTreeById = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getAssetTreeByIdMethod,
      { config, payload },
      actionTypes.GET_TREE_NODE_BY_ID,
      (res) => responseSuccess(res)
    );
  };
};

export const postAssetTree = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      postAssetTreeMethod,
      { config, payload },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};

export const putAssetTree = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      putAssetTreeMethod,
      {
        config,
        payload: {
          id: payload.nodeId,
          data: payload.newNode,
        },
      },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};

export const deleteAssetTree = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      deleteAssetTreeMethod,
      { config, payload },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};

export const getAssetGroupByCode = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getAssetGroupByCodeMethod,
      { config, payload },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};

export const getAssetByCode = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getAssetByCodeMethod,
      { config, payload },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};
