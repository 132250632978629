import baseService from "src/helper/baseService";
import axios from "../../helper/axios";
import * as actionTypes from "./actionTypes";
import sliceKeys from "../sliceKeys";

const getAssetInventoryInformationMethod = ({ payload, config }) => {
  return axios.get(
    `asset-inventory-report-export/getAssetInventoryInformation/${payload.id}`
  );
};
const getAllAssetInventoryAreaMethod = ({ payload, config }) => {
  return axios.get(
    `asset-inventory-report-export/getAllAssetInventoryArea/${payload.id}`
  );
};
const getAllAssetInventoryItemsMethod = ({ payload, config }) => {
  return axios.get(
    `asset-inventory-report-export/getAllAssetInventoryItems?assetInventoryId=${
		payload.assetInventoryId}&departmentOrWarehouseId=${
		payload.departmentOrWarehouseId}&sortField=${
		payload.sortField || ""}&sortOrder=${
		payload.sortOrder || ""}${
		(payload.currentPage && `&currentPage=${payload.currentPage}`)|| ""}${
		(payload.pageSize && `&pageSize=${payload.pageSize}`)|| ""}${
		(payload.filters && `&filters=${payload.filters}`)|| ""}`);
};
const getAssetInventoryTeamMethod = ({ payload, config }) => {
  return axios.get(
    `asset-inventory-report-export/getAssetInventoryTeam?assetInventoryId=${
		payload.assetInventoryId}&departmentOrWarehouseId=${
		payload.departmentOrWarehouseId}`);
};
const createAssetInventoryReportDocumentMethod = ({ payload, config }) => {
  return axios.post(
    `asset-inventory-report-export/createAssetInventoryReportDocument`,
    payload, {...config, responseType: 'blob', }
  );
};
const getAssetInventoryReportInformationMethod = ({ payload, config }) => {
  return axios.get(
    `asset-inventory-report-export/getAssetInventoryReportInformation/${payload.id}`,);
};
const exportAssetInventoryReportDocumentMethod = ({ payload, config }) => {
  return axios.post(
    `asset-inventory-report-export/exportAssetInventoryReportDocument`,
    payload, {...config, responseType: 'blob', }
  );
};
const updateAssetInventoryReportDocumentMethod = ({ payload, config }) => {
  return axios.post(
    `asset-inventory-report-export/updateAssetInventoryReportDocument`, {
      asset_inventory_report_id: payload.asset_inventory_report_id, 
      asset_inventory_id: payload.asset_inventory_id, 
      assistant_id: payload.assistant_id, 
      departmentOrWarehouseID: payload.departmentOrWarehouseID, 
    }
  );
};

const responseSuccess = (res) => {
  if (!res) return res;
  if (res.response) return { ...res.response.data };
  return res.data;
};

export const getAssetInventoryInformation = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getAssetInventoryInformationMethod,
      { payload, config },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};
export const getAllAssetInventoryArea = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getAllAssetInventoryAreaMethod,
      { payload, config },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};
export const getAllAssetInventoryItems = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getAllAssetInventoryItemsMethod,
      { payload, config },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};
export const getAssetInventoryTeam = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getAssetInventoryTeamMethod,
      { payload, config },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};
export const createAssetInventoryReportDocument = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      createAssetInventoryReportDocumentMethod,
      { payload, config },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};
export const getAssetInventoryReportInformation = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getAssetInventoryReportInformationMethod,
      { payload, config },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};
export const exportAssetInventoryReportDocument = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      exportAssetInventoryReportDocumentMethod,
      { payload, config },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};
export const updateAssetInventoryReportDocument = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      updateAssetInventoryReportDocumentMethod,
      { payload, config },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};
