import * as actionTypes from '../actions/actionTypes'

const initialState = {
    tree: [],
    treeNodeById: null,
    currentNodeID: null,
    assetGroupsByTreeId: []
}

const reducer = (state = initialState, action)=>{
    switch (action.type) {
        case actionTypes.GET_TREE_DATA:
		  return {...state, tree: action.payload?.responseData}
        case actionTypes.GET_TREE_NODE_BY_ID:
            return {...state, treeNodeById: action.payload?.responseData,
                                currentNodeID: action.payload?.responseData?.id}
        case actionTypes.GET_ASSET_GROUPS_BY_TREE_ID:
		  return {...state, assetGroupsByTreeId: action.payload?.responseData?.rows}
        default:
          return state
	}
}
export default reducer;