import firebase from "firebase/app";
import "firebase/messaging";
import { getAllNotification } from "./store/actions";
import store from "./store/createStore";
import configuration from "src/configuration";

firebase.initializeApp({
  apiKey: "AIzaSyA36Mja2ePoHBILWyxdMCT7gy1nLx28M6M",
  authDomain: "qlts-ec7bb.firebaseapp.com",
  projectId: "qlts-ec7bb",
  storageBucket: "qlts-ec7bb.appspot.com",
  messagingSenderId: "417425600156",
  appId: "1:417425600156:web:782b925bbd1bc7a43b0759",
  measurementId: "G-KGQD9X7YVP",
});

let messaging = null;
if (firebase.messaging.isSupported()) {
  messaging = firebase.messaging();

  messaging.onMessage(function (payload) {
    try {
      //try???
      const noteTitle = payload.data.title;
      const noteOptions = {
        body: payload.data.body,
        icon: "logo-icon.svg", //this is my image icon for popup notification, store in my public folder
      };
      store.dispatch({
        type: "HAS_NEW_NOTIFICATION",
        hasNewNotification: true,
      });

      new Notification(noteTitle, noteOptions).onclick = function (event) {
        if (
          payload &&
          payload.data &&
          payload.data.click_action &&
          payload.data.click_action.length > 0
        ) {
          window.open(
            configuration.siteURL + payload.data.click_action,
            "_blank"
          );
        }
        this.close();
      };
    } catch (err) {}
  });

  messaging.usePublicVapidKey(
    "BCBLWfbH5uABVL1l6AZBKaoLwY59f01PwoMCS6YUXQBmGtcaMUI-ATdVQlE7bpjSg_COZoNTVrtTIIl8KN-cJ_Y"
  );
}

export { messaging };
