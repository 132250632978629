import React, { useState, useReducer, useEffect, useRef, } from "react";
import { 
	CModalTitle, 
} from "@coreui/react"
import styled from "styled-components";
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";

import { 
	Table, 
	Col, 
	Row, 
	Thead, 
	Tbody, 
	Tfoot, 
} from "../../../sharedComponents/Table/Table"
import { useUploadFile, } from "../../../helper/hooks"
import { 
	getAssetInventoryItemByID, 
	putAssetInventoryItem, 
	putAssetInventoryItemCriteria, 
	uploadFileAssetInventoryItemCriteria, 
	deleteFileAssetInventoryItemCriteria, 
	getAllAssetStatus, 
	getAllAssetInventoryCriteriaByAssetInventoryId, 
	setMessage, 
} from "../../../store/actions"
import config from "../../../configuration/index"
import sliceKeys from "../../../store/sliceKeys"
import Input, { InputDate } from "../../../sharedComponents/Input/Input";
import Mdal from "../../../sharedComponents/Modal/Mdal"
import Uploader from "../../../sharedComponents/Uploader/index"
import DeleteFileDialog from "../../assets-page/asset-form/DeleteFileDialog"

import editIcon from "../../../assets/icons/edit.svg"
import saveIcon from "../../../assets/icons/save.svg"
import closeIcon from "../../../assets/icons/close.svg"

// WARNING
// format code focus this should be grid-area: grid_reporting${(props) => props.index};
// There is NO ANY space between "grid_reporting" and "${(props) => props.index};"
const GridCell = styled.div`
  grid-area: grid_reporting${(props) => props.index};
`;
const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-template-rows: repeat(2, minmax(0, 1fr));
  grid-gap: 0 1.5rem;
  padding: 0;
  margin: 0.5rem 0;
  grid-template-areas:
    "grid_reporting1 grid_reporting2"
    "grid_reporting3 grid_reporting4"
    ;
`;

const TabReviewInventoryReport = props => {
	const { 
		id, 
		show, 
		item, 
		setItem, 
		criteria, 
		finalStatuses, 
		setSubmitCriteria, 
		edit, 
	} = props;
	const [errors, setErrors] = useState({})

	return (<>
		<div>
			<div style={{overflowX: 'auto'}}>
			<Table className="reporting-page__table">
				{item.asset_inventory_item_criteria?.length
				?item.asset_inventory_item_criteria.map((section, index) => {
					return <Section 
						key={index}
						section={{...section, }} 
						listItems={[...criteria]}
						handleMethod={{
							changeCriteria: (item) => setSubmitCriteria(prev => !prev
							.find(other => other.id === item.id)?.id
							? [...prev, item] 
							: prev
								.map(other => other.id === item.id?item:other))
						}}
						unique_key={item.unique_key}
						edit={edit}
					/>
				}) 
				: <>
				<Tfoot>
					<tr className="custom-table__rows custom-table__rows--blank">
					<td colSpan={20}>
						<div className="list-empty-message">Không có dữ liệu đánh giá</div>
					</td>
					</tr>
				</Tfoot>
				</>}
			</Table>
			</div>
			<GridContainer>
			<GridCell
				index={1}
			>
				<div className="reporting-page__modal reporting-page__label__text">% Khấu hao / năm</div>
				<Input
				disabled
				value={(item.depreciation && +item.depreciation.toFixed(2)) || "--"}
				onChange={(e) =>
					setItem((prev) => ({ ...prev, depreciation: e.target.value}))
				}
				placeholder={"Nhập tỷ lệ khấu hao"}
				className={`${errors.depreciation ? "input--outline-red" : ""} reporting-page__text`}
				/>
			</GridCell>
			<GridCell
				index={2}
			>
				<div className="reporting-page__modal reporting-page__label__text">Tỷ lệ khấu hao còn lại (%)</div>
				<Input
				disabled
				value={item.residual_depreciation || "--"}
				onChange={(e) =>
					setItem((prev) => ({ ...prev, residual_depreciation: e.target.value}))
				}
				placeholder={"Nhập tỷ lệ khấu hao còn lại (%)"}
				className={`${errors.residual_depreciation ? "input--outline-red" : ""} reporting-page__text`}
				/>
			</GridCell>
			<GridCell
				index={3}
			>
				<div className="reporting-page__modal reporting-page__label__text">Giá trị đã khấu hao (VNĐ) </div>
				<Input
				disabled
				value={item.residualCost || "--"}
				onChange={(e) =>
					setItem((prev) => ({ ...prev, residualCost: e.target.value }))
				}
				placeholder={"Nhập giá trị còn lại"}
				className={`${errors.residualCost ? "input--outline-red" : ""} reporting-page__text`}
				/>
			</GridCell>
			<GridCell
				index={4}
			>
				<div className="reporting-page__modal reporting-page__label__text">Giá trị còn lại (VNĐ)</div>
				<Input
				disabled
				value={item.cost || "--"}
				onChange={(e) =>
					setItem((prev) => ({ ...prev, cost: e.target.value }))
				}
				placeholder={"Nhập giá mới tương đương"}
				className={`${errors.cost ? "input--outline-red" : ""} reporting-page__text`}
				/>
			</GridCell>
			</GridContainer>
			<div>
				<div className="reporting-page__modal reporting-page__label__text">Đánh giá tổng quan</div>
				<textarea name="description" 
					disabled={!edit}
					value={item.description || ""} 
					onChange={e => setItem(prev => ({...prev, description: e.target.value}))}
					placeholder="Nhập mô tả"/>
			</div>
			<div>
				<div className="reporting-page__modal reporting-page__label__text">Kết quả cuối cùng</div>
				<div className="flex flex__row">
					{finalStatuses.length 
					? finalStatuses.map((status, index) => 
						<button 
							key={index} 
							disabled={!edit}
							onClick={() => {
								if (!edit) return;
								if (
								  item.report_conclusion?.id !== status.id
								) {
								  setItem((prev) => ({
									...prev,
									report_conclusion: status,
								  }));
								} 
								else if (
								  item.report_conclusion?.id === status.id
								) {
								  setItem((prev) => ({
									...prev,
									report_conclusion: {
									  code: "INVENTORY_ITEM_CHECKED", 
									  color: "#00a560", 
									  description: "Đang kiểm kê", 
									  group_code: "INVENTORY_ITEM", 
									  // id: "2db0a08e-fa06-4b71-b37a-047e9e8ed70b", 
									  id: "274aae13-4675-4e97-838b-d26847270a75", 
									  name: "Đang kiểm kê", 
									},
								  }));
								}
								// edit && item.report_conclusion?.id !== status.id 
								// && setItem(prev => ({...prev, report_conclusion: status }
								// ))
							}}
							className={"button button--border-less flex-grow"}
							>
							<input 
								type="radio" 
								disabled={!edit}
								name={"reporting_conclusion"} 
								value={status.id} 
								checked={item.report_conclusion?.id === status.id} 
							/>{status.name}
						</button>
					) : "Không có dữ liệu đánh giá"}
				</div>
			</div>
		</div>
	</>)
}

const Section = props => {
	const { 
		section = {}, 
		listItems = [], 
	} = props;
	const dispatch = useDispatch()
	const ref = useRef(true)
	const [propsLoaded, setPropsLoaded] = useState(false)
	const [itemLoaded, setItemLoaded] = useState(false);
	const [item, setItem] = useState({})
	const [confirm, setConfirm] = useState(false)
	const [confirmModal, setConfirmModal] = useState(false)
	const [criteria, setCriteria] = useState([])
	const initState = {
		listFiles: []
	}

	const addState = (state, payload) => {
		if (!payload) return state
		const addState = {
			...state, 
			listFiles: [
				...state.listFiles,
				...payload,
			], 
		}
		return addState
	}
	const removeState = (state, payload) => {
		if (!payload) return state
		const removeState = {
			...state,
			listFiles: [
				...state.listFiles
				.filter(file => file.id !== payload.id)
			], 
		}
		return removeState
	}
	const reducer = (state = initState, action) => {
		switch(action.type) {
			case "reset": return (initState)
			case "add": return addState(state, action.payload)
			case "remove": return removeState(state, action.payload)
			default: 
				return state
		}
	}
	const [state, dispatchState] = useReducer(reducer, initState)
	
    const handleStateUploadFile = parameters => {
		const {
			empty = false, 
			add = false, 
			filter = false, 
		} = parameters

		if (empty !== false) {
			dispatchState({type: "reset"})
		}
		if (add !== false) {
			if (!add.length) return
            dispatchState({type: "add", payload: add
				.map(file => ({...file,
					name: file.image
					.substring(file.image.lastIndexOf('/') + 1),
				}))
			})
		}
		if (filter !== false) {
			dispatchState({type: "remove", payload: {id: filter}})
		}
	}


	useEffect(() => {
		ref.current = true
		return () => {
			ref.current = false
		}
	}, [])
	useEffect(() => {
		setCriteria([...listItems])
	}, [listItems])
	useEffect(() => {
	  if (ref.current && section && propsLoaded && itemLoaded) {
		typeof props.handleMethod.changeCriteria === 'function' &&
		  props.handleMethod.changeCriteria({
			id: item.id,
			description: item.description,
			asset_inventory_criteria_option_id:
			  item.asset_inventory_criteria_option_id,
		  });
	  } else if (ref.current && section && propsLoaded && !itemLoaded)
		setItemLoaded(true);
	}, [item]);
	useEffect(() => {
		if (ref.current && section && !propsLoaded) {
			setPropsLoaded(true)
			setItem(section)
			dispatchState({type: "reset"})
			dispatchState({type: "add", payload: section?.asset_inventory_criteria_attachments
				? section?.asset_inventory_criteria_attachments.map(file => ({...file,
					name: file.image
					.substring(file.image.lastIndexOf('/') + 1),
				})) : []
			})

		}
	}, [section])
    const fileMethods = useUploadFile({
		id: section.id, 
		add: (payload, config) => {
			return uploadFileAssetInventoryItemCriteria({...payload, unique_key: props.unique_key}, config)
		}, 
		delete: deleteFileAssetInventoryItemCriteria, 
		handleState: handleStateUploadFile, 
        confirmDelete: () => {
            return setConfirmModal(true)
        }, 
        confirmed: confirm, 
        setConfirmed: setConfirm , 
	})
	return (<>
		<Thead><Row th>
			<Col colSpan={1} th></Col>
			{criteria?.length
			? [...criteria
				.find(item => item.id === section.asset_inventory_criteria_id)?.asset_inventory_criteria_options
				|| [], 
				...Array.from(Array(criteria.find(item => item.id === section.asset_inventory_criteria_id)?.asset_inventory_criteria_options.length 
				? 3-criteria.find(item => item.id === section.asset_inventory_criteria_id)?.asset_inventory_criteria_options.length : 3).keys()), ]
				.map((status, index) => 
				<Col colSpan={1} key={index} th childClass="table__thead__text table__thead__text--text-center">{status.name || "--"}</Col>
			) : null}
		</Row></Thead>
		<Tbody><Row plain borderless>
			<Col colSpan={1}>{criteria.find(item => item.id === section.asset_inventory_criteria_id)?.name}</Col>
			{/* {criteria?.find(item => item.id === section.asset_inventory_criteria_id)?.asset_inventory_criteria_options?.length */}
			{criteria?.length
			? [...criteria
				.find(item => item.id === section.asset_inventory_criteria_id)?.asset_inventory_criteria_options
				|| [], 
				...Array.from(Array(criteria.find(item => item.id === section.asset_inventory_criteria_id)?.asset_inventory_criteria_options.length 
				? 3-criteria.find(item => item.id === section.asset_inventory_criteria_id)?.asset_inventory_criteria_options.length : 3).keys()),]
				.map((criteria, index) => 
				<td className="table__cols  " key={index}>
					{
					// criteria.id && 
					<div className="flex flex-center"><button 
					key={index}
					disabled={!criteria.id || (!props.edit && item.asset_inventory_criteria_option_id !== criteria.id)}
					onClick={() => {
						if (!props.edit) return
						if ((!item.asset_inventory_criteria_option_id 
							|| item.asset_inventory_criteria_option_id !== criteria.id)) {
							setItem(prev => ({...prev, asset_inventory_criteria_option_id: criteria.id, }))
						} else if (item.asset_inventory_criteria_option_id === criteria.id) {
							setItem(prev => ({...prev, asset_inventory_criteria_option_id: null, }))
						}
					}}
					className={"button button--border-less flex-grow"}
					>
					<input 
						disabled={!criteria.id || (!props.edit && item.asset_inventory_criteria_option_id !== criteria.id)}
						readOnly={!criteria.id || !props.edit}
						type="radio" 
						name={section.id} 
						value={criteria.id || index} 
						checked={item.asset_inventory_criteria_option_id ===
							criteria.id || false} 
						onChange={() => {}}
					/>
					</button></div>}
				</td>
			) : null}
		</Row>
		<Row plain>
			<Col th>Mô tả chi tiết</Col>
			<Col colSpan={10} className={"table__cols--input"} childClass={"table__rows__input"}>
			<div className="flex">
				<div className="flex flex__column flex-grow flex-gap--1">
					{props.edit ? <>
					{/* {true ? <> */}
						<Input value={item.description || ""} onChange={e => setItem(prev => ({...prev, description: e.target.value}))} placeholder={"Nhập mô tả"} className={"reporting-page__table__text"} />
					</>
					: <>
						<div className={"reporting-page__table__text"}>{item.description}</div>
					</>}
					{state.listFiles.length || props.edit
					?
					<>
						<Uploader 
							id={section.id} 
							multipleFiles 
							listFiles={state.listFiles}
							hasPreview
							borderless
							unScrollable={!props.edit} 
							unShowActions={!props.edit} 
							unShowFileName
							actionMethod={fileMethods}
							hasStacks={!props.edit && 5}
							placeholder={{
								input: () => "", 
								empty: () => "", 
								icon: () => false, 
							}}
							center={"left"}
							closeOnBackdrop
						/>
						{confirmModal && <DeleteFileDialog open={confirmModal} handleClose={setConfirmModal} setConfirm={setConfirm} />} 
					</> 
					: null}
				</div>
			</div>
		</Col></Row></Tbody>
	</>)
}

export default TabReviewInventoryReport