import * as actionTypes from "../actions/actionTypes";

const initialState = {
    assetStatisticList: [],
    total: 0
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_ALL_ASSET_STATISTICS:
            return {
              ...state,
              assetStatisticList: action.payload?.responseData?.static,
              total: action.payload?.responseData?.total,
            };
        default:
            return state
    }
}

export default reducer
