import baseService from "src/helper/baseService";
import axios from "../../helper/axios";
import * as actionTypes from "./actionTypes";

const getAllCompanyMethod = ({ payload, config }) => {
  return axios.get(
    `company/getAllCompany${
      payload
        ? `?currentPage=${payload.currentPage}&pageSize=${
            payload.rowsPerPage
          }&filters=${encodeURIComponent(
            payload.searchQuery
          )}&isAllocated=false`
        : ""
    }`
  );
};
const getAllCompanyByIdMethod = ({ payload, config }) => {
  return axios.get(`company/getCompany/${payload.id}`);
};
const postCompanyMethod = ({ payload, config }) => {
  return axios.post(`company/postCompany`, payload, config);
};
const putCompanyMethod = ({ payload, config }) => {
  return axios.put(`company/putCompany/${payload.id}`, payload.data);
};

const deleteCompanyMethod = ({ payload, config }) => {
  return axios.delete(`company/deleteCompany/${payload.id}`);
};

const responseSuccess = (res) => {
  return res.data;
};

export const getAllCompany = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getAllCompanyMethod,
      { config, payload },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};

export const getCompanyById = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getAllCompanyByIdMethod,
      { config, payload },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};

export const postCompany = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      postCompanyMethod,
      { config, payload },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};

export const putCompany = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      putCompanyMethod,
      { config, payload },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};

export const deleteCompany = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      deleteCompanyMethod,
      { config, payload },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};
