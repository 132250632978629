import moment from 'moment'
import * as actionTypes from '../actions/actionTypes'

const initialState = {
    globalMessages: []
}

const setMessages = (state, payload) => {
	if (!payload) return state
	if (!payload?.globalMessages && !payload?.globalMessages?.length < 1) return state
	const newMessages = payload?.globalMessages
	?.map(message => message.id ? message: ({...message, id: moment().valueOf()}))
	const message = {...state, 
		globalMessages: [...state.globalMessages, 
			...newMessages
		]
	}
	return message
}
const clearMessages = (state) => {
	return {...state, globalMessages: []}
}
const clearOneMessage = (state, id) => {
	return {...state, 
		globalMessages: state.globalMessages.filter(message => message.id !== id)}
}

const reducer = (state = initialState, action)=>{
    switch (action.type) {
        case actionTypes.API_MESSAGE:
		  return setMessages(state, action?.payload)
        case actionTypes.CLEAR_API_MESSAGE:
		  return clearMessages(state)
        case actionTypes.CLEAR_ONE_API_MESSAGE:
		  return clearOneMessage(state, action?.payload?.id)
        default:
          return state
	}
}
export default reducer;