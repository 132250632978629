import * as actionTypes from "../actions/actionTypes";
import sliceKeys from "../sliceKeys";

const initState = () => {
  const token = localStorage.getItem(sliceKeys.auth);
  if (token) {
    return { ...JSON.parse(`{"${sliceKeys.auth}":"${token}"}`) };
  }
  return {}
};
const initialState = initState();

const resetToken = async () => {
  await localStorage.removeItem(sliceKeys.auth);
  return {};
};

const setToken = (state, payload) => {
  if (!payload) return state;
  const token = payload.token;
  const setToken = {
    ...state,
    [sliceKeys.auth]: token,
  };
  localStorage.setItem(sliceKeys.auth, token);
  return setToken;
};

const setUser = (state, payload) => {
  if (!payload || !payload.data) return state;
  const setUser = {
    ...state,
    user: payload.data?.responseData,
  };
  return setUser;
};

const reducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case actionTypes.SET_TOKEN:
      return setToken(state, payload);
    case actionTypes.SET_USER:
      return setUser(state, payload);
    case actionTypes.RESET_TOKEN:
      return resetToken();
    default:
      return state;
  }
};

export default reducer;
