import {combineReducers} from 'redux';
import auth from './auth';
import layout from './layout';
import loader from './loader';
import message from './message';
import modal from './modal';
import devices from './devices';
import uploadFiles from './uploadFiles';
import pagination from './pagination';
import assetCategories from './assetCategories';
import tree from './tree';
import assetParentTreesFields from './assetParentTreesFields';
import distribution from './distribution';
import useByDepartmentId from './userByDepartmentId';
import asset from './asset';
import hangover from './hangover';
import importExportHistory from './importExportHistory';
import notification from './notification';
import myAssetRequests from './myAssetRequests';
import dropdownPagination from './dropdownPagination';
import common from './common';
import modalPagination from './modalPagination';
import myAssetWarehouse from './myAssetWarehouse';
import assetStatistic from './assetStatistic';
import assetFollowWarehouse from './assetFollowWarehouse';
import statisticAssetFollowWarehouse from './statisticAssetFollowWarehouse';
import assetFollowDepartment from './assetFollowDepartment';
import assetFollowDepartmentID from './assetFollowDepartmentID';
import assetWarehouseByUserID from './assetWarehouseByUserID';
import assetStatisticOfMy from './assetStatisticOfMy';
import assetOfMy from './assetOfMy';
import inventoryCommittee from './inventoryCommittee';
import inventoryReport from './inventoryReport';
import inventoryMyReport from './inventoryMyReport';
import assetTransferHistory from './assetTransferHistory';
import spinner from "./spinner";

export default combineReducers({
  auth,
  layout,
  loader,
  message,
  modal,
  devices,
  uploadFiles,
  pagination,
  assetCategories,
  tree,
  assetParentTreesFields,
  distribution,
  useByDepartmentId,
  asset,
  hangover,
  importExportHistory,
  notification,
  myAssetRequests,
  dropdownPagination,
  myAssetWarehouse,
  common,
  modalPagination,
  assetStatistic,
  assetFollowWarehouse,
  statisticAssetFollowWarehouse,
  assetFollowDepartment,
  assetFollowDepartmentID,
  assetWarehouseByUserID,
  assetStatisticOfMy,
  assetOfMy,
  inventoryCommittee,
  inventoryReport,
  inventoryMyReport,
  assetTransferHistory,
  spinner,
});
