import baseService from "src/helper/baseService";
import axios from "../../helper/axios";
import * as actionTypes from "./actionTypes";

const getAllWareHouseLst = ({ payload, config }) => {
  return axios.get(
    `/asset-warehouse/getAllAssetWarehouse?filters=${encodeURIComponent(
      payload.filters || ""
    )}${(payload.currentPage && `&currentPage=${payload.currentPage}`) || ""}${
      (payload.pageSize && `&pageSize=${payload.pageSize}`) || ""
    }&sortField=created_at&sortOrder=DESC`
  );
};

const getMyAssetWarehouseMethod = ({ payload, config }) => {
  return axios.get(
    `/asset-warehouse/getMyAssetWarehouse?filters=${encodeURIComponent(
      payload.filters || ""
    )}`
  );
};

const getWareHouseInfo = ({ payload, config }) => {
  return axios.get(`/asset-warehouse/getAssetWarehouse/${payload.id}`);
};

const addNewWareHouseInfo = ({ payload, config }) => {
  return axios.post(`/asset-warehouse/postAssetWarehouse`, payload.body);
};

const putWareHouseInfo = ({ payload, config }) => {
  return axios.put(
    `/asset-warehouse/putAssetWarehouse/${payload.id}`,
    payload.body
  );
};

const removeWareHouseInfo = ({ payload, config }) => {
  return axios.delete(`/asset-warehouse/deleteAssetWarehouse/${payload.id}`);
};

const responseSuccess = (res) => {
  return res.data;
};

export const getAllWareHouse = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getAllWareHouseLst,
      { config, payload },
      actionTypes.GET_ALL_WARE_HOUSE,
      (res) => responseSuccess(res)
    );
  };
};

export const getMyAssetWareHouse = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getMyAssetWarehouseMethod,
      { config, payload },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};

export const getWareHouseById = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getWareHouseInfo,
      { config, payload },
      actionTypes.GET_WARE_HOUSE_DETAILS,
      (res) => responseSuccess(res)
    );
  };
};

export const addNewWareHouse = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      addNewWareHouseInfo,
      { config, payload },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};

export const updateWareHouse = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      putWareHouseInfo,
      { config, payload },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};

export const removeWareHouse = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      removeWareHouseInfo,
      { config, payload },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};
