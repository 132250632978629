import baseService from "src/helper/baseService";
import axios from "../../helper/axios";
import * as actionTypes from "./actionTypes";

const getAllUserMethod = ({ payload, config }) => {
  return axios.get(
    `user/getAllUser?currentPage=${payload.currentPage}&pageSize=${
      payload.rowsPerPage
    }&filters=${encodeURIComponent(payload.searchQuery)}`
  );
};
const getAllUserWithPositionByWarehouseIDMethod = ({
  payload,
  config,
}) => {
  return axios.get(
    `user/getAllUserWithPositionByWarehouseID/${
      payload.id
    }?filters=${payload.filters || ""}${
      (payload.currentPage && `&currentPage=${payload.currentPage}`) || ""
    }${(payload.pageSize && `&pageSize=${payload.pageSize}`) || ""}${
      (payload.sortField && `&sortFieldF=${payload.sortField}`) || ""
    }${(payload.sortOrder && `&sortOrder=${payload.sortOrder}`) || ""}`
  );
};
const getAllUserWithPositionByDepartmentIDMethod = ({
  payload,
  config,
}) => {
  return axios.get(
    `user/getAllUserWithPositionByDepartmentID/${
      payload.id
    }?filters=${payload.filters || ""}${
      (payload.currentPage && `&currentPage=${payload.currentPage}`) || ""
    }${(payload.pageSize && `&pageSize=${payload.pageSize}`) || ""}${
      (payload.sortField && `&sortFieldF=${payload.sortField}`) || ""
    }${(payload.sortOrder && `&sortOrder=${payload.sortOrder}`) || ""}`
  );
};

const responseSuccess = (res) => {
  if (!res) return res;
  if (res.response) return { ...res.response.data };
  return res.data;
};

export const getAllUser = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getAllUserMethod,
      { payload, config },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};
export const getAllUserWithPositionByWarehouseID = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getAllUserWithPositionByWarehouseIDMethod,
      { payload, config },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};
export const getAllUserWithPositionByDepartmentID = (payload, config) => {
  return async (dispatch) => {
    return baseService.serviceRequest(
      {},
      dispatch,
      getAllUserWithPositionByDepartmentIDMethod,
      { payload, config },
      actionTypes.NO_ACTION,
      (res) => responseSuccess(res)
    );
  };
};
