export const NO_ACTION = "NO_ACTION";
// =======================Loader action types=======================
export const START_LOADING = "START_LOADING";
export const STOP_LOADING = "STOP_LOADING";
// =======================Toast Message action types=======================
export const API_MESSAGE = "API_MESSAGE";
export const SET_API_MESSAGE = "SET_API_MESSAGE";
export const CLEAR_API_MESSAGE = "CLEAR_API_MESSAGE";
export const CLEAR_ONE_API_MESSAGE = "CLEAR_ONE_API_MESSAGE";
// =======================Login action types=======================
export const VALIDATE_TOKEN = "VALIDATE_TOKEN";
export const SET_TOKEN = "SET_TOKEN";
export const RESET_TOKEN = "RESET_TOKEN";
export const UNAUTHENTICATED = "UNAUTHENTICATED";
export const SET_USER = "SET_USER";
export const GET_USER = "GET_USER";
export const SET_USERS = "SET_USERS";
// =======================Status action types=======================
export const SET_STATUS = "SET_STATUS";
// =======================Devices action types=======================
export const SET_ASSETS_DEVICES = "SET_ASSETS_DEVICES";
export const SET_ASSETS_CATEGORIES = "SET_ASSETS_CATEGORIES";
export const SET_ASSETS_CATEGORIES_DETAIL = "SET_ASSETS_CATEGORIES_DETAIL";
export const SET_ASSET_DETAIL = "SET_ASSET_DETAIL";
export const UNSET_ASSET_DETAIL = "UNSET_ASSET_DETAIL";
export const GET_ASSET_GROUP_DETAIL = "GET_ASSET_GROUP_DETAIL";
// =======================Upload Files action types=======================
export const CLEAR_UPLOAD_FILE = "CLEAR_UPLOAD_FILE";
export const SET_UPLOAD_FILE = "SET_UPLOAD_FILE";
export const UNSET_UPLOAD_FILE = "UNSET_UPLOAD_FILE";
export const UNSET_UPLOAD_FILES = "UNSET_UPLOAD_FILES";
export const SET_UPLOAD_PROGRESS = "SET_UPLOAD_PROGRESS";
export const SUCCESS_UPLOAD_FILE = "SUCCESS_UPLOAD_FILE";
export const FAILURE_UPLOAD_FILE = "FAILURE_UPLOAD_FILE";
export const RETRY_UPLOAD_FILE = "RETRY_UPLOAD_FILE";
// =======================Pagination action types=======================
export const SET_CURRENT_PAGE = "SET_CURRENT_PAGE";
export const SET_ROWS_PER_PAGE = "SET_ROWS_PER_PAGE";
export const SET_TOTAL_PAGES = "SET_TOTAL_PAGES";
export const RESET_PAGINATION_STATE = "RESET_PAGINATION_STATE";
// =======================DROPDOWN Pagination action types=======================
export const SET_DROPDOWN_CURRENT_PAGE = "SET_DROPDOWN_CURRENT_PAGE";
export const SET_ROWS_PER_DROPDOWN_PAGE = "SET_ROWS_PER_DROPDOWN_PAGE";
export const SET_TOTAL_DROPDOWN_PAGES = "SET_TOTAL_DROPDOWN_PAGES";
export const RESET_DROPDOWN_PAGINATION_STATE =
  "RESET_DROPDOWN_PAGINATION_STATE";
// =======================Modal Pagination action types=======================
export const SET_MODAL_CURRENT_PAGE = "SET_MODAL_CURRENT_PAGE";
export const SET_ROWS_PER_MODAL_PAGE = "SET_ROWS_PER_MODAL_PAGE";
export const SET_TOTAL_MODAL_PAGES = "SET_TOTAL_MODAL_PAGES";
export const RESET_MODAL_PAGINATION_STATE = "RESET_MODAL_PAGINATION_STATE";
// =======================Asset Group action types=======================
export const GET_ALL_ASSET_GROUP = "GET_ALL_ASSET_GROUP";
export const GET_ASSET_GROUP = "GET_ASSET_GROUP";
export const POST_ASSET_GROUP = "POST_ASSET_GROUP";
export const PUT_ASSET_GROUP = "PUT_ASSET_GROUP";
export const DELETE_ASSET_GROUP = "DELETE_ASSET_GROUP";
// =======================Asset Category action types=======================
export const GET_ALL_ASSET_CATEGORY = "GET_ALL_ASSET_CATEGORY";
export const GET_ASSET_CATEGORY = "GET_ASSET_CATEGORY";
export const POST_ASSET_CATEGORY = "POST_ASSET_CATEGORY";
export const PUT_ASSET_CATEGORY = "PUT_ASSET_CATEGORY";
export const DELETE_ASSET_CATEGORY = "DELETE_ASSET_CATEGORY";
// =======================Tree action types=======================
export const GET_TREE_DATA = "GET_TREE_DATA";
export const GET_TREE_NODE_BY_ID = "GET_TREE_NODE_BY_ID";
export const GET_ASSET_GROUPS_BY_TREE_ID = "GET_ASSET_GROUPS_BY_TREE_ID";
export const GET_ASSET_GROUPS_BY_TREE_ID_WITH_SEARCH =
  "GET_ASSET_GROUPS_BY_TREE_ID_WITH_SEARCH";
export const DELETE_TREE_NODE = "DELETE_TREE_NODE";
// =======================Notification=======================
export const GET_WEB_TOKEN = "POST_WEB_TOKEN";
// =======================Tabs action types=======================
export const SET_TAB = "SET_TAB";
export const SET_TABS = "SET_TABS";
// =======================Asset Trees Fields action types=======================
export const GET_PARENT_ASSET_TREES_FIELDS = "GET_PARENT_ASSET_TREES_FIELDS";
// =======================Distribution action types=======================
export const GET_ALL_DEPARTMENT = "GET_ALL_DEPARTMENT";
export const GET_ALL_USER_BY_DEPARTMENTID = "GET_ALL_USER_BY_DEPARTMENTID";
// =======================Asset action types=======================
export const GET_ALL_ASSET = "GET_ALL_ASSET";
export const GET_ALL_ALLOCATION_ASSET = "GET_ALL_ALLOCATION_ASSET";
export const GET_ALL_MY_ASSET_REQUEST = "GET_ALL_MY_ASSET_REQUEST";
// =======================Import Export History types=======================
export const GET_INVENTORY_HISTORY = "GET_INVENTORY_HISTORY";
// =======================Notification types=======================
export const GET_NOTIFICATION = "GET_NOTIFICATION";
export const GET_NOTIFICATION_GROUP = "GET_NOTIFICATION_GROUP";
export const RESET_NOTIFICATION = "RESET_NOTIFICATION";
export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";
export const HAS_NEW_NOTIFICATION = "HAS_NEW_NOTIFICATION";
// =======================My Assets=========================
export const GET_ALL_MY_ASSET = "GET_ALL_MY_ASSET";
// =======================Inventory===============
export const GET_ALL_WARE_HOUSE = "GET_ALL_WARE_HOUSE";
// =======================User==================
export const GET_ALL_USERS = "GET_ALL_USERS";
export const GET_ALL_USER = "GET_ALL_USER";
// =======================Common==================
export const GET_STATUS_LIST = "GET_STATUS_LIST";
export const GET_WARE_HOUSE_DETAILS = "GET_WARE_HOUSE_DETAILS";
export const GET_MY_ASSET_WAREHOUSE = "GET_MY_ASSET_WAREHOUSE";
// =======================Statistics==================
export const GET_ALL_ASSET_STATISTICS = "GET_ALL_ASSET_STATISTICS";
export const GET_ALL_ASSET_FOLLOW_WARE_HOUSE =
  "GET_ALL_ASSET_FOLLOW_WARE_HOUSE";
export const GET_STATISTIC_ASSET_FOLLOW_WARE_HOUSE =
  "GET_STATISTIC_ASSET_FOLLOW_WARE_HOUSE";
export const GET_STATISTIC_ASSET_FOLLOW_DEPARTMENT =
  "GET_STATISTIC_ASSET_FOLLOW_DEPARTMENT";
export const GET_STATISTIC_ASSET_FOLLOW_DEPARTMENT_BY_ID =
  "GET_STATISTIC_ASSET_FOLLOW_DEPARTMENT_BY_ID";
export const GET_ALL_ASSET_OF_MY = "GET_ALL_ASSET_OF_MY";
export const GET_ALL_ASSET_STATISTIC_OF_MY = "GET_ALL_ASSET_STATISTIC_OF_MY";
// =======================Asset Warehouse==================
export const GET_ASSET_WAREHOUSE_BY_USER_ID = "GET_ASSET_WAREHOUSE_BY_USER_ID";
// =======================InventoryCommittee==================
export const SET_INVENTORYCOMMITTEE = "SET_INVENTORYCOMMITTEE";
export const RESET_INVENTORYCOMMITTEE = "RESET_INVENTORYCOMMITTEE";
// =======================Inventory Reports==================
export const GET_ALL_REPORT = "GET_ALL_REPORT";
export const GET_MY_REPORT = "GET_MY_REPORT";
// =======================Asset History==================
export const GET_ASSET_HISTORY = "GET_ASSET_HISTORY";
export const GET_ALL_REQUEST_TYPE = "GET_ALL_REQUEST_TYPE";
// =======================Export Excel File Spinner==================
export const SET_ASSET_EXPORT_EXCEL_SPINNER = "SET_ASSET_EXPORT_EXCEL_SPINNER";
export const UNSET_ASSET_EXPORT_EXCEL_SPINNER =
  "UNSET_ASSET_EXPORT_EXCEL_SPINNER";
export const SET_ASSET_GROUP_EXPORT_EXCEL_SPINNER =
  "SET_ASSET_GROUP_EXPORT_EXCEL_SPINNER";
export const UNSET_ASSET_ASSET_GROUP_EXPORT_EXCEL_SPINNER =
  "UNSET_ASSET_ASSET_GROUP_EXPORT_EXCEL_SPINNER";
  
export const SET_IMPORT_EXPORT_EXCEL_SPINNER =
  "SET_IMPORT_EXPORT_EXCEL_SPINNER";
export const UNSET_IMPORT_EXPORT_EXCEL_SPINNER =
  "UNSET_IMPORT_EXPORT_EXCEL_SPINNER";

export const SET_IMPORT_VIEW_PDF_SPINNER = "SET_IMPORT_VIEW_PDF_SPINNER";
export const UNSET_IMPORT_VIEW_PDF_SPINNER = "UNSET_IMPORT_VIEW_PDF_SPINNER";
